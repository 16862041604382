import Vue from "vue";
//import Vuex from 'vuex'
import App from "./App.vue";
import Loader from "./plugins/loader";
import router from "./router";
import store from "./store";
import VueTheMask from "vue-the-mask";
import money from "v-money";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import BootstrapVue from "bootstrap-vue";
import PortalVue from "portal-vue";
import VueCurrencyInput from "vue-currency-input";

window.$ = window.jQuery = require("jquery");

import "bootstrap";
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(Loader, store);

Vue.prototype.moment = moment;

Vue.use(VueTheMask);

const optionsSW2 = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
  //confirmButtonColor: '#3085d6',
  //cancelButtonColor: '#d33',
  //cancelButtonText: 'Não',
  //confirmButtonText: 'Sim'
};
Vue.use(VueSweetalert2, optionsSW2);

Vue.use(money, { precision: 2 });
Vue.use(BootstrapVue);
Vue.use(PortalVue);

import MoedaFilter from "./filters/filterMoeda";
import DataFilter from "./filters/filterData";
import DataHoraFilter from "./filters/filterDataHora";
import HoraFilter from "./filters/filterHora";
import HorMinFilter from "./filters/filterHorMin";
import HorMinStrFilter from "./filters/filterHorMinStr";
import CurrencyFilter from "./filters/filterCurrency";
import ViaCep from "vue-viacep";

Vue.use(ViaCep);
Vue.filter("moeda", MoedaFilter);
Vue.filter("data", DataFilter);
Vue.filter("datahora", DataHoraFilter);
Vue.filter("hora", HoraFilter);
Vue.filter("horamin", HorMinFilter);
Vue.filter("horaminstr", HorMinStrFilter);
Vue.filter("currency", CurrencyFilter);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

const pluginOptions = {
  /* see config reference */
  globalOptions: {
    locale: "pt-BR",
    currency: "BRL",
    precision: 2,
    distractionFree: { hideCurrencySymbol: true },
  },
};
Vue.use(VueCurrencyInput, pluginOptions);

new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(App),
});
