<template>
    <div class="not-found align-middle">
        <div class="circulo bg-danger text-white mx-auto"><i class="fa fa-chain-broken fa-4x"></i></div>
        <strong class="text-danger">404</strong>
        <h1 class="text-dark">PÁGINA NÃO ENCONTRADA</h1>   
        <p class="text-dark">A página que você está procurando foi movida, removida, renomeada ou talvez nunca existisse. <br/>Você entrou em um link quebrado.</p>
    </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
    .not-found{
        text-align: center;
        color: #fff;
        margin: 0px -15px -15px -15px;
        padding: 20px;
    }

    .not-found h1 {
        font-size: 36px;
        font-family: 'Russo One', Helvetica, Arial, Verdana, sans-serif;
    }

    .not-found strong {
        font-size: 200px;
        font-family: 'Russo One', Helvetica, Arial, Verdana, sans-serif;
    }

    .not-found p {
        font-size: 20px;
        font-family: 'Glegoo', Helvetica, Arial, Verdana, sans-serif;
    }

    .circulo{
        width: 100px;
        height: 100px;
        border-radius: 100px; /*(padrão)*/
        -moz-border-radius: 100px; /*(navegadores Flock e Firefox)*/
        -webkit-border-radius: 100px; /*(navegadores Chrome e Safari)*/
        padding: 20px;
    }    

</style>

