import Vue from 'vue'
import Vuex from 'vuex'


import Configs from './configs'
import Login from './login'
import Popup from './popup'

const modules = {
    Login,
    Popup,
    Configs
}

Vue.use(Vuex)

export default new Vuex.Store({
    modules
})