export const USU_LOGADO = "USU_LOGADO";
export const USU_LOGAR_SUCCESS = "USU_LOGAR_SUCCESS";
export const USU_LOGAR = "USU_LOGAR";
export const USU_LOGOUT = "USU_LOGOUT";
export const USU_MODULO = "USU_MODULO";
export const USU_SUBMODULO = "USU_SUBMODULO";
export const USU_EXERCICIO = "USU_EXERCICIO";
export const USU_COMPETENCIA = "USU_COMPETENCIA";
export const USU_ULTEXERCICIO = "USU_ULTEXERCICIO";
export const USU_BUSCA = "USU_BUSCA";
export const USU_IP = "USU_IP";
export const MENU = "MENU";
export const LAYOUT = "LAYOUT";
