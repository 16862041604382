function load(component) {
  return () => import(`../components/comum/${component}.vue`);
}

function loadC(component) {
  return () => import(`../components/${component}.vue`);
}

export default [
  {
    path: "/fpcargosvaga",
    component: load("MctDataViewer"),
    props: { source: "fpcargosvaga/lista", titulo: "Cargos Vaga" },
  },
  {
    path: "/fpcargosvaga/novo",
    component: load("MctFormViewer"),
    props: { source: "fpcargosvaga/novo", titulo: "Cargos Vaga", modo: "novo" },
  },
  {
    path: "/fpcargosvaga/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fpcargosvaga/editar",
      titulo: "Cargos Vaga",
      modo: "editar",
    },
  },
  {
    path: "/fpcargosvaga/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpcargosvaga/deletar",
      titulo: "Cargos Vaga",
      modo: "deletar",
    },
  },
  {
    path: "/fpcargosvaga/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpcargosvaga/editar",
      titulo: "Cargos Vaga",
      modo: "visualizar",
    },
  },

  {
    path: "/fpcargosclasse",
    component: load("MctDataViewer"),
    props: { source: "fpcargosclasse/lista", titulo: "Cargos Classe" },
  },
  {
    path: "/fpcargosclasse/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fpcargosclasse/novo",
      titulo: "Cargos Classe",
      modo: "novo",
    },
  },
  {
    path: "/fpcargosclasse/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fpcargosclasse/editar",
      titulo: "Cargos Classe",
      modo: "editar",
    },
  },
  {
    path: "/fpcargosclasse/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpcargosclasse/deletar",
      titulo: "Cargos Classe",
      modo: "deletar",
    },
  },
  {
    path: "/fpcargosclasse/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpcargosclasse/editar",
      titulo: "Cargos Classe",
      modo: "visualizar",
    },
  },

  {
    path: "/fptpadmissao",
    component: load("MctDataViewer"),
    props: { source: "fptpadmissao/lista", titulo: "Tipos de Admissão" },
  },
  {
    path: "/fptpadmissao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fptpadmissao/novo",
      titulo: "Tipos de Admissão",
      modo: "novo",
    },
  },
  {
    path: "/fptpadmissao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpadmissao/editar",
      titulo: "Tipos de Admissão",
      modo: "editar",
    },
  },
  {
    path: "/fptpadmissao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpadmissao/deletar",
      titulo: "Tipos de Admissão",
      modo: "deletar",
    },
  },
  {
    path: "/fptpadmissao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpadmissao/editar",
      titulo: "Tipos de Admissão",
      modo: "visualizar",
    },
  },

  {
    path: "/fptpcategorias",
    component: load("MctDataViewer"),
    props: { source: "fptpcategorias/lista", titulo: "Tipos de Categorias" },
  },
  {
    path: "/fptpcategorias/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fptpcategorias/novo",
      titulo: "Tipos de Categorias",
      modo: "novo",
    },
  },
  {
    path: "/fptpcategorias/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpcategorias/editar",
      titulo: "Tipos de Categorias",
      modo: "editar",
    },
  },
  {
    path: "/fptpcategorias/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpcategorias/deletar",
      titulo: "Tipos de Categorias",
      modo: "deletar",
    },
  },
  {
    path: "/fptpcategorias/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpcategorias/editar",
      titulo: "Tipos de Categorias",
      modo: "visualizar",
    },
  },

  {
    path: "/fptpclassificacao",
    component: load("MctDataViewer"),
    props: {
      source: "fptpclassificacao/lista",
      titulo: "Tipos de função / Classificação",
    },
  },
  {
    path: "/fptpclassificacao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fptpclassificacao/novo",
      titulo: "Tipos de função / Classificação",
      modo: "novo",
    },
  },
  {
    path: "/fptpclassificacao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpclassificacao/editar",
      titulo: "Tipos de função / Classificação",
      modo: "editar",
    },
  },
  {
    path: "/fptpclassificacao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpclassificacao/deletar",
      titulo: "Tipos de função / Classificação",
      modo: "deletar",
    },
  },
  {
    path: "/fptpclassificacao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpclassificacao/editar",
      titulo: "Tipos de função / Classificação",
      modo: "visualizar",
    },
  },

  {
    path: "/fpocorrencias",
    component: load("MctDataViewer"),
    props: { source: "fpocorrencias/lista", titulo: "Ocorrências" },
  },
  {
    path: "/fpocorrencias/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fpocorrencias/novo",
      titulo: "Ocorrências",
      modo: "novo",
    },
  },
  {
    path: "/fpocorrencias/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fpocorrencias/editar",
      titulo: "Ocorrências",
      modo: "editar",
    },
  },
  {
    path: "/fpocorrencias/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpocorrencias/deletar",
      titulo: "Ocorrências",
      modo: "deletar",
    },
  },
  {
    path: "/fpocorrencias/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpocorrencias/editar",
      titulo: "Ocorrências",
      modo: "visualizar",
    },
  },

  {
    path: "/fpvinculos",
    component: load("MctDataViewer"),
    props: { source: "fpvinculos/lista", titulo: "Vínculos" },
  },
  {
    path: "/fpvinculos/novo",
    component: load("MctFormViewer"),
    props: { source: "fpvinculos/novo", titulo: "Vínculos", modo: "novo" },
  },
  {
    path: "/fpvinculos/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "fpvinculos/editar", titulo: "Vínculos", modo: "editar" },
  },
  {
    path: "/fpvinculos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpvinculos/deletar",
      titulo: "Vínculos",
      modo: "deletar",
    },
  },
  {
    path: "/fpvinculos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpvinculos/editar",
      titulo: "Vínculos",
      modo: "visualizar",
    },
  },

  {
    path: "/fptpatopess",
    component: load("MctDataViewer"),
    props: { source: "fptpatopess/lista", titulo: "Tipos de Ato Pessoal" },
  },
  {
    path: "/fptpatopess/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fptpatopess/novo",
      titulo: "Tipos de Ato Pessoal",
      modo: "novo",
    },
  },
  {
    path: "/fptpatopess/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpatopess/editar",
      titulo: "Tipos de Ato Pessoal",
      modo: "editar",
    },
  },
  {
    path: "/fptpatopess/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpatopess/deletar",
      titulo: "Tipos de Ato Pessoal",
      modo: "deletar",
    },
  },
  {
    path: "/fptpatopess/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fptpatopess/editar",
      titulo: "Tipos de Ato Pessoal",
      modo: "visualizar",
    },
  },

  {
    path: "/grauinstrucao",
    component: load("MctDataViewer"),
    props: { source: "grauinstrucao/lista", titulo: "Grau de Instrução" },
  },
  {
    path: "/grauinstrucao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "grauinstrucao/novo",
      titulo: "Grau de Instrução",
      modo: "novo",
    },
  },
  {
    path: "/grauinstrucao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "grauinstrucao/editar",
      titulo: "Grau de Instrução",
      modo: "editar",
    },
  },
  {
    path: "/grauinstrucao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "grauinstrucao/deletar",
      titulo: "Grau de Instrução",
      modo: "deletar",
    },
  },
  {
    path: "/grauinstrucao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "grauinstrucao/editar",
      titulo: "Grau de Instrução",
      modo: "visualizar",
    },
  },

  {
    path: "/grauparentesco",
    component: load("MctDataViewer"),
    props: { source: "grauparentesco/lista", titulo: "Grau de Parentesco" },
  },
  {
    path: "/grauparentesco/novo",
    component: load("MctFormViewer"),
    props: {
      source: "grauparentesco/novo",
      titulo: "Grau de Parentesco",
      modo: "novo",
    },
  },
  {
    path: "/grauparentesco/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "grauparentesco/editar",
      titulo: "Grau de Parentesco",
      modo: "editar",
    },
  },
  {
    path: "/grauparentesco/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "grauparentesco/deletar",
      titulo: "Grau de Parentesco",
      modo: "deletar",
    },
  },
  {
    path: "/grauparentesco/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "grauparentesco/editar",
      titulo: "Grau de Parentesco",
      modo: "visualizar",
    },
  },

  {
    path: "/veicomunicacao",
    component: load("MctDataViewer"),
    props: {
      source: "veiculocomunic/lista",
      titulo: "Veículos de Comunicação",
    },
  },
  {
    path: "/veicomunicacao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/novo",
      titulo: "Veículos de Comunicação",
      modo: "novo",
    },
  },
  {
    path: "/veicomunicacao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/editar",
      titulo: "Veículos de Comunicação",
      modo: "editar",
    },
  },
  {
    path: "/veicomunicacao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/deletar",
      titulo: "Veículos de Comunicação",
      modo: "deletar",
    },
  },
  {
    path: "/veicomunicacao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/editar",
      titulo: "Veículos de Comunicação",
      modo: "visualizar",
    },
  },

  {
    path: "/fpcontratostransfnivel",
    component: load("MctDataViewer"),
    props: {
      source: "fpcontratostransfnivel/lista",
      titulo: "Transf. de Nível por Tempo de Serviço",
    },
  },
  {
    path: "/fpcontratostransfnivel/novo",
    component: loadC("modulos/sigrh/cadastro/ContratosTransfNivel"),
    props: {
      source: "fpcontratostransfnivel/novo",
      titulo: "Transf. de Nível por Tempo de Serviço",
      modo: "novo",
    },
  },
  {
    path: "/fpcontratostransfnivel/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/ContratosTransfNivel"),
    props: {
      source: "fpcontratostransfnivel/editar",
      titulo: "Transf. de Nível por Tempo de Serviço",
      modo: "editar",
    },
  },
  {
    path: "/fpcontratostransfnivel/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/ContratosTransfNivel"),
    props: {
      source: "fpcontratostransfnivel/deletar",
      titulo: "Transf. de Nível por Tempo de Serviço",
      modo: "deletar",
    },
  },
  {
    path: "/fpcontratostransfnivel/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/ContratosTransfNivel"),
    props: {
      source: "fpcontratostransfnivel/editar",
      titulo: "Transf. de Nível por Tempo de Serviço",
      modo: "visualizar",
    },
  },

  {
    path: "/fpats",
    component: load("MctDataViewer"),
    props: { source: "fpats/lista", titulo: "ATS" },
  },
  {
    path: "/fpats/novo",
    component: load("MctFormViewer"),
    props: { source: "fpats/novo", titulo: "ATS", modo: "novo" },
  },
  {
    path: "/fpats/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "fpats/editar", titulo: "ATS", modo: "editar" },
  },
  {
    path: "/fpats/:codigo/deletar",
    component: load("MctFormViewer"),
    props: { source: "fpats/deletar", titulo: "ATS", modo: "deletar" },
  },
  {
    path: "/fpats/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: { source: "fpats/editar", titulo: "ATS", modo: "visualizar" },
  },

  {
    path: "/fpproventos",
    component: load("MctDataViewer"),
    props: { source: "fpproventos/lista", titulo: "Proventos" },
  },
  {
    path: "/fpproventos/novo",
    component: load("MctFormViewer"),
    props: { source: "fpproventos/novo", titulo: "Proventos", modo: "novo" },
  },
  {
    path: "/fpproventos/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fpproventos/editar",
      titulo: "Proventos",
      modo: "editar",
    },
  },
  {
    path: "/fpproventos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpproventos/deletar",
      titulo: "Proventos",
      modo: "deletar",
    },
  },
  {
    path: "/fpproventos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpproventos/editar",
      titulo: "Proventos",
      modo: "visualizar",
    },
  },

  {
    path: "/fpdescontos",
    component: load("MctDataViewer"),
    props: { source: "fpdescontos/lista", titulo: "Descontos" },
  },
  {
    path: "/fpdescontos/novo",
    component: load("MctFormViewer"),
    props: { source: "fpdescontos/novo", titulo: "Descontos", modo: "novo" },
  },
  {
    path: "/fpdescontos/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fpdescontos/editar",
      titulo: "Descontos",
      modo: "editar",
    },
  },
  {
    path: "/fpdescontos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpdescontos/deletar",
      titulo: "Descontos",
      modo: "deletar",
    },
  },
  {
    path: "/fpdescontos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpdescontos/editar",
      titulo: "Descontos",
      modo: "visualizar",
    },
  },

  {
    path: "/feriados",
    component: load("MctDataViewer"),
    props: { source: "feriados/lista", titulo: "Feriados" },
  },
  {
    path: "/feriados/novo",
    component: load("MctFormViewer"),
    props: { source: "feriados/novo", titulo: "Feriados", modo: "novo" },
  },
  {
    path: "/feriados/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "feriados/editar", titulo: "Feriados", modo: "editar" },
  },
  {
    path: "/feriados/:codigo/deletar",
    component: load("MctFormViewer"),
    props: { source: "feriados/deletar", titulo: "Feriados", modo: "deletar" },
  },
  {
    path: "/feriados/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "feriados/editar",
      titulo: "Feriados",
      modo: "visualizar",
    },
  },

  {
    path: "/fpconcursocargo",
    component: load("MctDataViewer"),
    props: { source: "/fpconcursocargo/lista", titulo: "Concurso Cargo" },
  },
  {
    path: "/fpconcursocargo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "/fpconcursocargo/novo",
      titulo: "Concurso Cargo",
      modo: "novo",
    },
  },
  {
    path: "/fpconcursocargo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpconcursocargo/editar",
      titulo: "Concurso Cargo",
      modo: "editar",
    },
  },
  {
    path: "/fpconcursocargo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpconcursocargo/deletar",
      titulo: "Concurso Cargo",
      modo: "deletar",
    },
  },
  {
    path: "/fpconcursocargo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpconcursocargo/editar",
      titulo: "Concurso Cargo",
      modo: "visualizar",
    },
  },

  {
    path: "/fpmovtosegurado",
    component: load("MctDataViewer"),
    props: { source: "/fpmovtosegurado/lista", titulo: "Movimento Segurado" },
  },
  {
    path: "/fpmovtosegurado/novo",
    component: load("MctFormViewer"),
    props: {
      source: "/fpmovtosegurado/novo",
      titulo: "Movimento Segurado",
      modo: "novo",
    },
  },
  {
    path: "/fpmovtosegurado/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpmovtosegurado/editar",
      titulo: "Movimento Segurado",
      modo: "editar",
    },
  },
  {
    path: "/fpmovtosegurado/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpmovtosegurado/deletar",
      titulo: "Movimento Segurado",
      modo: "deletar",
    },
  },
  {
    path: "/fpmovtosegurado/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpmovtosegurado/editar",
      titulo: "Movimento Segurado",
      modo: "visualizar",
    },
  },

  {
    path: "/fpplanosaude",
    component: load("MctDataViewer"),
    props: { source: "/fpplanosaude/lista", titulo: "Plano de Saúde" },
  },
  {
    path: "/fpplanosaude/novo",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/novo",
      titulo: "Plano de Saúde",
      modo: "novo",
    },
  },
  {
    path: "/fpplanosaude/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/editar",
      titulo: "Plano de Saúde",
      modo: "editar",
    },
  },
  {
    path: "/fpplanosaude/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/deletar",
      titulo: "Plano de Saúde",
      modo: "deletar",
    },
  },
  {
    path: "/fpplanosaude/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/editar",
      titulo: "Plano de Saúde",
      modo: "visualizar",
    },
  },

  {
    path: "/fpdescontosproventos",
    component: load("MctDataViewer"),
    props: {
      source: "/fpdescontosproventos/lista",
      titulo: "Descontos Proventos",
    },
  },
  {
    path: "/fpdescontosproventos/novo",
    component: load("MctFormViewer"),
    props: {
      source: "/fpdescontosproventos/novo",
      titulo: "Descontos Proventos",
      modo: "novo",
    },
  },
  {
    path: "/fpdescontosproventos/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpdescontosproventos/editar",
      titulo: "Descontos Proventos",
      modo: "editar",
    },
  },
  {
    path: "/fpdescontosproventos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpdescontosproventos/deletar",
      titulo: "Descontos Proventos",
      modo: "deletar",
    },
  },
  {
    path: "/fpdescontosproventos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpdescontosproventos/editar",
      titulo: "Descontos Proventos",
      modo: "visualizar",
    },
  },

  {
    path: "/fpplanosaude",
    component: load("MctDataViewer"),
    props: { source: "/fpplanosaude/lista", titulo: "Plano de Saúde" },
  },
  {
    path: "/fpplanosaude/novo",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/novo",
      titulo: "Plano de Saúde",
      modo: "novo",
    },
  },
  {
    path: "/fpplanosaude/:plns_codigo/:plns_ano/editar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/editar",
      titulo: "Plano de Saúde",
      modo: "editar",
    },
  },
  {
    path: "/fpplanosaude/:plns_codigo/:plns_ano/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/deletar",
      titulo: "Plano de Saúde",
      modo: "deletar",
    },
  },
  {
    path: "/fpplanosaude/:plns_codigo/:plns_ano/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "/fpplanosaude/editar",
      titulo: "Plano de Saúde",
      modo: "visualizar",
    },
  },

  /*{ path: '/setores', component: load('MctDataViewer'), props: {source:"/setores/lista",titulo:"Setores"}},
    { path: '/setores/novo', component: load('MctFormViewer'), props: {source:"/setores/novo",titulo:"Setores",modo:'novo'} },
    { path: '/setores/:codigo/editar', component: load('MctFormViewer'), props: {source:"/setores/editar",titulo:"Setores",modo:'editar'} },
    { path: '/setores/:codigo/deletar', component: load('MctFormViewer'), props: {source:"/setores/deletar",titulo:"Setores",modo:'deletar'} },
    { path: '/setores/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"/setores/editar",titulo:"Setores",modo:'visualizar'} },*/

  {
    path: "/fpadiantamento",
    component: loadC("modulos/sigrh/lancamentos/AdiantamentoLista"),
    props: { source: "fpadiantamento/lista", titulo: "Adiantamento" },
  },
  {
    path: "/fpadiantamento/novo",
    component: loadC("modulos/sigrh/lancamentos/Adiantamento"),
    props: {
      source: "fpadiantamento/novo",
      titulo: "Adiantamento",
      modo: "novo",
    },
  },
  {
    path: "/fpadiantamento/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/Adiantamento"),
    props: {
      source: "fpadiantamento/editar",
      titulo: "Adiantamento",
      modo: "editar",
    },
  },
  {
    path: "/fpadiantamento/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/Adiantamento"),
    props: {
      source: "fpadiantamento/deletar",
      titulo: "Adiantamento",
      modo: "deletar",
    },
  },
  {
    path: "/fpadiantamento/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/Adiantamento"),
    props: {
      source: "fpadiantamento/editar",
      titulo: "Adiantamento",
      modo: "visualizar",
    },
  },

  //{ path: '/fpcargoscbo', component: load('MctDataViewer'), props: {source:"fpcargoscbo/lista",titulo:"Cargos CBO"}},
  {
    path: "/fpcargoscbo",
    component: loadC("modulos/sigrh/cadastro/CargosCboLista"),
    props: { source: "fpcargoscbo/lista", titulo: "Cargos CBO" },
  },
  {
    path: "/fpcargoscbo/novo",
    component: loadC("modulos/sigrh/cadastro/CargosCbo"),
    props: { source: "fpcargoscbo/novo", titulo: "Cargos CBO", modo: "novo" },
  },
  {
    path: "/fpcargoscbo/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/CargosCbo"),
    props: {
      source: "fpcargoscbo/editar",
      titulo: "Cargos CBO",
      modo: "editar",
    },
  },
  {
    path: "/fpcargoscbo/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/CargosCbo"),
    props: {
      source: "fpcargoscbo/deletar",
      titulo: "Cargos CBO",
      modo: "deletar",
    },
  },
  {
    path: "/fpcargoscbo/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/CargosCbo"),
    props: {
      source: "fpcargoscbo/editar",
      titulo: "Cargos CBO",
      modo: "visualizar",
    },
  },

  {
    path: "/fpdiarias",
    component: loadC("modulos/sigrh/cadastro/DiariasLista"),
    props: { source: "fpdiarias/lista", titulo: "Diárias das Funções" },
  },
  {
    path: "/fpdiarias/novo",
    component: loadC("modulos/sigrh/cadastro/Diarias"),
    props: {
      source: "fpdiarias/novo",
      titulo: "Diárias das Funções",
      modo: "novo",
    },
  },
  {
    path: "/fpdiarias/:vdiar_codigo/:vdiar_ano/:vdiar_mes/editar",
    component: loadC("modulos/sigrh/cadastro/Diarias"),
    props: {
      source: "fpdiarias/editar",
      titulo: "Diárias das Funções",
      modo: "editar",
    },
  },
  {
    path: "/fpdiarias/:vdiar_codigo/:vdiar_ano/:vdiar_mes/deletar",
    component: loadC("modulos/sigrh/cadastro/Diarias"),
    props: {
      source: "fpdiarias/deletar",
      titulo: "Diárias das Funções",
      modo: "deletar",
    },
  },
  {
    path: "/fpdiarias/:vdiar_codigo/:vdiar_ano/:vdiar_mes/visualizar",
    component: loadC("modulos/sigrh/cadastro/Diarias"),
    props: {
      source: "fpdiarias/editar",
      titulo: "Diárias das Funções",
      modo: "visualizar",
    },
  },

  {
    path: "/fphorarios",
    component: load("MctDataViewer"),
    props: { source: "fphorarios/lista", titulo: "Horários" },
  },
  {
    path: "/fphorarios/novo",
    component: loadC("modulos/sigrh/cadastro/Horarios"),
    props: { source: "fphorarios/novo", titulo: "Horários", modo: "novo" },
  },
  {
    path: "/fphorarios/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Horarios"),
    props: { source: "fphorarios/editar", titulo: "Horários", modo: "editar" },
  },
  {
    path: "/fphorarios/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Horarios"),
    props: {
      source: "fphorarios/deletar",
      titulo: "Horários",
      modo: "deletar",
    },
  },
  {
    path: "/fphorarios/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Horarios"),
    props: {
      source: "fphorarios/editar",
      titulo: "Horários",
      modo: "visualizar",
    },
  },

  {
    path: "/fpdependentes",
    component: loadC("modulos/sigrh/cadastro/DependentesLista"),
    props: { source: "fpdependentes/lista", titulo: "Dependentes" },
  },
  {
    path: "/fpdependentes/novo",
    component: loadC("modulos/sigrh/cadastro/Dependentes"),
    props: {
      source: "fpdependentes/novo",
      titulo: "Dependentes",
      modo: "novo",
    },
  },
  {
    path: "/fpdependentes/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Dependentes"),
    props: {
      source: "fpdependentes/editar",
      titulo: "Dependentes",
      modo: "editar",
    },
  },
  {
    path: "/fpdependentes/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Dependentes"),
    props: {
      source: "fpdependentes/deletar",
      titulo: "Dependentes",
      modo: "deletar",
    },
  },
  {
    path: "/fpdependentes/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Dependentes"),
    props: {
      source: "fpdependentes/editar",
      titulo: "Dependentes",
      modo: "visualizar",
    },
  },

  {
    path: "/fpconfig13",
    component: load("MctDataViewer"),
    props: {
      source: "fpconfig13/lista",
      titulo: "Configuração do 13º Salário",
    },
  },
  {
    path: "/fpconfig13/novo",
    component: loadC("modulos/sigrh/cadastro/Config13"),
    props: {
      source: "fpconfig13/novo",
      titulo: "Configuração do 13º Salário",
      modo: "novo",
    },
  },
  {
    path: "/fpconfig13/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Config13"),
    props: {
      source: "fpconfig13/editar",
      titulo: "Configuração do 13º Salário",
      modo: "editar",
    },
  },
  {
    path: "/fpconfig13/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Config13"),
    props: {
      source: "fpconfig13/deletar",
      titulo: "Configuração do 13º Salário",
      modo: "deletar",
    },
  },
  {
    path: "/fpconfig13/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Config13"),
    props: {
      source: "fpconfig13/editar",
      titulo: "Configuração do 13º Salário",
      modo: "visualizar",
    },
  },

  {
    path: "/fpsalfamilia",
    component: load("MctDataViewer"),
    props: { source: "fpsalfamilia/lista", titulo: "Salário Família" },
  },
  {
    path: "/fpsalfamilia/novo",
    component: loadC("modulos/sigrh/cadastro/SalarioFamilia"),
    props: {
      source: "fpsalfamilia/novo",
      titulo: "Salário Família",
      modo: "novo",
    },
  },
  {
    path: "/fpsalfamilia/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/SalarioFamilia"),
    props: {
      source: "fpsalfamilia/editar",
      titulo: "Salário Família",
      modo: "editar",
    },
  },
  {
    path: "/fpsalfamilia/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/SalarioFamilia"),
    props: {
      source: "fpsalfamilia/deletar",
      titulo: "Salário Família",
      modo: "deletar",
    },
  },
  {
    path: "/fpsalfamilia/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/SalarioFamilia"),
    props: {
      source: "fpsalfamilia/editar",
      titulo: "Salário Família",
      modo: "visualizar",
    },
  },

  {
    path: "/fpsalminimo",
    component: load("MctDataViewer"),
    props: { source: "fpsalminimo/lista", titulo: "Salário Mínimo" },
  },
  {
    path: "/fpsalminimo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fpsalminimo/novo",
      titulo: "Salário Mínimo",
      modo: "novo",
    },
  },
  {
    path: "/fpsalminimo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fpsalminimo/editar",
      titulo: "Salário Mínimo",
      modo: "editar",
    },
  },
  {
    path: "/fpsalminimo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpsalminimo/deletar",
      titulo: "Salário Mínimo",
      modo: "deletar",
    },
  },
  {
    path: "/fpsalminimo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpsalminimo/editar",
      titulo: "Salário Mínimo",
      modo: "visualizar",
    },
  },

  {
    path: "/fpcargos",
    component: loadC("modulos/sigrh/cadastro/CargosLista"),
    props: { source: "fpcargos/lista", titulo: "Cargos" },
  },
  {
    path: "/fpcargos/novo",
    component: loadC("modulos/sigrh/cadastro/Cargos"),
    props: { source: "fpcargos/novo", titulo: "Cargos", modo: "novo" },
  },
  {
    path: "/fpcargos/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Cargos"),
    props: { source: "fpcargos/editar", titulo: "Cargos", modo: "editar" },
  },
  {
    path: "/fpcargos/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Cargos"),
    props: { source: "fpcargos/deletar", titulo: "Cargos", modo: "deletar" },
  },
  {
    path: "/fpcargos/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Cargos"),
    props: { source: "fpcargos/editar", titulo: "Cargos", modo: "visualizar" },
  },

  {
    path: "/fpconcursoaprovados",
    component: load("MctDataViewer"),
    props: {
      source: "fpconcursoaprovados/lista",
      titulo: "Aprovados no Concurso",
    },
  },
  {
    path: "/fpconcursoaprovados/novo",
    component: loadC("modulos/sigrh/cadastro/ConcursoAprovados"),
    props: {
      source: "fpconcursoaprovados/novo",
      titulo: "Aprovados no Concurso",
      modo: "novo",
    },
  },
  {
    path: "/fpconcursoaprovados/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/ConcursoAprovados"),
    props: {
      source: "fpconcursoaprovados/editar",
      titulo: "Aprovados no Concurso",
      modo: "editar",
    },
  },
  {
    path: "/fpconcursoaprovados/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/ConcursoAprovados"),
    props: {
      source: "fpconcursoaprovados/deletar",
      titulo: "Aprovados no Concurso",
      modo: "deletar",
    },
  },
  {
    path: "/fpconcursoaprovados/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/ConcursoAprovados"),
    props: {
      source: "fpconcursoaprovados/editar",
      titulo: "Aprovados no Concurso",
      modo: "visualizar",
    },
  },

  {
    path: "/fpcontratostransf",
    component: loadC("modulos/sigrh/cadastro/ContratosTransferenciaLista"),
    props: {
      source: "fpcontratostransf/lista",
      titulo: "Transf. de Cargo ou Setor",
    },
  },
  {
    path: "/fpcontratostransf/novo",
    component: loadC("modulos/sigrh/cadastro/ContratosTransferencia"),
    props: {
      source: "fpcontratostransf/novo",
      titulo: "Transf. de Cargo ou Setor",
      modo: "novo",
    },
  },
  {
    path: "/fpcontratostransf/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/ContratosTransferencia"),
    props: {
      source: "fpcontratostransf/editar",
      titulo: "Transf. de Cargo ou Setor",
      modo: "editar",
    },
  },
  {
    path: "/fpcontratostransf/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/ContratosTransferencia"),
    props: {
      source: "fpcontratostransf/deletar",
      titulo: "Transf. de Cargo ou Setor",
      modo: "deletar",
    },
  },
  {
    path: "/fpcontratostransf/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/ContratosTransferencia"),
    props: {
      source: "fpcontratostransf/editar",
      titulo: "Transf. de Cargo ou Setor",
      modo: "visualizar",
    },
  },

  {
    path: "/fpcontribsindical",
    component: load("MctDataViewer"),
    props: {
      source: "fpcontribsindical/lista",
      titulo: "Contribuição Sindical",
    },
  },
  {
    path: "/fpcontribsindical/novo",
    component: loadC("modulos/sigrh/cadastro/ContribuicaoSindical"),
    props: {
      source: "fpcontribsindical/novo",
      titulo: "Contribuição Sindical",
      modo: "novo",
    },
  },
  {
    path: "/fpcontribsindical/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/ContribuicaoSindical"),
    props: {
      source: "fpcontribsindical/editar",
      titulo: "Contribuição Sindical",
      modo: "editar",
    },
  },
  {
    path: "/fpcontribsindical/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/ContribuicaoSindical"),
    props: {
      source: "fpcontribsindical/deletar",
      titulo: "Contribuição Sindical",
      modo: "deletar",
    },
  },
  {
    path: "/fpcontribsindical/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/ContribuicaoSindical"),
    props: {
      source: "fpcontribsindical/editar",
      titulo: "Contribuição Sindical",
      modo: "visualizar",
    },
  },

  {
    path: "/fpprevidencia",
    component: load("MctDataViewer"),
    props: { source: "fpprevidencia/lista", titulo: "Previdência" },
  },
  {
    path: "/fpprevidencia/novo",
    component: loadC("modulos/sigrh/cadastro/Previdencia"),
    props: {
      source: "fpprevidencia/novo",
      titulo: "Previdência",
      modo: "novo",
    },
  },
  {
    path: "/fpprevidencia/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Previdencia"),
    props: {
      source: "fpprevidencia/editar",
      titulo: "Previdência",
      modo: "editar",
    },
  },
  {
    path: "/fpprevidencia/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Previdencia"),
    props: {
      source: "fpprevidencia/deletar",
      titulo: "Previdência",
      modo: "deletar",
    },
  },
  {
    path: "/fpprevidencia/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Previdencia"),
    props: {
      source: "fpprevidencia/editar",
      titulo: "Previdência",
      modo: "visualizar",
    },
  },

  {
    path: "/irrf",
    component: load("MctDataViewer"),
    props: { source: "irrf/lista", titulo: "IRRF" },
  },
  {
    path: "/irrf/novo",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/novo", titulo: "IRRF", modo: "novo" },
  },
  {
    path: "/irrf/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/editar", titulo: "IRRF", modo: "editar" },
  },
  {
    path: "/irrf/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/deletar", titulo: "IRRF", modo: "deletar" },
  },
  {
    path: "/irrf/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/editar", titulo: "IRRF", modo: "visualizar" },
  },

  {
    path: "/inss",
    component: load("MctDataViewer"),
    props: { source: "inss/lista", titulo: "INSS" },
  },
  {
    path: "/inss/novo",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/novo", titulo: "INSS", modo: "novo" },
  },
  {
    path: "/inss/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/editar", titulo: "INSS", modo: "editar" },
  },
  {
    path: "/inss/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/deletar", titulo: "INSS", modo: "deletar" },
  },
  {
    path: "/inss/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/editar", titulo: "INSS", modo: "visualizar" },
  },

  {
    path: "/fpcausaafastamento",
    component: load("MctDataViewer"),
    props: { source: "fpcausaafastamento/lista", titulo: "Causa Afastamento" },
  },
  {
    path: "/fpcausaafastamento/novo",
    component: loadC("modulos/sigrh/cadastro/CausaAfastamento"),
    props: {
      source: "fpcausaafastamento/novo",
      titulo: "Causa Afastamento",
      modo: "novo",
    },
  },
  {
    path: "/fpcausaafastamento/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/CausaAfastamento"),
    props: {
      source: "fpcausaafastamento/editar",
      titulo: "Causa Afastamento",
      modo: "editar",
    },
  },
  {
    path: "/fpcausaafastamento/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/CausaAfastamento"),
    props: {
      source: "fpcausaafastamento/deletar",
      titulo: "Causa Afastamento",
      modo: "deletar",
    },
  },
  {
    path: "/fpcausaafastamento/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/CausaAfastamento"),
    props: {
      source: "fpcausaafastamento/editar",
      titulo: "Causa Afastamento",
      modo: "visualizar",
    },
  },

  {
    path: "/fpproventoscompetencia",
    component: loadC("modulos/sigrh/cadastro/ProventosCompetenciaLista"),
    props: {
      source: "fpproventoscompetencia/lista",
      titulo: "Proventos na Competência",
    },
  },
  {
    path: "/fpproventoscompetencia/novo",
    component: loadC("modulos/sigrh/cadastro/ProventosCompetencia"),
    props: {
      source: "fpproventoscompetencia/novo",
      titulo: "Proventos na Competência",
      modo: "novo",
    },
  },
  {
    path: "/fpproventoscompetencia/:prv_codigo/:prv_ug/:prv_exercicio/:prv_competencia/editar",
    component: loadC("modulos/sigrh/cadastro/ProventosCompetencia"),
    props: {
      source: "fpproventoscompetencia/editar",
      titulo: "Proventos na Competência",
      modo: "editar",
    },
  },
  {
    path: "/fpproventoscompetencia/:prv_codigo/:prv_ug/:prv_exercicio/:prv_competencia/deletar",
    component: loadC("modulos/sigrh/cadastro/ProventosCompetencia"),
    props: {
      source: "fpproventoscompetencia/deletar",
      titulo: "Proventos na Competência",
      modo: "deletar",
    },
  },
  {
    path: "/fpproventoscompetencia/:prv_codigo/:prv_ug/:prv_exercicio/:prv_competencia/visualizar",
    component: loadC("modulos/sigrh/cadastro/ProventosCompetencia"),
    props: {
      source: "fpproventoscompetencia/editar",
      titulo: "Proventos na Competência",
      modo: "visualizar",
    },
  },

  {
    path: "/fpdescontoscompetencia",
    component: loadC("modulos/sigrh/cadastro/DescontosCompetenciaLista"),
    props: {
      source: "fpdescontoscompetencia/lista",
      titulo: "Descontos na Competência",
    },
  },
  {
    path: "/fpdescontoscompetencia/novo",
    component: loadC("modulos/sigrh/cadastro/DescontosCompetencia"),
    props: {
      source: "fpdescontoscompetencia/novo",
      titulo: "Descontos na Competência",
      modo: "novo",
    },
  },
  {
    path: "/fpdescontoscompetencia/:dsc_codigo/:dsc_ug/:dsc_exercicio/:dsc_competencia/editar",
    component: loadC("modulos/sigrh/cadastro/DescontosCompetencia"),
    props: {
      source: "fpdescontoscompetencia/editar",
      titulo: "Descontos na Competência",
      modo: "editar",
    },
  },
  {
    path: "/fpdescontoscompetencia/:dsc_codigo/:dsc_ug/:dsc_exercicio/:dsc_competencia/deletar",
    component: loadC("modulos/sigrh/cadastro/DescontosCompetencia"),
    props: {
      source: "fpdescontoscompetencia/deletar",
      titulo: "Descontos na Competência",
      modo: "deletar",
    },
  },
  {
    path: "/fpdescontoscompetencia/:dsc_codigo/:dsc_ug/:dsc_exercicio/:dsc_competencia/visualizar",
    component: loadC("modulos/sigrh/cadastro/DescontosCompetencia"),
    props: {
      source: "fpdescontoscompetencia/editar",
      titulo: "Descontos na Competência",
      modo: "visualizar",
    },
  },

  {
    path: "/fphorasextras",
    component: loadC("modulos/sigrh/lancamentos/HorasExtrasLista"),
    props: { source: "fphorasextras/lista", titulo: "Horas Extras" },
  },
  {
    path: "/fphorasextras/novo",
    component: loadC("modulos/sigrh/lancamentos/HorasExtras"),
    props: {
      source: "fphorasextras/novo",
      titulo: "Horas Extras",
      modo: "novo",
    },
  },
  {
    path: "/fphorasextras/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/HorasExtras"),
    props: {
      source: "fphorasextras/editar",
      titulo: "Horas Extras",
      modo: "editar",
    },
  },
  {
    path: "/fphorasextras/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/HorasExtras"),
    props: {
      source: "fphorasextras/deletar",
      titulo: "Horas Extras",
      modo: "deletar",
    },
  },
  {
    path: "/fphorasextras/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/HorasExtras"),
    props: {
      source: "fphorasextras/editar",
      titulo: "Horas Extras",
      modo: "visualizar",
    },
  },

  {
    path: "/fpfuncionarios",
    component: load("MctDataViewer"),
    props: { source: "fpfuncionarios/lista", titulo: "Funcionários" },
  },
  {
    path: "/fpfuncionarios/novo",
    component: loadC("modulos/sigrh/cadastro/Funcionarios"),
    props: {
      source: "fpfuncionarios/novo",
      titulo: "Funcionários",
      modo: "novo",
    },
  },
  {
    path: "/fpfuncionarios/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Funcionarios"),
    props: {
      source: "fpfuncionarios/editar",
      titulo: "Funcionários",
      modo: "editar",
    },
  },
  {
    path: "/fpfuncionarios/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Funcionarios"),
    props: {
      source: "fpfuncionarios/deletar",
      titulo: "Funcionários",
      modo: "deletar",
    },
  },
  {
    path: "/fpfuncionarios/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Funcionarios"),
    props: {
      source: "fpfuncionarios/editar",
      titulo: "Funcionários",
      modo: "visualizar",
    },
  },

  {
    path: "/fpcontratos",
    component: loadC("modulos/sigrh/cadastro/ContratosLista"),
    props: { source: "fpcontratos/lista", titulo: "Contratos" },
  },
  {
    path: "/fpcontratos/novo",
    component: loadC("modulos/sigrh/cadastro/Contratos"),
    props: { source: "fpcontratos/novo", titulo: "Contratos", modo: "novo" },
  },
  {
    path: "/fpcontratos/:cntr_codigo/:cntr_ug/:cntr_exercicio/editar",
    component: loadC("modulos/sigrh/cadastro/Contratos"),
    props: {
      source: "fpcontratos/editar",
      titulo: "Contratos",
      modo: "editar",
    },
  },
  {
    path: "/fpcontratos/:cntr_codigo/:cntr_ug/:cntr_exercicio/deletar",
    component: loadC("modulos/sigrh/cadastro/Contratos"),
    props: {
      source: "fpcontratos/deletar",
      titulo: "Contratos",
      modo: "deletar",
    },
  },
  {
    path: "/fpcontratos/:cntr_codigo/:cntr_ug/:cntr_exercicio/visualizar",
    component: loadC("modulos/sigrh/cadastro/Contratos"),
    props: {
      source: "fpcontratos/editar",
      titulo: "Contratos",
      modo: "visualizar",
    },
  },

  {
    path: "/fpconcurso",
    component: loadC("modulos/sigrh/cadastro/ConcursoLista"),
    props: { source: "fpconcurso/lista", titulo: "Concurso" },
  },
  {
    path: "/fpconcurso/novo",
    component: loadC("modulos/sigrh/cadastro/Concurso"),
    props: { source: "fpconcurso/novo", titulo: "Concurso", modo: "novo" },
  },
  {
    path: "/fpconcurso/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Concurso"),
    props: { source: "fpconcurso/editar", titulo: "Concurso", modo: "editar" },
  },
  {
    path: "/fpconcurso/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Concurso"),
    props: {
      source: "fpconcurso/deletar",
      titulo: "Concurso",
      modo: "deletar",
    },
  },
  {
    path: "/fpconcurso/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Concurso"),
    props: {
      source: "fpconcurso/editar",
      titulo: "Concurso",
      modo: "visualizar",
    },
  },

  {
    path: "/fpinssmultiplos",
    component: loadC("modulos/sigrh/lancamentos/InssMultiplosLista"),
    props: {
      source: "fpinssmultiplos/lista",
      titulo: "INSS Múltiplos Vínculos",
    },
  },
  {
    path: "/fpinssmultiplos/novo",
    component: loadC("modulos/sigrh/lancamentos/InssMultiplos"),
    props: {
      source: "fpinssmultiplos/novo",
      titulo: "INSS Múltiplos Vínculos",
      modo: "novo",
    },
  },
  {
    path: "/fpinssmultiplos/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/InssMultiplos"),
    props: {
      source: "fpinssmultiplos/editar",
      titulo: "INSS Múltiplos Vínculos",
      modo: "editar",
    },
  },
  {
    path: "/fpinssmultiplos/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/InssMultiplos"),
    props: {
      source: "fpinssmultiplos/deletar",
      titulo: "INSS Múltiplos Vínculos",
      modo: "deletar",
    },
  },
  {
    path: "/fpinssmultiplos/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/InssMultiplos"),
    props: {
      source: "fpinssmultiplos/editar",
      titulo: "INSS Múltiplos Vínculos",
      modo: "visualizar",
    },
  },

  {
    path: "/fpprevmultiplos",
    component: loadC("modulos/sigrh/lancamentos/PrevMultiplosLista"),
    props: {
      source: "fpprevmultiplos/lista",
      titulo: "Previdência Múltiplos Vínculos",
    },
  },
  {
    path: "/fpprevmultiplos/novo",
    component: loadC("modulos/sigrh/lancamentos/PrevMultiplos"),
    props: {
      source: "fpprevmultiplos/novo",
      titulo: "Previdência Múltiplos Vínculos",
      modo: "novo",
    },
  },
  {
    path: "/fpprevmultiplos/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/PrevMultiplos"),
    props: {
      source: "fpprevmultiplos/editar",
      titulo: "Previdência Múltiplos Vínculos",
      modo: "editar",
    },
  },
  {
    path: "/fpprevmultiplos/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/PrevMultiplos"),
    props: {
      source: "fpprevmultiplos/deletar",
      titulo: "Previdência Múltiplos Vínculos",
      modo: "deletar",
    },
  },
  {
    path: "/fpprevmultiplos/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/PrevMultiplos"),
    props: {
      source: "fpprevmultiplos/editar",
      titulo: "Previdência Múltiplos Vínculos",
      modo: "visualizar",
    },
  },

  {
    path: "/fpferiasnormais",
    component: loadC("modulos/sigrh/lancamentos/FeriasNormaisLista"),
    props: { source: "fpferiasnormais/lista", titulo: "Férias Normais" },
  },
  {
    path: "/fpferiasnormais/novo",
    component: loadC("modulos/sigrh/lancamentos/FeriasNormais"),
    props: {
      source: "fpferiasnormais/novo",
      titulo: "Férias Normais",
      modo: "novo",
    },
  },
  {
    path: "/fpferiasnormais/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/FeriasNormais"),
    props: {
      source: "fpferiasnormais/editar",
      titulo: "Férias Normais",
      modo: "editar",
    },
  },
  {
    path: "/fpferiasnormais/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/FeriasNormais"),
    props: {
      source: "fpferiasnormais/deletar",
      titulo: "Férias Normais",
      modo: "deletar",
    },
  },
  {
    path: "/fpferiasnormais/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/FeriasNormais"),
    props: {
      source: "fpferiasnormais/editar",
      titulo: "Férias Normais",
      modo: "visualizar",
    },
  },

  {
    path: "/fpferiasindenizadas",
    component: loadC("modulos/sigrh/lancamentos/FeriasIndenizadasLista"),
    props: {
      source: "fpferiasindenizadas/lista",
      titulo: "Férias Indenizadas",
    },
  },
  {
    path: "/fpferiasindenizadas/novo",
    component: loadC("modulos/sigrh/lancamentos/FeriasIndenizadas"),
    props: {
      source: "fpferiasindenizadas/novo",
      titulo: "Férias Indenizadas",
      modo: "novo",
    },
  },
  {
    path: "/fpferiasindenizadas/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/FeriasIndenizadas"),
    props: {
      source: "fpferiasindenizadas/editar",
      titulo: "Férias Indenizadas",
      modo: "editar",
    },
  },
  {
    path: "/fpferiasindenizadas/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/FeriasIndenizadas"),
    props: {
      source: "fpferiasindenizadas/deletar",
      titulo: "Férias Indenizadas",
      modo: "deletar",
    },
  },
  {
    path: "/fpferiasindenizadas/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/FeriasIndenizadas"),
    props: {
      source: "fpferiasindenizadas/editar",
      titulo: "Férias Indenizadas",
      modo: "visualizar",
    },
  },

  {
    path: "/fprescisao",
    component: loadC("modulos/sigrh/lancamentos/RescisaoLista"),
    props: { source: "/fprescisao/lista", titulo: "Rescisão Contratual" },
  },
  {
    path: "/fprescisao/novo",
    component: loadC("modulos/sigrh/lancamentos/Rescisao"),
    props: {
      source: "/fprescisao/novo",
      titulo: "Rescisão Contratual",
      modo: "novo",
    },
  },
  {
    path: "/fprescisao/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/Rescisao"),
    props: {
      source: "/fprescisao/editar",
      titulo: "Rescisão Contratual",
      modo: "editar",
    },
  },
  {
    path: "/fprescisao/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/Rescisao"),
    props: {
      source: "/fprescisao/deletar",
      titulo: "Rescisão Contratual",
      modo: "deletar",
    },
  },
  {
    path: "/fprescisao/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/Rescisao"),
    props: {
      source: "/fprescisao/editar",
      titulo: "Rescisão Contratual",
      modo: "visualizar",
    },
  },

  {
    path: "/fpafastamento",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoLista"),
    props: { source: "/fpafastamento/lista", titulo: "Afastamentos" },
  },
  {
    path: "/fpafastamento/novo",
    component: loadC("modulos/sigrh/lancamentos/Afastamento"),
    props: {
      source: "/fpafastamento/novo",
      titulo: "Afastamentos",
      modo: "novo",
    },
  },
  {
    path: "/fpafastamento/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/Afastamento"),
    props: {
      source: "/fpafastamento/editar",
      titulo: "Afastamentos",
      modo: "editar",
    },
  },
  {
    path: "/fpafastamento/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/Afastamento"),
    props: {
      source: "/fpafastamento/deletar",
      titulo: "Afastamentos",
      modo: "deletar",
    },
  },
  {
    path: "/fpafastamento/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/Afastamento"),
    props: {
      source: "/fpafastamento/editar",
      titulo: "Afastamentos",
      modo: "visualizar",
    },
  },

  {
    path: "/fpafastamentoretorno",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoRetornoLista"),
    props: { source: "/fpafastamento/lista", titulo: "Retorno Afastamentos" },
  },
  //{ path: '/fpafastamentoretorno/novo', component: loadC('modulos/sigrh/lancamentos/AfastamentoRetorno'), props: {source:"/fpafastamento/novo",titulo:"Retorno Afastamentos",modo:'novo'} },
  {
    path: "/fpafastamentoretorno/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoRetorno"),
    props: {
      source: "/fpafastamento/editar",
      titulo: "Retorno Afastamentos",
      modo: "editar",
    },
  },
  //{ path: '/fpafastamentoretorno/:codigo/deletar', component: loadC('modulos/sigrh/lancamentos/AfastamentoRetorno'), props: {source:"/fpafastamento/deletar",titulo:"Retorno Afastamentos",modo:'deletar'} },
  {
    path: "/fpafastamentoretorno/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoRetorno"),
    props: {
      source: "/fpafastamento/editar",
      titulo: "Retorno Afastamentos",
      modo: "visualizar",
    },
  },

  {
    path: "/fpconcursofase",
    component: load("MctDataViewer"),
    props: { source: "fpconcursofase/lista", titulo: "Concurso Fase" },
  },
  {
    path: "/fpconcursofase/novo",
    component: load("MctFormViewer"),
    props: {
      source: "fpconcursofase/novo",
      titulo: "Concurso Fase",
      modo: "novo",
    },
  },
  {
    path: "/fpconcursofase/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "fpconcursofase/editar",
      titulo: "Concurso Fase",
      modo: "editar",
    },
  },
  {
    path: "/fpconcursofase/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "fpconcursofase/deletar",
      titulo: "Concurso Fase",
      modo: "deletar",
    },
  },
  {
    path: "/fpconcursofase/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "fpconcursofase/editar",
      titulo: "Concurso Fase",
      modo: "visualizar",
    },
  },

  {
    path: "/fpadiantamentogeral",
    component: loadC("modulos/sigrh/lancamentos/AdiantamentoGeral"),
    props: {
      source: "fpadiantamento/novo",
      titulo: "Adiantamento Geral",
      modo: "novo",
    },
  },

  {
    path: "/fpfichaponto",
    component: loadC("modulos/sigrh/lancamentos/FichaPontoLista"),
    props: { source: "fpfichaponto/lista", titulo: "Ficha de Ponto" },
  },
  {
    path: "/fpfichaponto/novo",
    component: loadC("modulos/sigrh/lancamentos/FichaPonto"),
    props: {
      source: "fpfichaponto/novo",
      titulo: "Ficha de Ponto",
      modo: "novo",
    },
  },
  {
    path: "/fpfichaponto/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/FichaPonto"),
    props: {
      source: "fpfichaponto/editar",
      titulo: "Ficha de Ponto",
      modo: "editar",
    },
  },
  {
    path: "/fpfichaponto/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/FichaPonto"),
    props: {
      source: "fpfichaponto/deletar",
      titulo: "Ficha de Ponto",
      modo: "deletar",
    },
  },
  {
    path: "/fpfichaponto/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/FichaPonto"),
    props: {
      source: "fpfichaponto/editar",
      titulo: "Ficha de Ponto",
      modo: "visualizar",
    },
  },

  {
    path: "/fpprorrogacaosalmat",
    component: loadC("modulos/sigrh/lancamentos/ProrrogacaoSalMatLista"),
    props: {
      source: "fpprorrogacaosalmat/lista",
      titulo: "Prorrogação de Salário Maternidade",
    },
  },
  {
    path: "/fpprorrogacaosalmat/novo",
    component: loadC("modulos/sigrh/lancamentos/ProrrogacaoSalMat"),
    props: {
      source: "fpprorrogacaosalmat/novo",
      titulo: "Prorrogação de Salário Maternidade",
      modo: "novo",
    },
  },
  {
    path: "/fpprorrogacaosalmat/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/ProrrogacaoSalMat"),
    props: {
      source: "fpprorrogacaosalmat/editar",
      titulo: "Prorrogação de Salário Maternidade",
      modo: "editar",
    },
  },
  {
    path: "/fpprorrogacaosalmat/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/ProrrogacaoSalMat"),
    props: {
      source: "fpprorrogacaosalmat/deletar",
      titulo: "Prorrogação de Salário Maternidade",
      modo: "deletar",
    },
  },
  {
    path: "/fpprorrogacaosalmat/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/ProrrogacaoSalMat"),
    props: {
      source: "fpprorrogacaosalmat/editar",
      titulo: "Prorrogação de Salário Maternidade",
      modo: "visualizar",
    },
  },

  {
    path: "/fpafastamentoindenizado",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoIndenizadoLista"),
    props: {
      source: "fpafastamentoindenizado/lista",
      titulo: "Licença Prêmio Indenizada",
    },
  },
  {
    path: "/fpafastamentoindenizado/novo",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoIndenizado"),
    props: {
      source: "fpafastamentoindenizado/novo",
      titulo: "Licença Prêmio Indenizada",
      modo: "novo",
    },
  },
  {
    path: "/fpafastamentoindenizado/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoIndenizado"),
    props: {
      source: "fpafastamentoindenizado/editar",
      titulo: "Licença Prêmio Indenizada",
      modo: "editar",
    },
  },
  {
    path: "/fpafastamentoindenizado/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoIndenizado"),
    props: {
      source: "fpafastamentoindenizado/deletar",
      titulo: "Licença Prêmio Indenizada",
      modo: "deletar",
    },
  },
  {
    path: "/fpafastamentoindenizado/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/AfastamentoIndenizado"),
    props: {
      source: "fpafastamentoindenizado/editar",
      titulo: "Licença Prêmio Indenizada",
      modo: "visualizar",
    },
  },

  {
    path: "/fpcontratosdescontos",
    component: loadC("modulos/sigrh/lancamentos/ContratosProventosDescontos"),
    props: {
      source: "fpcontratosproventosdescontos/novo",
      titulo: "Proventos e Descontos",
      modo: "novo",
    },
  }, // >> LANÇAMENTOS >> PROVENTOS E DESCONTOS >> INDIVIDUAL
  //{ path: '/fpcontratosdescontos', component: loadC('modulos/sigrh/lancamentos/ContratosProventosDescontos'), props: {source:"fpcontratosproventosdescontos/editar",titulo:"Proventos e Descontos", modo:'editar'} },

  {
    path: "/fpcontrdescgeral",
    component: loadC(
      "modulos/sigrh/lancamentos/ContratosProventosDescontosGeral"
    ),
    props: {
      source: "fpcontratosproventosdescontosgeral/novo",
      titulo: "Proventos e Descontos Geral",
      modo: "novo",
    },
  },

  {
    path: "/aumentogeralsalario",
    component: loadC("modulos/sigrh/lancamentos/AumentoGeralSalarioLista"),
    props: { source: "fpcargos/lista", titulo: "Aumento Geral" },
  },

  {
    path: "/folhapagtocntr",
    component: loadC("modulos/sigrh/calculofolha/FolhaPagamentoContratoTeste"),
    props: {
      source: "fpfolpgto/novo",
      titulo: "Cálculo Folha de Pagamento por Contrato",
      modo: "novo",
    },
  },

  {
    path: "/folhapagtogeral",
    component: loadC("modulos/sigrh/calculofolha/FolhaPagamentoGeral"),
    props: {
      source: "fpfolpgtogeral/novo",
      titulo: "Calcular Folha de Pagamento",
      modo: "novo",
    },
  },
  //{ path: '/fpfolpgto', component: loadC('modulos/sigrh/calculofolha/CalcularFolhaPagamento'), props: {source:"fpfolpgto/novo",titulo:"Calculo Geral da Folha de Pagamento",  modo:'novo'}},

  {
    path: "/listadescontos",
    component: load("MctFormViewer"),
    props: {
      source: "relatoriodescontos/combo",
      titulo: "Relatório Descontos",
      modo: "novo",
    },
  },

  {
    path: "/fpfaltas",
    component: load("MctDataViewer"),
    props: { source: "fpfaltas/lista", titulo: "Faltas Injustificadas" },
  },
  {
    path: "/fpfaltas/novo",
    component: loadC("modulos/sigrh/lancamentos/Faltas"),
    props: {
      source: "fpfaltas/novo",
      titulo: "Faltas Injustificadas",
      modo: "novo",
    },
  },
  {
    path: "/fpfaltas/:codigo/editar",
    component: loadC("modulos/sigrh/lancamentos/Faltas"),
    props: {
      source: "fpfaltas/editar",
      titulo: "Faltas Injustificadas",
      modo: "editar",
    },
  },
  {
    path: "/fpfaltas/:codigo/deletar",
    component: loadC("modulos/sigrh/lancamentos/Faltas"),
    props: {
      source: "fpfaltas/deletar",
      titulo: "Faltas Injustificadas",
      modo: "deletar",
    },
  },
  {
    path: "/fpfaltas/:codigo/visualizar",
    component: loadC("modulos/sigrh/lancamentos/Faltas"),
    props: {
      source: "fpfaltas/editar",
      titulo: "Faltas Injustificadas",
      modo: "visualizar",
    },
  },
];

//{source:"fpcontratosdescontos/novo",titulo:"Proventos e Descontos",modo:'novo'}
