export default function(val) {
    if (val == null )
        return '';
    let somaHors = 0.00
    let somaMins = 0.00
    let somaSegs = 0.00
    let vlrMin = Number(val.replace(':','.'))
    somaMins += Math.trunc(vlrMin)
    somaSegs += vlrMin - Math.trunc(vlrMin)

    while (somaSegs >= 60){
        somaMins += 1
        somaSegs -= 60     
    }
    while (somaMins >= 60){
        somaHors += 1
        somaMins -= 60     
    }
    return ("00" + somaHors).slice(-2)+':'+("00" + somaMins).slice(-2)//+':'+("00" + somaSegs).slice(-2)

} 
