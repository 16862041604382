<template>
    
</template>
<script>
export default {
    props: ['link'],
    created () {
        window.location.assign(this.link);    
    }
}
</script>

