export default function(val) {
    if (val == null )
        return '';

    if (val.length > 5){
        return val.substring(0,5)
    }else{
        return val
    }

} 
