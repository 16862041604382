import NotFound from "../components/NotFound.vue";
import MctOpenFile from "../components/comum/MctOpenFile.vue";

function load(component) {
  return () => import(`../components/comum/${component}.vue`);
}

function loadC(component) {
  return () => import(`../components/${component}.vue`);
}

export default [
  { path: "/", name: "Inicio" },
  { path: "/not-found", component: NotFound },
  { path: "*", component: NotFound },
  { path: "/openfile", component: MctOpenFile },

  { path: "/logar", component: loadC("seguranca/MctLogar") },
  { path: "/modulos", component: loadC("seguranca/MctModulos") },
  { path: "/submodulos", component: loadC("seguranca/MctSubModulos") },
  { path: "/alterarsenha", component: loadC("seguranca/MctAlterarSenha") },
  {
    path: "/privilegios",
    component: loadC("seguranca/MctPrivilegios"),
    props: { source: "privilegios/novo", titulo: "Privilégios" },
  },
  { path: "/exercicios", component: loadC("seguranca/MctExercicios") },
  { path: "/competencias", component: loadC("seguranca/MctCompetencias") },
  { path: "/upload", component: load("MctFileUpload") },

  {
    path: "/modulo",
    component: load("MctDataViewer"),
    props: { source: "modulo/lista", titulo: "Módulo" },
  },
  {
    path: "/modulo/novo",
    component: load("MctFormViewer"),
    props: { source: "modulo/novo", titulo: "Módulo", modo: "novo" },
  },
  {
    path: "/modulo/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "modulo/editar", titulo: "Módulo", modo: "editar" },
  },
  {
    path: "/modulo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: { source: "modulo/deletar", titulo: "Módulo", modo: "deletar" },
  },
  {
    path: "/modulo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: { source: "modulo/editar", titulo: "Módulo", modo: "visualizar" },
  },

  {
    path: "/submodulo",
    component: load("MctDataViewer"),
    props: { source: "submodulo/lista", titulo: "Sub-Módulo" },
  },
  {
    path: "/submodulo/novo",
    component: load("MctFormViewer"),
    props: { source: "submodulo/novo", titulo: "Sub-Módulo", modo: "novo" },
  },
  {
    path: "/submodulo/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "submodulo/editar", titulo: "Sub-Módulo", modo: "editar" },
  },
  {
    path: "/submodulo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "submodulo/deletar",
      titulo: "Sub-Módulo",
      modo: "deletar",
    },
  },
  {
    path: "/submodulo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "submodulo/editar",
      titulo: "Sub-Módulo",
      modo: "visualizar",
    },
  },

  {
    path: "/perfil",
    component: load("MctDataViewer"),
    props: { source: "perfil/lista", titulo: "Perfil" },
  },
  {
    path: "/perfil/novo",
    component: loadC("seguranca/MctPerfilCad"),
    props: { source: "perfil/novo", titulo: "Perfil", modo: "novo" },
  },
  {
    path: "/perfil/:per_codigo/:ug_codigo/editar",
    component: loadC("seguranca/MctPerfilCad"),
    props: { source: "perfil/editar", titulo: "Perfil", modo: "editar" },
  },
  {
    path: "/perfil/:per_codigo/:ug_codigo/deletar",
    component: loadC("seguranca/MctPerfilCad"),
    props: { source: "perfil/deletar", titulo: "Perfil", modo: "deletar" },
  },
  {
    path: "/perfil/:per_codigo/:ug_codigo/visualizar",
    component: loadC("seguranca/MctPerfilCad"),
    props: { source: "perfil/editar", titulo: "Perfil", modo: "visualizar" },
  },

  {
    path: "/usuarios",
    component: load("MctDataViewer"),
    props: { source: "usuarios/lista", titulo: "Usuários" },
  },
  {
    path: "/usuarios/novo",
    component: loadC("seguranca/MctUsuarioCad"),
    props: { source: "usuarios/novo", titulo: "Usuários", modo: "novo" },
  },
  {
    path: "/usuarios/:usu_codigo/:ug_codigo/editar",
    component: loadC("seguranca/MctUsuarioCad"),
    props: { source: "usuarios/editar", titulo: "Usuários", modo: "editar" },
  },
  {
    path: "/usuarios/:usu_codigo/:ug_codigo/deletar",
    component: loadC("seguranca/MctUsuarioCad"),
    props: { source: "usuarios/deletar", titulo: "Usuários", modo: "deletar" },
  },
  {
    path: "/usuarios/:usu_codigo/:ug_codigo/visualizar",
    component: loadC("seguranca/MctUsuarioCad"),
    props: {
      source: "usuarios/editar",
      titulo: "Usuários",
      modo: "visualizar",
    },
  },

  {
    path: "/menu",
    component: load("MctDataViewer"),
    props: { source: "menu/lista", titulo: "Menu" },
  },
  {
    path: "/menu/novo",
    component: loadC("seguranca/MctMenuCad"),
    props: { source: "menu/novo", titulo: "Menu", modo: "novo" },
  },
  {
    path: "/menu/:codigo/editar",
    component: loadC("seguranca/MctMenuCad"),
    props: { source: "menu/editar", titulo: "Menu", modo: "editar" },
  },
  {
    path: "/menu/:codigo/deletar",
    component: loadC("seguranca/MctMenuCad"),
    props: { source: "menu/deletar", titulo: "Menu", modo: "deletar" },
  },
  {
    path: "/menu/:codigo/visualizar",
    component: loadC("seguranca/MctMenuCad"),
    props: { source: "menu/editar", titulo: "Menu", modo: "visualizar" },
  },

  {
    path: "/entidade",
    component: load("MctDataViewer"),
    props: { source: "entidade/lista", titulo: "Entidade" },
  },
  {
    path: "/entidade/novo",
    component: loadC("seguranca/MctEntidadeCad"),
    props: { source: "entidade/novo", titulo: "Entidade", modo: "novo" },
  },
  {
    path: "/entidade/:codigo/editar",
    component: loadC("seguranca/MctEntidadeCad"),
    props: { source: "entidade/editar", titulo: "Entidade", modo: "editar" },
  },
  {
    path: "/entidade/:codigo/deletar",
    component: loadC("seguranca/MctEntidadeCad"),
    props: { source: "entidade/deletar", titulo: "Entidade", modo: "deletar" },
  },
  {
    path: "/entidade/:codigo/visualizar",
    component: loadC("seguranca/MctEntidadeCad"),
    props: {
      source: "entidade/editar",
      titulo: "Entidade",
      modo: "visualizar",
    },
  },

  {
    path: "/pessoa",
    component: loadC("modulos/comum/PessoasLista"),
    props: { source: "pessoas/lista", titulo: "Pessoas" },
  },
  {
    path: "/pessoa/novo",
    component: loadC("modulos/comum/Pessoas"),
    props: { source: "pessoas/novo", titulo: "Pessoas", modo: "novo" },
  },
  {
    path: "/pessoa/:codigo/editar",
    component: loadC("modulos/comum/Pessoas"),
    props: { source: "pessoas/editar", titulo: "Pessoas", modo: "editar" },
  },
  {
    path: "/pessoa/:codigo/deletar",
    component: loadC("modulos/comum/Pessoas"),
    props: { source: "pessoas/deletar", titulo: "Pessoas", modo: "deletar" },
  },
  {
    path: "/pessoa/:codigo/visualizar",
    component: loadC("modulos/comum/Pessoas"),
    props: { source: "pessoas/editar", titulo: "Pessoas", modo: "visualizar" },
  },

  {
    path: "/unimedida",
    component: load("MctDataViewer"),
    props: { source: "unimedida/lista", titulo: "Unidades de Medida" },
  },
  {
    path: "/unimedida/novo",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/novo",
      titulo: "Unidades de Medida",
      modo: "novo",
    },
  },
  {
    path: "/unimedida/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/editar",
      titulo: "Unidades de Medida",
      modo: "editar",
    },
  },
  {
    path: "/unimedida/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/deletar",
      titulo: "Unidades de Medida",
      modo: "deletar",
    },
  },
  {
    path: "/unimedida/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/editar",
      titulo: "Unidades de Medida",
      modo: "visualizar",
    },
  },

  {
    path: "/banco",
    component: load("MctDataViewer"),
    props: { source: "banco/lista", titulo: "Bancos" },
  },
  {
    path: "/banco/novo",
    component: load("MctFormViewer"),
    props: { source: "banco/novo", titulo: "Bancos", modo: "novo" },
  },
  {
    path: "/banco/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "banco/editar", titulo: "Bancos", modo: "editar" },
  },
  {
    path: "/banco/:codigo/deletar",
    component: load("MctFormViewer"),
    props: { source: "banco/deletar", titulo: "Bancos", modo: "deletar" },
  },
  {
    path: "/banco/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: { source: "banco/editar", titulo: "Bancos", modo: "visualizar" },
  },

  {
    path: "/agenciabancaria",
    component: load("MctDataViewer"),
    props: { source: "agenciabancaria/lista", titulo: "Agências Bancárias" },
  },
  {
    path: "/agenciabancaria/novo",
    component: load("MctFormViewer"),
    props: {
      source: "agenciabancaria/novo",
      titulo: "Agências Bancárias",
      modo: "novo",
    },
  },
  {
    path: "/agenciabancaria/:age_codigo/:bnc_codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "agenciabancaria/editar",
      titulo: "Agências Bancárias",
      modo: "editar",
    },
  },
  {
    path: "/agenciabancaria/:age_codigo/:bnc_codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "agenciabancaria/deletar",
      titulo: "Agências Bancárias",
      modo: "deletar",
    },
  },
  {
    path: "/agenciabancaria/:age_codigo/:bnc_codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "agenciabancaria/editar",
      titulo: "Agências Bancárias",
      modo: "visualizar",
    },
  },
];
