export default function(val,legend, digits, options) {
    if (isNaN(val)) {
        return '';
    }

    if (legend === '') {
        var formatter = new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: digits,        
        });
        return formatter.format(val);
    } else {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: digits,        
        });
        return formatter.format(val);
    }

    //return Number(val).toLocaleString('pt-BR', { minimumFractionDigits: 2,  maximumFractionDigits: 2})
    //return format(val, assign(opt, defaults));
} 