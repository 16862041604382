function load (component) {
    return () => import(`../components/comum/${component}.vue`)
  }

  export default [

    { path:'/nacionalidade', component: load('MctDataViewer'), props: {source:"nacionalidade/lista",titulo:"Nacionalidade"}},
    { path: '/nacionalidade/novo', component: load('MctFormViewer'), props: {source:"nacionalidade/novo",titulo:"Nacionalidade",modo:'novo'} },
    { path: '/nacionalidade/:codigo/editar', component: load('MctFormViewer'), props: {source:"nacionalidade/editar",titulo:"Nacionalidade",modo:'editar'} },
    { path: '/nacionalidade/:codigo/deletar', component: load('MctFormViewer'), props: {source:"nacionalidade/deletar",titulo:"Nacionalidade",modo:'deletar'} },
    { path: '/nacionalidade/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"nacionalidade/editar",titulo:"Nacionalidade",modo:'visualizar'} },

    { path:'/pais', component: load('MctDataViewer'), props: {source:"pais/lista",titulo:"País"}},
    { path: '/pais/novo', component: load('MctFormViewer'), props: {source:"pais/novo",titulo:"País",modo:'novo'} },
    { path: '/pais/:codigo/editar', component: load('MctFormViewer'), props: {source:"pais/editar",titulo:"País",modo:'editar'} },
    { path: '/pais/:codigo/deletar', component: load('MctFormViewer'), props: {source:"pais/deletar",titulo:"País",modo:'deletar'} },
    { path: '/pais/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"pais/editar",titulo:"País",modo:'visualizar'} },

    { path:'/estado', component: load('MctDataViewer'), props: {source:"estado/lista",titulo:"Estado"}},
    { path: '/estado/novo', component: load('MctFormViewer'), props: {source:"estado/novo",titulo:"Estado",modo:'novo'} },
    { path: '/estado/:codigo/editar', component: load('MctFormViewer'), props: {source:"estado/editar",titulo:"Estado",modo:'editar'} },
    { path: '/estado/:codigo/deletar', component: load('MctFormViewer'), props: {source:"estado/deletar",titulo:"Estado",modo:'deletar'} },
    { path: '/estado/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"estado/editar",titulo:"Estado",modo:'visualizar'} },

    { path:'/cidade', component: load('MctDataViewer'), props: {source:"cidade/lista",titulo:"Cidade"}},
    { path: '/cidade/novo', component: load('MctFormViewer'), props: {source:"cidade/novo",titulo:"Cidade",modo:'novo'} },
    { path: '/cidade/:codigo/editar', component: load('MctFormViewer'), props: {source:"cidade/editar",titulo:"Cidade",modo:'editar'} },
    { path: '/cidade/:codigo/deletar', component: load('MctFormViewer'), props: {source:"cidade/deletar",titulo:"Cidade",modo:'deletar'} },
    { path: '/cidade/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"cidade/editar",titulo:"Cidade",modo:'visualizar'} },

    { path:'/bairro', component: load('MctDataViewer'), props: {source:"bairro/lista",titulo:"Bairro"}},
    { path: '/bairro/novo', component: load('MctFormViewer'), props: {source:"bairro/novo",titulo:"Bairro",modo:'novo'} },
    { path: '/bairro/:codigo/editar', component: load('MctFormViewer'), props: {source:"bairro/editar",titulo:"Bairro",modo:'editar'} },
    { path: '/bairro/:codigo/deletar', component: load('MctFormViewer'), props: {source:"bairro/deletar",titulo:"Bairro",modo:'deletar'} },
    { path: '/bairro/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"bairro/editar",titulo:"Bairro",modo:'visualizar'} },

    { path:'/tplogradouro', component: load('MctDataViewer'), props: {source:"tplogradouro/lista",titulo:"Tipo Logradouro"}},
    { path: '/tplogradouro/novo', component: load('MctFormViewer'), props: {source:"tplogradouro/novo",titulo:"Tipo Logradouro",modo:'novo'} },
    { path: '/tplogradouro/:codigo/editar', component: load('MctFormViewer'), props: {source:"tplogradouro/editar",titulo:"Tipo Logradouro",modo:'editar'} },
    { path: '/tplogradouro/:codigo/deletar', component: load('MctFormViewer'), props: {source:"tplogradouro/deletar",titulo:"Tipo Logradouro",modo:'deletar'} },
    { path: '/tplogradouro/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"tplogradouro/editar",titulo:"Tipo Logradouro",modo:'visualizar'} },

    { path:'/logradouro', component: load('MctDataViewer'), props: {source:"logradouro/lista",titulo:"Logradouro"}},
    { path: '/logradouro/novo', component: load('MctFormViewer'), props: {source:"logradouro/novo",titulo:"Logradouro",modo:'novo'} },
    { path: '/logradouro/:codigo/editar', component: load('MctFormViewer'), props: {source:"logradouro/editar",titulo:"Logradouro",modo:'editar'} },
    { path: '/logradouro/:codigo/deletar', component: load('MctFormViewer'), props: {source:"logradouro/deletar",titulo:"Logradouro",modo:'deletar'} },
    { path: '/logradouro/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"logradouro/editar",titulo:"Logradouro",modo:'visualizar'} },

    { path:'/quadra', component: load('MctDataViewer'), props: {source:"quadra/lista",titulo:"Quadra"}},
    { path: '/quadra/novo', component: load('MctFormViewer'), props: {source:"quadra/novo",titulo:"Quadra",modo:'novo'} },
    { path: '/quadra/:codigo/editar', component: load('MctFormViewer'), props: {source:"quadra/editar",titulo:"Quadra",modo:'editar'} },
    { path: '/quadra/:codigo/deletar', component: load('MctFormViewer'), props: {source:"quadra/deletar",titulo:"Quadra",modo:'deletar'} },
    { path: '/quadra/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"quadra/editar",titulo:"Quadra",modo:'visualizar'} },

];
