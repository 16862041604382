import axios from 'axios'

var url = process.env.VUE_APP_BASE_API;

const instance = axios.create({
  baseURL: url
});


export default instance

/*
import axios from 'axios'
import Vue from 'vue'
import router from './router'
import store from './store'


const path = window.location.pathname;
//console.log(path);

var url = path === '/portal' ? process.env.VUE_APP_BASE_PORTAL : process.env.VUE_APP_BASE_API;



const instance = axios.create({
  baseURL: url
});


instance.interceptors.response.use(
  // antes que uma resposta seja retornada, pare nprogress
  response => {
    NProgress.done()
    return response
  },
  // antes que uma solicitação retorne erro, para o nprogress, mostre uma msg de error e retorne a tela de login 
  error => {
    if (error.message === 'Token has expired' || error.message === 'Request failed with status code 401'){
        localStorage.removeItem("mct_user_token")
        Vue.swal({
          type: 'error',
          title: 'A conexão com o servidor expirou, por favor faça novamente o login no sistema.',
          text: error.message,
        })
        store.dispatch('Login/USU_LOGOUT');
        router.push({
          pathname: '/logar',
        });  
      }
    NProgress.done()
    return Promise.reject(error);
  }  
);

// antes que uma solicitação seja feita, inicie o nprogress
instance.interceptors.request.use(config => {
  NProgress.start()  
  return config
});

export default instance;
*/