import * as MutationTypes from './MutationTypes';

const state = {    
    dataP: [],
    showP: false,
};

const mutations = {       
    [MutationTypes.ABRIR_POPUP](state, data) {        
        //console.log(data)
        state.dataP = data[0]
        state.showP = data[1]
    },
    [MutationTypes.FECHAR_POPUP](state, data) {        
        //console.log(data)
        state.showP = data
    }

};

const actions = {
    [MutationTypes.ABRIR_POPUP]({ commit }, data) {
        //console.log(data)
        commit(MutationTypes.ABRIR_POPUP, data);        
    },
    [MutationTypes.FECHAR_POPUP]({ commit }, data) {
        //console.log(data)
        commit(MutationTypes.FECHAR_POPUP, data);        
    }
};

const getters = {
    dataP: state => state.dataP,
    showP: state => state.showP,
};


export default{
    namespaced: true,
    state, 
    actions, 
    mutations, 
    getters
}