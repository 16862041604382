function load(component) {
  return () => import(`../components/comum/${component}.vue`);
}

function loadC(component) {
  return () => import(`../components/${component}.vue`);
}

export default [
  //FRONT - CADASTRO FORNECEDORES
  {
    path: "/pessoas",
    component: loadC("modulos/comum/PessoasLista"),
    props: { source: "pessoas/lista", titulo: "PESSOAS / CREDORES" },
  },
  {
    path: "/pessoas/novo",
    component: loadC("modulos/comum/Pessoas"),
    props: {
      source: "pessoas/novo",
      titulo: "PESSOAS / CREDORES",
      modo: "novo",
    },
  },
  {
    path: "/pessoas/:codigo/editar",
    component: loadC("modulos/comum/Pessoas"),
    props: {
      source: "pessoas/editar",
      titulo: "PESSOAS / CREDORES",
      modo: "editar",
    },
  },
  {
    path: "/pessoas/:codigo/deletar",
    component: loadC("modulos/comum/Pessoas"),
    props: {
      source: "pessoas/deletar",
      titulo: "PESSOAS / CREDORES",
      modo: "deletar",
    },
  },
  {
    path: "/pessoas/:codigo/visualizar",
    component: loadC("modulos/comum/Pessoas"),
    props: {
      source: "pessoas/editar",
      titulo: "PESSOAS / CREDORES",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO CADASTRAR CRC
  {
    path: "/crcfornecedor",
    component: load("MctDataViewer"),
    props: { source: "crcfornecedor/lista", titulo: "CADASTRAR CRC" },
  },
  {
    path: "/crcfornecedor/novo",
    component: loadC("modulos/sigcad/cadastro/CrcFornecedor"),
    props: {
      source: "crcfornecedor/novo",
      titulo: "CADASTRAR CRC",
      modo: "novo",
    },
  },
  {
    path: "/crcfornecedor/:crcf_codigo/:crcf_ug/:crcf_exe/editar",
    component: loadC("modulos/sigcad/cadastro/CrcFornecedor"),
    props: {
      source: "crcfornecedor/editar",
      titulo: "CADASTRAR CRC",
      modo: "editar",
    },
  },
  {
    path: "/crcfornecedor/:crcf_codigo/:crcf_ug/:crcf_exe/deletar",
    component: loadC("modulos/sigcad/cadastro/CrcFornecedor"),
    props: {
      source: "crcfornecedor/deletar",
      titulo: "CADASTRAR CRC",
      modo: "deletar",
    },
  },
  {
    path: "/crcfornecedor/:crcf_codigo/:crcf_ug/:crcf_exe/visualizar",
    component: loadC("modulos/sigcad/cadastro/CrcFornecedor"),
    props: {
      source: "crcfornecedor/editar",
      titulo: "CADASTRAR CRC",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO CADASTRO/SETOR
  {
    path: "/setores",
    component: load("MctDataViewer"),
    props: { source: "setores/lista", titulo: "SETOR" },
  },
  {
    path: "/setores/novo",
    component: loadC("modulos/sigcad/cadastro/Setores"),
    props: { source: "setores/novo", titulo: "SETOR", modo: "novo" },
  },
  {
    path: "/setores/:str_codigo/:ug_codigo/:exe_codigo/editar",
    component: loadC("modulos/sigcad/cadastro/Setores"),
    props: { source: "setores/editar", titulo: "SETOR", modo: "editar" },
  },
  {
    path: "/setores/:str_codigo/:ug_codigo/:exe_codigo/deletar",
    component: loadC("modulos/sigcad/cadastro/Setores"),
    props: { source: "setores/deletar", titulo: "SETOR", modo: "deletar" },
  },
  {
    path: "/setores/:str_codigo/:ug_codigo/:exe_codigo/visualizar",
    component: loadC("modulos/sigcad/cadastro/Setores"),
    props: { source: "setores/editar", titulo: "SETOR", modo: "visualizar" },
  },

  //FRONT -CADASTRO GRUPO PRODUTOS
  {
    path: "/patprodutomaterial",
    component: load("MctDataViewer"),
    props: {
      source: "patprodutomaterial/lista",
      titulo: "Grupo de Material/ Serviço",
    },
  },
  {
    path: "/patprodutomaterial/novo",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutomaterial/novo",
      titulo: "Grupo de Material/ Serviço",
      modo: "novo",
    },
  },
  {
    path: "/patprodutomaterial/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutomaterial/editar",
      titulo: "Grupo de Material/ Serviço",
      modo: "editar",
    },
  },
  {
    path: "/patprodutomaterial/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutomaterial/deletar",
      titulo: "Grupo de Material/ Serviço",
      modo: "deletar",
    },
  },
  {
    path: "/patprodutomaterial/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutomaterial/editar",
      titulo: "Grupo de Material/ Serviço",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO GRUPO PRODUTOS
  {
    path: "/patprodutogrupo",
    component: load("MctDataViewer"),
    props: {
      source: "patprodutogrupo/lista",
      titulo: "Classe de Material/ Serviço",
    },
  },
  {
    path: "/patprodutogrupo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutogrupo/novo",
      titulo: "Classe de Material/ Serviço",
      modo: "novo",
    },
  },
  {
    path: "/patprodutogrupo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutogrupo/editar",
      titulo: "Classe de Material/ Serviço",
      modo: "editar",
    },
  },
  {
    path: "/patprodutogrupo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutogrupo/deletar",
      titulo: "Classe de Material/ Serviço",
      modo: "deletar",
    },
  },
  {
    path: "/patprodutogrupo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutogrupo/editar",
      titulo: "Classe de Material/ Serviço",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO SUB GRUPO PRODUTOS
  {
    path: "/patprodutosubgrupo",
    component: load("MctDataViewer"),
    props: { source: "patprodutosubgrupo/lista", titulo: "Material/ Serviço" },
  },
  {
    path: "/patprodutosubgrupo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutosubgrupo/novo",
      titulo: "Material/ Serviço",
      modo: "novo",
    },
  },
  {
    path: "/patprodutosubgrupo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutosubgrupo/editar",
      titulo: "Material/ Serviço",
      modo: "editar",
    },
  },
  {
    path: "/patprodutosubgrupo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutosubgrupo/deletar",
      titulo: "Material/ Serviço",
      modo: "deletar",
    },
  },
  {
    path: "/patprodutosubgrupo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "patprodutosubgrupo/editar",
      titulo: "Material/ Serviço",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTROPRODUTO/SERVICOS
  {
    path: "/cadprodutos",
    component: load("MctDataViewer"),
    props: { source: "cadprodutos/lista", titulo: "Item Material/ Serviço" },
  },
  {
    path: "/cadprodutos/novo",
    component: loadC("modulos/sigcad/cadastro/ProdutoServico"),
    props: {
      source: "cadprodutos/novo",
      titulo: "Item Material/ Serviço",
      modo: "novo",
    },
  },
  {
    path: "/cadprodutos/:cprd_codigo/:cprd_ug/editar",
    component: loadC("modulos/sigcad/cadastro/ProdutoServico"),
    props: {
      source: "cadprodutos/editar",
      titulo: "Item Material/ Serviço",
      modo: "editar",
    },
  },
  {
    path: "/cadprodutos/:cprd_codigo/:cprd_ug/deletar",
    component: loadC("modulos/sigcad/cadastro/ProdutoServico"),
    props: {
      source: "cadprodutos/deletar",
      titulo: "Item Material/ Serviço",
      modo: "deletar",
    },
  },
  {
    path: "/cadprodutos/:cprd_codigo/:cprd_ug/visualizar",
    component: loadC("modulos/sigcad/cadastro/ProdutoServico"),
    props: {
      source: "cadprodutos/editar",
      titulo: "Item Material/ Serviço",
      modo: "visualizar",
    },
  },

  //FRONT - COMISSOES
  {
    path: "/legcomissoes",
    component: load("MctDataViewer"),
    props: { source: "legcomissoes/lista", titulo: "COMISSÕES" },
  },
  {
    path: "/legcomissoes/novo",
    component: loadC("modulos/sigcad/cadastro/Comissoes"),
    props: { source: "legcomissoes/novo", titulo: "COMISSÕES", modo: "novo" },
  },
  {
    path: "/legcomissoes/:cmss_codigo/:cmss_ug/:cmss_exe/editar",
    component: loadC("modulos/sigcad/cadastro/Comissoes"),
    props: {
      source: "legcomissoes/editar",
      titulo: "COMISSÕES",
      modo: "editar",
    },
  },
  {
    path: "/legcomissoes/:cmss_codigo/:cmss_ug/:cmss_exe/deletar",
    component: loadC("modulos/sigcad/cadastro/Comissoes"),
    props: {
      source: "legcomissoes/deletar",
      titulo: "COMISSÕES",
      modo: "deletar",
    },
  },
  {
    path: "/legcomissoes/:cmss_codigo/:cmss_ug/:cmss_exe/visualizar",
    component: loadC("modulos/sigcad/cadastro/Comissoes"),
    props: {
      source: "legcomissoes/editar",
      titulo: "COMISSÕES",
      modo: "visualizar",
    },
  },

  //FRONT - CONSELHO MUNICIPAL
  {
    path: "/cadconselho",
    component: load("MctDataViewer"),
    props: { source: "cadconselho/lista", titulo: "CONSELHO MUNICIPAL" },
  },
  {
    path: "/cadconselho/novo",
    component: loadC("modulos/sigcad/cadastro/CadConselho"),
    props: {
      source: "cadconselho/novo",
      titulo: "CONSELHO MUNICIPAL",
      modo: "novo",
    },
  },
  {
    path: "/cadconselho/:cons_codigo/:cons_ug/editar",
    component: loadC("modulos/sigcad/cadastro/CadConselho"),
    props: {
      source: "cadconselho/editar",
      titulo: "CONSELHO MUNICIPAL",
      modo: "editar",
    },
  },
  {
    path: "/cadconselho/:cons_codigo/:cons_ug/deletar",
    component: loadC("modulos/sigcad/cadastro/CadConselho"),
    props: {
      source: "cadconselho/deletar",
      titulo: "CONSELHO MUNICIPAL",
      modo: "deletar",
    },
  },
  {
    path: "/cadconselho/:cons_codigo/:cons_ug/visualizar",
    component: loadC("modulos/sigcad/cadastro/CadConselho"),
    props: {
      source: "cadconselho/editar",
      titulo: "CONSELHO MUNICIPAL",
      modo: "visualizar",
    },
  },

  // ATA DE REUNIÃo
  {
    path: "/ataconselhomunicipal",
    component: load("MctDataViewer"),
    props: {
      source: "cadconselhoata/lista",
      titulo: "ATA DO CONSELHO MUNICIPAL",
    },
  },
  {
    path: "/ataconselhomunicipal/novo",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/novo",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "novo",
    },
  },
  {
    path: "/ataconselhomunicipal/:cons_ata_codigo/:cons_codigo/:cons_ug/editar",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/editar",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "editar",
    },
  },
  {
    path: "/ataconselhomunicipal/:cons_ata_codigo/:cons_codigo/:cons_ug/deletar",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/deletar",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "deletar",
    },
  },
  {
    path: "/ataconselhomunicipal/:cons_ata_codigo/:cons_codigo/:cons_ug/visualizar",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/editar",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "visualizar",
    },
  },

  //FRONT - EXIGENCIAS DE LICITAÇÃO
  {
    path: "/licexigencias",
    component: load("MctDataViewer"),
    props: {
      source: "cadlicexigencias/lista",
      titulo: "EXIGENCIAS DE LICITAÇÃO",
    },
  },
  {
    path: "/licexigencias/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadlicexigencias/novo",
      titulo: "EXIGENCIAS DE LICITAÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/licexigencias/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadlicexigencias/editar",
      titulo: "EXIGENCIAS DE LICITAÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/licexigencias/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadlicexigencias/deletar",
      titulo: "EXIGENCIAS DE LICITAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/licexigencias/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadlicexigencias/editar",
      titulo: "EXIGENCIAS DE LICITAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT - PROFISSAO / CARGOS
  {
    path: "/profissaocargos",
    component: load("MctDataViewer"),
    props: { source: "profissaocargos/lista", titulo: "PROFISSÕES" },
  },
  {
    path: "/profissaocargos/novo",
    component: load("MctFormViewer"),
    props: {
      source: "profissaocargos/novo",
      titulo: "PROFISSÕES",
      modo: "novo",
    },
  },
  {
    path: "/profissaocargos/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "profissaocargos/editar",
      titulo: "PROFISSÕES",
      modo: "editar",
    },
  },
  {
    path: "/profissaocargos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "profissaocargos/deletar",
      titulo: "PROFISSÕES",
      modo: "deletar",
    },
  },
  {
    path: "/profissaocargos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "profissaocargos/editar",
      titulo: "PROFISSÕES",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO/TABELAS-VEICULO COMUNICACAO
  {
    path: "/veiculocomunic",
    component: load("MctDataViewer"),
    props: {
      source: "veiculocomunic/lista",
      titulo: "VEÍCULOS DE COMUNICAÇÃO",
    },
  },
  {
    path: "/veiculocomunic/novo",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/novo",
      titulo: "VEÍCULOS DE COMUNICAÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/veiculocomunic/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/editar",
      titulo: "VEÍCULOS DE COMUNICAÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/veiculocomunic/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/deletar",
      titulo: "VEÍCULOS DE COMUNICAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/veiculocomunic/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "veiculocomunic/editar",
      titulo: "VEÍCULOS DE COMUNICAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT - CARGO LICITAÇÃO
  {
    path: "/cadliccargo",
    component: load("MctDataViewer"),
    props: { source: "cadliccargo/lista", titulo: "Profissões" },
  },
  {
    path: "/cadliccargo/novo",
    component: load("MctFormViewer"),
    props: { source: "cadliccargo/novo", titulo: "Profissões", modo: "novo" },
  },
  {
    path: "/cadliccargo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadliccargo/editar",
      titulo: "Profissões",
      modo: "editar",
    },
  },
  {
    path: "/cadliccargo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadliccargo/deletar",
      titulo: "Profissões",
      modo: "deletar",
    },
  },
  {
    path: "/cadliccargo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadliccargo/editar",
      titulo: "Profissões",
      modo: "visualizar",
    },
  },

  //FRONT - CONFIGURAR LIMITE PARA LICITAÇÃO
  {
    path: "/configlimlic",
    component: loadC("modulos/sigcad/cadastro/ConfigLimLic"),
    props: {
      source: "configlimlic/editar",
      titulo: "CONFIGURAR LIMITE PARA LICITAÇÃO",
      modo: "editar",
    },
  },
  //{ path: '/configlimlic/:exe_codigo/:ug_codigo/editar', component: loadC('modulos/sigcad/cadastro/ConfigLimLic'), props: { source: "configlimlic/editar", titulo: "CONFIGURAR LIMITE PARA LICITAÇÃO", modo: 'editar' } },
  //{ path: '/configlimlic/:exe_codigo/:ug_codigo/deletar', component: loadC('modulos/sigcad/cadastro/ConfigLimLic'), props: { source: "configlimlic/deletar", titulo: "CONFIGURAR LIMITE PARA LICITAÇÃO", modo: 'deletar' } },
  //{ path: '/configlimlic/:exe_codigo/:ug_codigo/visualizar', component: loadC('modulos/sigcad/cadastro/ConfigLimLic'), props: { source: "configlimlic/editar", titulo: "CONFIGURAR LIMITE PARA LICITAÇÃO", modo: 'visualizar' } },
  //{ path: '/configlimlic', component: loadC('modulos/sigcad/cadastro/ConfigLimLicLista'), props: { source: "configlimlic/lista", titulo: "CONFIGURAR LIMITE PARA LICITAÇÃO" } },

  //FRONT - CADASTRO TP MARCADOR
  {
    path: "/pattipomarcador",
    component: load("MctDataViewer"),
    props: { source: "cadtpmarcador/lista", titulo: "Marcador" },
  },
  {
    path: "/pattipomarcador/novo",
    component: load("MctFormViewer"),
    props: { source: "cadtpmarcador/novo", titulo: "Marcador", modo: "novo" },
  },
  {
    path: "/pattipomarcador/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpmarcador/editar",
      titulo: "Marcador",
      modo: "editar",
    },
  },
  {
    path: "/pattipomarcador/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpmarcador/deletar",
      titulo: "Marcador",
      modo: "deletar",
    },
  },
  {
    path: "/pattipomarcador/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpmarcador/editar",
      titulo: "Marcador",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO TP RECURSO
  {
    path: "/pattprecurso",
    component: load("MctDataViewer"),
    props: { source: "pattprecurso/lista", titulo: " Recursos" },
  },
  {
    path: "/pattprecurso/novo",
    component: load("MctFormViewer"),
    props: { source: "pattprecurso/novo", titulo: " Recursos", modo: "novo" },
  },
  {
    path: "/pattprecurso/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "pattprecurso/editar",
      titulo: " Recursos",
      modo: "editar",
    },
  },
  {
    path: "/pattprecurso/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "pattprecurso/deletar",
      titulo: " Recursos",
      modo: "deletar",
    },
  },
  {
    path: "/pattprecurso/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "pattprecurso/editar",
      titulo: " Recursos",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO / LOCAL
  {
    path: "/cadlocal",
    component: load("MctDataViewer"),
    props: { source: "cadlocal/lista", titulo: "Local" },
  },
  {
    path: "/cadlocal/novo",
    component: load("MctFormViewer"),
    props: { source: "cadlocal/novo", titulo: "Local", modo: "novo" },
  },
  {
    path: "/cadlocal/:loca_codigo/:loca_ug/editar",
    component: load("MctFormViewer"),
    props: { source: "cadlocal/editar", titulo: "Local", modo: "editar" },
  },
  {
    path: "/cadlocal/:loca_codigo/:loca_ug/deletar",
    component: load("MctFormViewer"),
    props: { source: "cadlocal/deletar", titulo: "Local", modo: "deletar" },
  },
  {
    path: "/cadlocal/:loca_codigo/:loca_ug/visualizar",
    component: load("MctFormViewer"),
    props: { source: "cadlocal/editar", titulo: "Local", modo: "visualizar" },
  },

  //FRONT -CADASTRO TP GRUPO BENS
  {
    path: "/patgrupobens",
    component: load("MctDataViewer"),
    props: { source: "patgrupobens/lista", titulo: "Grupo de Bens" },
  },
  {
    path: "/patgrupobens/novo",
    component: load("MctFormViewer"),
    props: {
      source: "patgrupobens/novo",
      titulo: "Grupo de Bens",
      modo: "novo",
    },
  },
  {
    path: "/patgrupobens/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "patgrupobens/editar",
      titulo: "Grupo de Bens",
      modo: "editar",
    },
  },
  {
    path: "/patgrupobens/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "patgrupobens/deletar",
      titulo: "Grupo de Bens",
      modo: "deletar",
    },
  },
  {
    path: "/patgrupobens/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "patgrupobens/editar",
      titulo: "Grupo de Bens",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO TP SUB-GRUPO BENS
  {
    path: "/patsubgrupobens",
    component: load("MctDataViewer"),
    props: { source: "patsubgrupobens/lista", titulo: "Sub-Grupo de Bens" },
  },
  {
    path: "/patsubgrupobens/novo",
    component: load("MctFormViewer"),
    props: {
      source: "patsubgrupobens/novo",
      titulo: "Sub-Grupo de Bens",
      modo: "novo",
    },
  },
  {
    path: "/patsubgrupobens/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "patsubgrupobens/editar",
      titulo: "Sub-Grupo de Bens",
      modo: "editar",
    },
  },
  {
    path: "/patsubgrupobens/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "patsubgrupobens/deletar",
      titulo: "Sub-Grupo de Bens",
      modo: "deletar",
    },
  },
  {
    path: "/patsubgrupobens/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "patsubgrupobens/editar",
      titulo: "Sub-Grupo de Bens",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO TP TIPO BENS
  {
    path: "/pattpbens",
    component: load("MctDataViewer"),
    props: { source: "pattpbens/lista", titulo: "Tipo de Bens" },
  },
  {
    path: "/pattpbens/novo",
    component: load("MctFormViewer"),
    props: { source: "pattpbens/novo", titulo: "Tipo de Bens", modo: "novo" },
  },
  {
    path: "/pattpbens/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "pattpbens/editar",
      titulo: "Tipo de Bens",
      modo: "editar",
    },
  },
  {
    path: "/pattpbens/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "pattpbens/deletar",
      titulo: "Tipo de Bens",
      modo: "deletar",
    },
  },
  {
    path: "/pattpbens/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "pattpbens/editar",
      titulo: "Tipo de Bens",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/ CONTRATOS
  {
    path: "/cadcontratos",
    component: loadC("modulos/sigcad/administrativo/CadContratosLista"),
    props: { source: "cadcontratos/lista", titulo: "CONTRATOS" },
  },
  {
    path: "/cadcontratos/novo",
    component: loadC("modulos/sigcad/administrativo/CadContratos"),
    props: { source: "cadcontratos/novo", titulo: "CONTRATOS", modo: "novo" },
  },
  {
    path: "/cadcontratos/:con_codigo/:con_ug/:con_exe/editar",
    component: loadC("modulos/sigcad/administrativo/CadContratos"),
    props: {
      source: "cadcontratos/editar",
      titulo: "CONTRATOS",
      modo: "editar",
    },
  },
  {
    path: "/cadcontratos/:con_codigo/:con_ug/:con_exe/deletar",
    component: loadC("modulos/sigcad/administrativo/CadContratos"),
    props: {
      source: "cadcontratos/deletar",
      titulo: "CONTRATOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadcontratos/:con_codigo/:con_ug/:con_exe/visualizar",
    component: loadC("modulos/sigcad/administrativo/CadContratos"),
    props: {
      source: "cadcontratos/editar",
      titulo: "CONTRATOS",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/ ADITIVO CONTRATOS
  {
    path: "/cadcontratosaditivos",
    component: loadC("modulos/sigcad/administrativo/CadContratosAditivosLista"),
    props: { source: "cadcontratos/lista", titulo: "ADITIVO DE CONTRATOS" },
  },
  {
    path: "/cadcontratosaditivos/novo",
    component: loadC("modulos/sigcad/administrativo/CadContratosAditivos"),
    props: {
      source: "cadcontratos/novo",
      titulo: "ADITIVO DE CONTRATOS",
      modo: "novo",
    },
  },
  {
    path: "/cadcontratosaditivos/:con_codigo/:con_ug/:con_exe/editar",
    component: loadC("modulos/sigcad/administrativo/CadContratosAditivos"),
    props: {
      source: "cadcontratos/editar",
      titulo: "ADITIVO DE CONTRATOS",
      modo: "editar",
    },
  },
  {
    path: "/cadcontratosaditivos/:con_codigo/:con_ug/:con_exe/deletar",
    component: loadC("modulos/sigcad/administrativo/CadContratosAditivos"),
    props: {
      source: "cadcontratos/deletar",
      titulo: "ADITIVO DE CONTRATOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadcontratosaditivos/:con_codigo/:con_exe/:con_ug/visualizar",
    component: loadC("modulos/sigcad/administrativo/CadContratosAditivos"),
    props: {
      source: "cadcontratos/editar",
      titulo: "ADITIVO DE CONTRATOS",
      modo: "visualizar",
    },
  },

  //FRONT -ADM / CONTRATOS / DISTRATOS
  {
    path: "/cadcontratosdistratos",
    component: loadC(
      "modulos/sigcad/administrativo/CadContratosDistratosLista"
    ),
    props: { source: "cadcontratosdistratos/lista", titulo: "DISTRATOS" },
  },
  {
    path: "/cadcontratosdistratos/novo",
    component: loadC("modulos/sigcad/administrativo/CadContratosDistratos"),
    props: {
      source: "cadcontratosdistratos/novo",
      titulo: "DISTRATOS",
      modo: "novo",
    },
  },
  {
    path: "/cadcontratosdistratos/:con_codigo/:con_ug/:con_exe/:con_dst_codigo/:con_dst_ano/editar",
    component: loadC("modulos/sigcad/administrativo/CadContratosDistratos"),
    props: {
      source: "cadcontratosdistratos/editar",
      titulo: "DISTRATOS",
      modo: "editar",
    },
  },
  {
    path: "/cadcontratosdistratos/:con_codigo/:con_ug/:con_exe/:con_dst_codigo/:con_dst_ano/deletar",
    component: loadC("modulos/sigcad/administrativo/CadContratosDistratos"),
    props: {
      source: "cadcontratosdistratos/deletar",
      titulo: "DISTRATOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadcontratosdistratos/:con_codigo/:con_ug/:con_exe/:con_dst_codigo/:con_dst_ano/visualizar",
    component: loadC("modulos/sigcad/administrativo/CadContratosDistratos"),
    props: {
      source: "cadcontratosdistratos/editar",
      titulo: "DISTRATOS",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/ RECURSO / REPASSE
  {
    path: "/cadreceberrepasse",
    component: load("MctDataViewer"),
    props: { source: "cadreceberrepasse/lista", titulo: "RECURSO / REPASSE" },
  },
  {
    path: "/cadreceberrepasse/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadreceberrepasse/novo",
      titulo: "RECURSO / REPASSE",
      modo: "novo",
    },
  },
  {
    path: "/cadreceberrepasse/:rep_codigo/:rep_ug/:rep_exe/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadreceberrepasse/editar",
      titulo: "RECURSO / REPASSE",
      modo: "editar",
    },
  },
  {
    path: "/cadreceberrepasse/:rep_codigo/:rep_ug/:rep_exe/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadreceberrepasse/deletar",
      titulo: "RECURSO / REPASSE",
      modo: "deletar",
    },
  },
  {
    path: "/cadreceberrepasse/:rep_codigo/:rep_ug/:rep_exe/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadreceberrepasse/editar",
      titulo: "RECURSO / REPASSE",
      modo: "visualizar",
    },
  },

  //FRONT  ADM / DIVISAO
  {
    path: "/cadpessoasdivisao",
    component: load("MctDataViewer"),
    props: {
      source: "cadpessoasdivisao/lista",
      titulo: "DIVISÃO - ORG. REPASSADOR",
    },
  },
  {
    path: "/cadpessoasdivisao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadpessoasdivisao/novo",
      titulo: "DIVISÃO - ORG. REPASSADOR",
      modo: "novo",
    },
  },
  {
    path: "/cadpessoasdivisao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadpessoasdivisao/editar",
      titulo: "DIVISÃO - ORG. REPASSADOR",
      modo: "editar",
    },
  },
  {
    path: "/cadpessoasdivisao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadpessoasdivisao/deletar",
      titulo: "DIVISÃO - ORG. REPASSADOR",
      modo: "deletar",
    },
  },
  {
    path: "/cadpessoasdivisao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadpessoasdivisao/editar",
      titulo: "DIVISÃO - ORG. REPASSADOR",
      modo: "visualizar",
    },
  },

  //FRONT -ALMOXARIFADO / REQUISIÇÃO
  {
    path: "/cadrequisicao",
    component: load("MctDataViewer"),
    props: { source: "cadrequisicao/lista", titulo: "REQUISIÇÃO" },
  },
  {
    path: "/cadrequisicao/novo",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicao"),
    props: { source: "cadrequisicao/novo", titulo: "REQUISIÇÃO", modo: "novo" },
  },
  {
    path: "/cadrequisicao/:req_codigo/:req_ug/:req_exe/editar",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicao"),
    props: {
      source: "cadrequisicao/editar",
      titulo: "REQUISIÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/cadrequisicao/:req_codigo/:req_ug/:req_exe/deletar",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicao"),
    props: {
      source: "cadrequisicao/deletar",
      titulo: "REQUISIÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/cadrequisicao/:req_codigo/:req_ug/:req_exe/visualizar",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicao"),
    props: {
      source: "cadrequisicao/editar",
      titulo: "REQUISIÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT -ALMOXARIFADO / AUTORIZAR REQUISIÇÃO
  {
    path: "/cadrequisicaoitens",
    component: load("MctDataViewerSB"),
    props: {
      source: "cadrequisicao/lista",
      titulo: "AUTORIZAÇÃO DA REQUISIÇÃO",
    },
  },
  {
    path: "/cadrequisicaoitens/novo",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicaoAutorizar"),
    props: {
      source: "cadrequisicao/novo",
      titulo: "AUTORIZAÇÃO DA REQUISIÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/cadrequisicaoitens/:req_codigo/:req_ug/:req_exe/editar",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicaoAutorizar"),
    props: {
      source: "cadrequisicao/editar",
      titulo: "AUTORIZAÇÃO DA REQUISIÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/cadrequisicaoitens/:req_codigo/:req_ug/:req_exe/deletar",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicaoAutorizar"),
    props: {
      source: "cadrequisicao/deletar",
      titulo: "AUTORIZAÇÃO DA REQUISIÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/cadrequisicaoitens/:req_codigo/:req_ug/:req_exe/visualizar",
    component: loadC("modulos/sigcad/almoxarifado/CadRequisicaoAutorizar"),
    props: {
      source: "cadrequisicao/editar",
      titulo: "AUTORIZAÇÃO DA REQUISIÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/ALMOXARIFADO/ENTRADA
  {
    path: "/cadentrada",
    component: load("MctDataViewer"),
    props: {
      source: "cadentrada/lista",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS",
    },
  },
  {
    path: "/cadentrada/novo",
    component: loadC("modulos/sigcad/almoxarifado/CadEntrada"),
    props: {
      source: "cadentrada/novo",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS",
      modo: "novo",
    },
  },
  {
    path: "/cadentrada/:ent_codigo/:ent_ug/:ent_exe/editar",
    component: loadC("modulos/sigcad/almoxarifado/CadEntrada"),
    props: {
      source: "cadentrada/editar",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS",
      modo: "editar",
    },
  },
  {
    path: "/cadentrada/:ent_codigo/:ent_ug/:ent_exe/deletar",
    component: loadC("modulos/sigcad/almoxarifado/CadEntrada"),
    props: {
      source: "cadentrada/deletar",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadentrada/:ent_codigo/:ent_ug/:ent_exe/visualizar",
    component: loadC("modulos/sigcad/almoxarifado/CadEntrada"),
    props: {
      source: "cadentrada/editar",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS",
      modo: "visualizar",
    },
  },

  //FRONT - ALMOXARIFADO / SAÍDA DE PRODUTOS/SERVIÇOS
  {
    path: "/cadsaida",
    component: load("MctDataViewer"),
    props: { source: "cadsaida/lista", titulo: "SAÍDA DE PRODUTOS/SERVIÇOS" },
  },
  {
    path: "/cadsaida/novo",
    component: loadC("modulos/sigcad/almoxarifado/CadSaida"),
    props: {
      source: "cadsaida/novo",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "novo",
    },
  },
  {
    path: "/cadsaida/:sai_codigo/:sai_ug/:sai_exe/editar",
    component: loadC("modulos/sigcad/almoxarifado/CadSaida"),
    props: {
      source: "cadsaida/editar",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "editar",
    },
  },
  {
    path: "/cadsaida/:sai_codigo/:sai_ug/:sai_exe/deletar",
    component: loadC("modulos/sigcad/almoxarifado/CadSaida"),
    props: {
      source: "cadsaida/deletar",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadsaida/:sai_codigo/:sai_ug/:sai_exe/visualizar",
    component: loadC("modulos/sigcad/almoxarifado/CadSaida"),
    props: {
      source: "cadsaida/editar",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "visualizar",
    },
  },

  //FRONT -/ALMOXARIFADO/DEVOLUCAO DE PRODUTOS/SERVIÇOS
  {
    path: "/caddevolucao",
    component: loadC("modulos/sigcad/almoxarifado/CadDevolucaoLista"),
    props: {
      source: "caddevolucao/lista",
      titulo: "DEVOLUÇÃO DE PRODUTOS/SERVIÇOS",
    },
  },
  {
    path: "/caddevolucao/novo",
    component: loadC("modulos/sigcad/almoxarifado/CadDevolucao"),
    props: {
      source: "caddevolucao/novo",
      titulo: "DEVOLUÇÃO DE PRODUTOS/SERVIÇOS",
      modo: "novo",
    },
  },
  {
    path: "/caddevolucao/:dev_codigo/:dev_ug/:dev_exe/editar",
    component: loadC("modulos/sigcad/almoxarifado/CadDevolucao"),
    props: {
      source: "caddevolucao/editar",
      titulo: "DEVOLUÇÃO DE PRODUTOS/SERVIÇOS",
      modo: "editar",
    },
  },
  {
    path: "/caddevolucao/:dev_codigo/:dev_ug/:dev_exe/deletar",
    component: loadC("modulos/sigcad/almoxarifado/CadDevolucao"),
    props: {
      source: "caddevolucao/deletar",
      titulo: "DEVOLUÇÃO DE PRODUTOS/SERVIÇOS",
      modo: "deletar",
    },
  },
  {
    path: "/caddevolucao/:dev_codigo/:dev_ug/:dev_exe/visualizar",
    component: loadC("modulos/sigcad/almoxarifado/CadDevolucao"),
    props: {
      source: "caddevolucao/editar",
      titulo: "DEVOLUÇÃO DE PRODUTOS/SERVIÇOS",
      modo: "visualizar",
    },
  },

  //FRONT -/ALMOXARIFADO/RECONTAGEM PRODUTOS
  {
    path: "/cadrecontagem/novo",
    component: load("modulos/sigcad/almoxarifado/CadRecontagem"),
    props: { source: "cadrecontagem/novo", titulo: "RECONTAGEM", modo: "novo" },
  },
  {
    path: "/cadrecontagem/:rcont_codigo/:rcont_produto_cod/:rcont_produto_ug/editar",
    component: loadC("modulos/sigcad/almoxarifado/CadRecontagem"),
    props: {
      source: "cadrecontagem/editar",
      titulo: "RECONTAGEM",
      modo: "editar",
    },
  },
  {
    path: "/cadrecontagem/:rcont_codigo/:rcont_produto_cod/:rcont_produto_ug/deletar",
    component: loadC("modulos/sigcad/almoxarifado/CadRecontagem"),
    props: {
      source: "cadrecontagem/deletar",
      titulo: "RECONTAGEM",
      modo: "deletar",
    },
  },
  {
    path: "/cadrecontagem/:rcont_codigo/:rcont_produto_cod/:rcont_produto_ug/visualizar",
    component: loadC("modulos/sigcad/almoxarifado/CadRecontagem"),
    props: {
      source: "cadrecontagem/editar",
      titulo: "RECONTAGEM",
      modo: "visualizar",
    },
  },

  //FRONT - ACOMPANHAMENTO - REGULARIDADE
  {
    path: "/acompanhamentoregularidade",
    component: load("MctDataViewer"),
    props: {
      source: "acompanhamentoregularidade/lista",
      titulo: "Acompanhamento/Regularidade de contrato",
    },
  },
  {
    path: "/acompanhamentoregularidade/novo",
    component: loadC("modulos/sigcad/almoxarifado/CadContratoRegularidade"),
    props: {
      source: "acompanhamentoregularidade/novo",
      titulo: "Acompanhamento/Regularidade de contrato",
      modo: "novo",
    },
  },

  //FRONT - COMPRAS / TIPOS MEMORANDO
  {
    path: "/pattipomemorando",
    component: load("MctDataViewer"),
    props: { source: "pattipomemorando/lista", titulo: "TIPO MEMORANDO" },
  },
  {
    path: "/pattipomemorando/novo",
    component: load("MctFormViewer"),
    props: {
      source: "pattipomemorando/novo",
      titulo: "TIPO MEMORANDO",
      modo: "novo",
    },
  },
  {
    path: "/pattipomemorando/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "pattipomemorando/editar",
      titulo: "TIPO MEMORANDO",
      modo: "editar",
    },
  },
  {
    path: "/pattipomemorando/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "pattipomemorando/deletar",
      titulo: "TIPO MEMORANDO",
      modo: "deletar",
    },
  },
  {
    path: "/pattipomemorando/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "pattipomemorando/editar",
      titulo: "TIPO MEMORANDO",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS / MEMORANDO
  {
    path: "/cadmemorando",
    component: loadC("modulos/sigcad/compras/CadMemorandoLista"),
    props: { source: "cadmemorando/lista", titulo: "MEMORANDO" },
  },
  {
    path: "/cadmemorando/novo",
    component: loadC("modulos/sigcad/compras/CadMemorando"),
    props: { source: "cadmemorando/novo", titulo: "MEMORANDO", modo: "novo" },
  },
  {
    path: "/cadmemorando/:mem_codigo/:mem_ug/:mem_exe/editar",
    component: loadC("modulos/sigcad/compras/CadMemorando"),
    props: {
      source: "cadmemorando/editar",
      titulo: "MEMORANDO",
      modo: "editar",
    },
  },
  {
    path: "/cadmemorando/:mem_codigo/:mem_ug/:mem_exe/deletar",
    component: loadC("modulos/sigcad/compras/CadMemorando"),
    props: {
      source: "cadmemorando/deletar",
      titulo: "MEMORANDO",
      modo: "deletar",
    },
  },
  {
    path: "/cadmemorando/:mem_codigo/:mem_ug/:mem_exe/visualizar",
    component: loadC("modulos/sigcad/compras/CadMemorando"),
    props: {
      source: "cadmemorando/editar",
      titulo: "MEMORANDO",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS / AUTORIZA MEMORANDO ITEM
  {
    path: "/cadmemorandoitens",
    component: loadC("modulos/sigcad/compras/CadAutorizaMemorandoLista"),
    props: { source: "cadmemorando/lista", titulo: "AUTORIZAÇÃO MEMORANDO" },
  },
  {
    path: "/cadmemorandoitens/novo",
    component: loadC("modulos/sigcad/compras/CadAutorizaMemorando"),
    props: {
      source: "cadmemorandoitens/novo",
      titulo: "AUTORIZAÇÃO MEMORANDO",
      modo: "novo",
    },
  },
  {
    path: "/cadmemorandoitens/:mem_codigo/:mem_ug/:mem_exe/editar",
    component: loadC("modulos/sigcad/compras/CadAutorizaMemorando"),
    props: {
      source: "cadmemorandoitens/editar",
      titulo: "AUTORIZAÇÃO MEMORANDO",
      modo: "editar",
    },
  },
  {
    path: "/cadmemorandoitens/:mem_codigo/:mem_ug/:mem_exe/deletar",
    component: loadC("modulos/sigcad/compras/CadAutorizaMemorando"),
    props: {
      source: "cadmemorandoitens/deletar",
      titulo: "AUTORIZAÇÃO MEMORANDO",
      modo: "deletar",
    },
  },
  {
    path: "/cadmemorandoitens/:mem_codigo/:mem_ug/:mem_exe/visualizar",
    component: loadC("modulos/sigcad/compras/CadAutorizaMemorando"),
    props: {
      source: "cadmemorandoitens/editar",
      titulo: "AUTORIZAÇÃO MEMORANDO",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS/PROTOCOLAR PROCESSO (proc_codigo, proc_ug, proc_exe
  {
    path: "/cadprotocolarprocesso",
    component: load("MctDataViewer"),
    props: {
      source: "cadprotocolarprocesso/lista",
      titulo: "PROTOCOLAR PROCESSO",
    },
  },
  {
    path: "/cadprotocolarprocesso/novo",
    component: loadC("modulos/sigcad/compras/CadProtocolarProcesso"),
    props: {
      source: "cadprotocolarprocesso/novo",
      titulo: "PROTOCOLAR PROCESSO",
      modo: "novo",
    },
  },
  {
    path: "/cadprotocolarprocesso/:proc_codigo/:proc_ug/:proc_exe/editar",
    component: loadC("modulos/sigcad/compras/CadProtocolarProcesso"),
    props: {
      source: "cadprotocolarprocesso/editar",
      titulo: "PROTOCOLAR PROCESSO",
      modo: "editar",
    },
  },
  {
    path: "/cadprotocolarprocesso/:proc_codigo/:proc_ug/:proc_exe/deletar",
    component: loadC("modulos/sigcad/compras/CadProtocolarProcesso"),
    props: {
      source: "cadprotocolarprocesso/deletar",
      titulo: "PROTOCOLAR PROCESSO",
      modo: "deletar",
    },
  },
  {
    path: "/cadprotocolarprocesso/:proc_codigo/:proc_ug/:proc_exe/visualizar",
    component: loadC("modulos/sigcad/compras/CadProtocolarProcesso"),
    props: {
      source: "cadprotocolarprocesso/editar",
      titulo: "PROTOCOLAR PROCESSO",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS / TERMO REFERENCIA
  {
    path: "/cadtermoreferencia",
    component: load("MctDataViewer"),
    props: {
      source: "cadtermoreferencia/lista",
      titulo: "TERMOS DE REFERÊNCIA",
    },
  },
  {
    path: "/cadtermoreferencia/novo",
    component: loadC("modulos/sigcad/compras/CadTermoReferencia"),
    props: {
      source: "cadtermoreferencia/novo",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "novo",
    },
  },
  {
    path: "/cadtermoreferencia/:trm_codigo/:trm_ug/:trm_exe/editar",
    component: loadC("modulos/sigcad/compras/CadTermoReferencia"),
    props: {
      source: "cadtermoreferencia/editar",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "editar",
    },
  },
  {
    path: "/cadtermoreferencia/:trm_codigo/:trm_ug/:trm_exe/deletar",
    component: loadC("modulos/sigcad/compras/CadTermoReferencia"),
    props: {
      source: "cadtermoreferencia/deletar",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "deletar",
    },
  },
  {
    path: "/cadtermoreferencia/:trm_codigo/:trm_ug/:trm_exe/visualizar",
    component: loadC("modulos/sigcad/compras/CadTermoReferencia"),
    props: {
      source: "cadtermoreferencia/editar",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS / COTAÇÃO
  {
    path: "/cadcotacao",
    component: loadC("modulos/sigcad/compras/CadCotacaoLista"),
    props: { source: "cadcotacao/lista", titulo: "COTAÇÃO" },
  },
  {
    path: "/cadcotacao/novo",
    component: loadC("modulos/sigcad/compras/CadCotacao"),
    props: { source: "cadcotacao/novo", titulo: "COTAÇÃO", modo: "novo" },
  },
  {
    path: "/cadcotacao/:cota_codigo/:cota_ug/:cota_exe/editar",
    component: loadC("modulos/sigcad/compras/CadCotacao"),
    props: { source: "cadcotacao/editar", titulo: "COTAÇÃO", modo: "editar" },
  },
  {
    path: "/cadcotacao/:cota_codigo/:cota_ug/:cota_exe/deletar",
    component: loadC("modulos/sigcad/compras/CadCotacao"),
    props: { source: "cadcotacao/deletar", titulo: "COTAÇÃO", modo: "deletar" },
  },
  {
    path: "/cadcotacao/:cota_codigo/:cota_ug/:cota_exe/visualizar",
    component: loadC("modulos/sigcad/compras/CadCotacao"),
    props: {
      source: "cadcotacao/editar",
      titulo: "COTAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT - REABRIR COTAÇÃO
  {
    path: "/cadreabrircotacao",
    component: loadC("modulos/sigcad/compras/CadCotacaoReabrir"),
    props: {
      source: "cadcotacao/novo",
      titulo: "REABRIR COTAÇÃO",
      modo: "novo",
    },
  },

  //FRONT - COMPRAS/ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS
  {
    path: "/cadordemcompra",
    component: loadC("modulos/sigcad/compras/CadOrdemCompraLista"),
    props: {
      source: "cadordemcompra/lista",
      titulo: "ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS",
    },
  },
  {
    path: "/cadordemcompra/novo",
    component: loadC("modulos/sigcad/compras/CadOrdemCompra"),
    props: {
      source: "cadordemcompra/novo",
      titulo: "ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS",
      modo: "novo",
    },
  },
  {
    path: "/cadordemcompra/:cmpo_codigo/:cmpo_ug/:cmpo_exe/editar",
    component: loadC("modulos/sigcad/compras/CadOrdemCompra"),
    props: {
      source: "cadordemcompra/editar",
      titulo: "ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS",
      modo: "editar",
    },
  },
  {
    path: "/cadordemcompra/:cmpo_codigo/:cmpo_ug/:cmpo_exe/deletar",
    component: loadC("modulos/sigcad/compras/CadOrdemCompra"),
    props: {
      source: "cadordemcompra/deletar",
      titulo: "ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadordemcompra/:cmpo_codigo/:cmpo_ug/:cmpo_exe/visualizar",
    component: loadC("modulos/sigcad/compras/CadOrdemCompra"),
    props: {
      source: "cadordemcompra/editar",
      titulo: "ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS",
      modo: "visualizar",
    },
  },

  //FRONT -FROTAS / VEICULO
  {
    path: "/cadveiculo",
    component: load("MctDataViewer"),
    props: { source: "cadveiculo/lista", titulo: "VEÍCULOS" },
  },
  {
    path: "/cadveiculo/novo",
    component: loadC("modulos/sigcad/frota/CadVeiculo"),
    props: { source: "cadveiculo/novo", titulo: "VEÍCULOS", modo: "novo" },
  },
  {
    path: "/cadveiculo/:vco_codigo/:vco_ug/editar",
    component: loadC("modulos/sigcad/frota/CadVeiculo"),
    props: { source: "cadveiculo/editar", titulo: "VEÍCULOS", modo: "editar" },
  },
  {
    path: "/cadveiculo/:vco_codigo/:vco_ug/deletar",
    component: loadC("modulos/sigcad/frota/CadVeiculo"),
    props: {
      source: "cadveiculo/deletar",
      titulo: "VEÍCULOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadveiculo/:vco_codigo/:vco_ug/visualizar",
    component: loadC("modulos/sigcad/frota/CadVeiculo"),
    props: {
      source: "cadveiculo/editar",
      titulo: "VEÍCULOS",
      modo: "visualizar",
    },
  },

  //FRONT - FROTA /_PNEUS
  {
    path: "/cadfrotapneus",
    component: load("MctDataViewer"),
    props: { source: "cadfrotapneus/lista", titulo: "Pneus" },
  },
  {
    path: "/cadfrotapneus/novo",
    component: loadC("modulos/sigcad/frota/CadFrotaPneu"),
    props: { source: "cadfrotapneus/novo", titulo: "Pneus ", modo: "novo" },
  },
  {
    path: "/cadfrotapneus/:pne_codigo/:pne_ug/editar",
    component: loadC("modulos/sigcad/frota/CadFrotaPneu"),
    props: { source: "cadfrotapneus/editar", titulo: "Pneus ", modo: "editar" },
  },
  {
    path: "/cadfrotapneus/:pne_codigo/:pne_ug/deletar",
    component: loadC("modulos/sigcad/frota/CadFrotaPneu"),
    props: {
      source: "cadfrotapneus/deletar",
      titulo: "Pneus ",
      modo: "deletar",
    },
  },
  {
    path: "/cadfrotapneus/:pne_codigo/:pne_ug/visualizar",
    component: loadC("modulos/sigcad/frota/CadFrotaPneu"),
    props: {
      source: "cadfrotapneus/editar",
      titulo: "Pneus ",
      modo: "visualizar",
    },
  },

  //FRONT -FROTA/ MANUNTENÇÃO /
  //{ path: '/cadftabastecimento', component: load('MctDataViewer'), props: { source: "cadveiculo/lista", titulo: "MANUTENÇÃO DE VEÍCULOS" } },

  {
    path: "/cadftabastecimento",
    component: loadC("modulos/sigcad/frota/CadFrotaManutencaoVeiculo"),
    props: { titulo: "MANUTENÇÃO DE VEÍCULOS", modo: "editar" },
  },
  //{ path: '/cadftabastecimento', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadveiculo/editar", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'editar' } },

  //{ path: '/cadftabastecimento/novo', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadveiculo/novo", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'novo' } },
  //{ path: '/cadftabastecimento/:vco_codigo/:vco_ug/editar', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadveiculo/editar", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'editar' } },
  //{ path: '/cadftabastecimento/:vco_codigo/:vco_ug/deletar', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadveiculo/deletar", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'deletar' } },
  //{ path: '/cadftabastecimento/:vco_codigo/:vco_ug/visualizar', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadveiculo/editar", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'visualizar' } },

  // { path: '/cadftabastecimento', component: load('MctDataViewer'), props: { source: "cadftabastecimento/lista", titulo: "MANUTENÇÃO DE VEÍCULOS" } },
  // { path: '/cadftabastecimento/novo', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadftabastecimento/novo", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'novo' } },
  // { path: '/cadftabastecimento/:abas_codigo/:abas_veiculo_cod/:abas_veiculo_ug/editar', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadftabastecimento/editar", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'editar' } },
  // { path: '/cadftabastecimento/:abas_codigo/:abas_veiculo_cod/:abas_veiculo_ug/deletar', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadftabastecimento/deletar", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'deletar' } },
  // { path: '/cadftabastecimento/:abas_codigo/:abas_veiculo_cod/:abas_veiculo_ug/visualizar', component: loadC('modulos/sigcad/frota/CadFtAbastecimento'), props: { source: "cadftabastecimento/editar", titulo: "MANUTENÇÃO DE VEÍCULOS", modo: 'visualizar' } },

  //FRONT - CADASTRO FROTA - SAIDA DE VEICULO
  {
    path: "/cadftsaidas",
    component: loadC("modulos/sigcad/frota/CadFtSaidaLista"),
    props: { source: "cadftsaidas/lista", titulo: "SAÍDA DE VEÍCULOS" },
  },
  {
    path: "/cadftsaidas/novo",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/novo",
      titulo: "SAÍDA DE VEÍCULOS",
      modo: "novo",
    },
  },
  {
    path: "/cadftsaidas/:ft_sai_codigo/:vco_codigo/:vco_ug/editar",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/editar",
      titulo: "SAÍDA DE VEÍCULOS",
      modo: "editar",
    },
  },
  {
    path: "/cadftsaidas/:ft_sai_codigo/:vco_codigo/:vco_ug/deletar",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/deletar",
      titulo: "SAÍDA DE VEÍCULOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadftsaidas/:ft_sai_codigo/:vco_codigo/:vco_ug/visualizar",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/editar",
      titulo: "SAÍDA DE VEÍCULOS",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO FROTA - RETORNO DE VEICULO
  {
    path: "/ftretorno",
    component: loadC("modulos/sigcad/frota/CadFtSaidaLista"),
    props: { source: "cadftsaidas/lista", titulo: "VEÍCULOS" },
  },
  {
    path: "/ftretorno/novo",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/novo",
      titulo: "RETORNO DE VEÍCULOS",
      modo: "novo",
    },
  },
  {
    path: "/ftretorno/:ft_sai_codigo/:vco_codigo/:vco_ug/editar",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/editar",
      titulo: "RETORNO DE VEÍCULOS",
      modo: "editar",
    },
  },
  {
    path: "/ftretorno/:ft_sai_codigo/:vco_codigo/:vco_ug/deletar",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/deletar",
      titulo: "RETORNO DE VEÍCULOS",
      modo: "deletar",
    },
  },
  {
    path: "/ftretorno/:ft_sai_codigo/:vco_codigo/:vco_ug/visualizar",
    component: loadC("modulos/sigcad/frota/CadFtSaidas"),
    props: {
      source: "cadftsaidas/editar",
      titulo: "RETORNO DE VEÍCULOS",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO FROTA - FICHA SAIDA FROTA
  {
    path: "/cadfichasaidaveic",
    component: loadC("modulos/sigcad/frota/CadFichaSaidaVeic"),
    props: { source: "", titulo: " FICHA SAÍDA FROTA", modo: "novo" },
  },

  //FRONT - FROTA - MARCAS
  {
    path: "/cadveiculomarca",
    component: load("MctDataViewer"),
    props: { source: "cadveiculomarca/lista", titulo: "Marcas" },
  },
  {
    path: "/cadveiculomarca/novo",
    component: load("MctFormViewer"),
    props: { source: "cadveiculomarca/novo", titulo: "Marcas", modo: "novo" },
  },
  {
    path: "/cadveiculomarca/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadveiculomarca/editar",
      titulo: "Marcas",
      modo: "editar",
    },
  },
  {
    path: "/cadveiculomarca/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadveiculomarca/deletar",
      titulo: "Marcas",
      modo: "deletar",
    },
  },
  {
    path: "/cadveiculomarca/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadveiculomarca/editar",
      titulo: "Marcas",
      modo: "visualizar",
    },
  },

  //FRONT - FROTA -  COMBUSTIVEL
  {
    path: "/cadveiculocombustivel",
    component: load("MctDataViewer"),
    props: { source: "cadveiculocombustivel/lista", titulo: "Combustível" },
  },
  {
    path: "/cadveiculocombustivel/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadveiculocombustivel/novo",
      titulo: "Combustível",
      modo: "novo",
    },
  },
  {
    path: "/cadveiculocombustivel/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadveiculocombustivel/editar",
      titulo: "Combustível",
      modo: "editar",
    },
  },
  {
    path: "/cadveiculocombustivel/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadveiculocombustivel/deletar",
      titulo: "Combustível",
      modo: "deletar",
    },
  },
  {
    path: "/cadveiculocombustivel/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadveiculocombustivel/editar",
      titulo: "Combustível",
      modo: "visualizar",
    },
  },

  //FRONT - FROTA - TIPOP MARCADOR
  {
    path: "/cadtpmarcador",
    component: load("MctDataViewer"),
    props: { source: "cadtpmarcador/lista", titulo: "Tipo de Marcador" },
  },
  {
    path: "/cadtpmarcador/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpmarcador/novo",
      titulo: "Tipo de Marcador",
      modo: "novo",
    },
  },
  {
    path: "/cadtpmarcador/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpmarcador/editar",
      titulo: "Tipo de Marcador",
      modo: "editar",
    },
  },
  {
    path: "/cadtpmarcador/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpmarcador/deletar",
      titulo: "Tipo de Marcador",
      modo: "deletar",
    },
  },
  {
    path: "/cadtpmarcador/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpmarcador/editar",
      titulo: "Tipo de Marcador",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO TIPO VIAS -
  {
    path: "/cadtpvias",
    component: load("MctDataViewer"),
    props: { source: "cadtpvias/lista", titulo: "Tipo Vias" },
  },
  {
    path: "/cadtpvias/novo",
    component: load("MctFormViewer"),
    props: { source: "cadtpvias/novo", titulo: "Tipo Vias", modo: "novo" },
  },
  {
    path: "/cadtpvias/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "cadtpvias/editar", titulo: "Tipo Vias", modo: "editar" },
  },
  {
    path: "/cadtpvias/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpvias/deletar",
      titulo: "Tipo Vias",
      modo: "deletar",
    },
  },
  {
    path: "/cadtpvias/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpvias/editar",
      titulo: "Tipo Vias",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO TP VEICULO- CAD_TP_VEICULO
  {
    path: "/cadtpveiculo",
    component: load("MctDataViewer"),
    props: { source: "cadtpveiculo/lista", titulo: "Tipo Veículos" },
  },
  {
    path: "/cadtpveiculo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpveiculo/novo",
      titulo: "Tipo Veículos",
      modo: "novo",
    },
  },
  {
    path: "/cadtpveiculo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpveiculo/editar",
      titulo: "Tipo Veículos",
      modo: "editar",
    },
  },
  {
    path: "/cadtpveiculo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpveiculo/deletar",
      titulo: "Tipo Veículos",
      modo: "deletar",
    },
  },
  {
    path: "/cadtpveiculo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtpveiculo/editar",
      titulo: "Tipo Veículos",
      modo: "visualizar",
    },
  },

  //FRONT - FROTA- PNEUS_MARCA
  {
    path: "/cadfrotapneusmarcas",
    component: load("MctDataViewer"),
    props: { source: "cadfrotapneusmarcas/lista", titulo: "Pneus Marcas" },
  },
  {
    path: "/cadfrotapneusmarcas/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadfrotapneusmarcas/novo",
      titulo: "Pneus Marcas",
      modo: "novo",
    },
  },
  {
    path: "/cadfrotapneusmarcas/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadfrotapneusmarcas/editar",
      titulo: "Pneus Marcas",
      modo: "editar",
    },
  },
  {
    path: "/cadfrotapneusmarcas/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadfrotapneusmarcas/deletar",
      titulo: "Pneus Marcas",
      modo: "deletar",
    },
  },
  {
    path: "/cadfrotapneusmarcas/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadfrotapneusmarcas/editar",
      titulo: "Pneus Marcas",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇÃO - ALIENACAO
  {
    path: "/licalienacao",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/1/lista", titulo: "ALIENAÇÃO" },
  },
  {
    path: "/licalienacao/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/novo", titulo: "ALIENAÇÃO", modo: "novo" },
  },
  {
    path: "/licalienacao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/editar", titulo: "ALIENAÇÃO", modo: "editar" },
  },
  {
    path: "/licalienacao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "ALIENAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/licalienacao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "ALIENAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇÃO - CARTA CONVITE
  {
    path: "/liccartaconvite",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/2/lista", titulo: "CARTA CONVITE" },
  },
  {
    path: "/liccartaconvite/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/novo", titulo: "CARTA CONVITE", modo: "novo" },
  },
  {
    path: "/liccartaconvite/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "CARTA CONVITE",
      modo: "editar",
    },
  },
  {
    path: "/liccartaconvite/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "CARTA CONVITE",
      modo: "deletar",
    },
  },
  {
    path: "/liccartaconvite/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "CARTA CONVITE",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇÃO - CONCORRÊNCIA
  {
    path: "/licconcorrencia",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/3/lista", titulo: "CONCORRÊNCIA" },
  },
  {
    path: "/licconcorrencia/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/novo", titulo: "CONCORRÊNCIA", modo: "novo" },
  },
  {
    path: "/licconcorrencia/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "CONCORRÊNCIA",
      modo: "editar",
    },
  },
  {
    path: "/licconcorrencia/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "CONCORRÊNCIA",
      modo: "deletar",
    },
  },
  {
    path: "/licconcorrencia/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "CONCORRÊNCIA",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇÃO - CONCURSO
  {
    path: "/licconcurso",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/4/lista", titulo: "CONCURSO" },
  },
  {
    path: "/licconcurso/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/novo", titulo: "CONCURSO", modo: "novo" },
  },
  {
    path: "/licconcurso/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/editar", titulo: "CONCURSO", modo: "editar" },
  },
  {
    path: "/licconcurso/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/deletar", titulo: "CONCURSO", modo: "deletar" },
  },
  {
    path: "/licconcurso/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "CONCURSO",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇÃO - DISPENSA
  {
    path: "/licdispensa",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/5/lista", titulo: "DISPENSA" },
  },
  {
    path: "/licdispensa/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/novo", titulo: "DISPENSA", modo: "novo" },
  },
  {
    path: "/licdispensa/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/editar", titulo: "DISPENSA", modo: "editar" },
  },
  {
    path: "/licdispensa/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/deletar", titulo: "DISPENSA", modo: "deletar" },
  },
  {
    path: "/licdispensa/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "DISPENSA",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇÃO - INEXIGIBILIDADE
  {
    path: "/licinexigibilidade",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/6/lista", titulo: "INEXIGIBILIDADE" },
  },
  {
    path: "/licinexigibilidade/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/novo",
      titulo: "INEXIGIBILIDADE",
      modo: "novo",
    },
  },
  {
    path: "/licinexigibilidade/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "INEXIGIBILIDADE",
      modo: "editar",
    },
  },
  {
    path: "/licinexigibilidade/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "INEXIGIBILIDADE",
      modo: "deletar",
    },
  },
  {
    path: "/licinexigibilidade/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "INEXIGIBILIDADE  ",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇÃO - LEILÃO
  {
    path: "/licleilao",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/7/lista", titulo: "LEILÃO" },
  },
  {
    path: "/licleilao/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/novo", titulo: "LEILÃO", modo: "novo" },
  },
  {
    path: "/licleilao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/editar", titulo: "LEILÃO", modo: "editar" },
  },
  {
    path: "/licleilao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/deletar", titulo: "LEILÃO", modo: "deletar" },
  },
  {
    path: "/licleilao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/editar", titulo: "LEILÃO", modo: "visualizar" },
  },

  //FRONT -CADASTRO LICITAÇÃO - TOMADA DE PREÇO
  {
    path: "/lictomadapreco",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/9/lista", titulo: "TOMADA DE PREÇO" },
  },
  {
    path: "/lictomadapreco/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/novo",
      titulo: "TOMADA DE PREÇO",
      modo: "novo",
    },
  },
  {
    path: "/lictomadapreco/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "TOMADA DE PREÇO",
      modo: "editar",
    },
  },
  {
    path: "/lictomadapreco/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "TOMADA DE PREÇO",
      modo: "deletar",
    },
  },
  {
    path: "/lictomadapreco/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: {
      source: "licitacao/editar",
      titulo: "TOMADA DE PREÇO",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇAO - PRORROGAR
  {
    path: "/licprorrogacao",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/lista", titulo: "Prorrogar Licitação" },
  },
  {
    path: "/licprorrogacao/novo",
    component: loadC("modulos/sigcad/licitacao/LicProrrogarLicitacao"),
    props: {
      source: "licprorrogacao/novo",
      titulo: "Prorrogar Licitação",
      modo: "novo",
    },
  },
  {
    path: "/licprorrogacao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicProrrogarLicitacao"),
    props: {
      source: "licprorrogacao/editar",
      titulo: "Prorrogar Licitação",
      modo: "editar",
    },
  },
  {
    path: "/licprorrogacao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicProrrogarLicitacao"),
    props: {
      source: "licprorrogacao/deletar",
      titulo: "Prorrogar Licitação",
      modo: "deletar",
    },
  },
  {
    path: "/licprorrogacao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicProrrogarLicitacao"),
    props: {
      source: "licprorrogacao/editar",
      titulo: "Prorrogar Licitação",
      modo: "visualizar",
    },
  },

  //FRONT -CADASTRO LICITAÇAO - SUSPENSAO
  {
    path: "/licsuspensao",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/lista", titulo: "Suspender Licitação" },
  },
  {
    path: "/licsuspensao/novo",
    component: loadC("modulos/sigcad/licitacao/LicSuspenderLicitacao"),
    props: {
      source: "licsuspensao/novo",
      titulo: "Suspender Licitação",
      modo: "novo",
    },
  },
  {
    path: "/licsuspensao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicSuspenderLicitacao"),
    props: {
      source: "licsuspensao/editar",
      titulo: "Suspender Licitação",
      modo: "editar",
    },
  },
  {
    path: "/licsuspensao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicSuspenderLicitacao"),
    props: {
      source: "licsuspensao/deletar",
      titulo: "Suspender Licitação",
      modo: "deletar",
    },
  },
  {
    path: "/licsuspensao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicSuspenderLicitacao"),
    props: {
      source: "licsuspensao/editar",
      titulo: "Suspender Licitação",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO LICITAÇAO - CANCELAMENTO
  {
    path: "/liccancelamento",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "licitacao/lista", titulo: "Cancelar Licitação" },
  },
  {
    path: "/liccancelamento/novo",
    component: loadC("modulos/sigcad/licitacao/LicCancelamento"),
    props: {
      source: "licitacao/novo",
      titulo: "Cancelar Licitação",
      modo: "novo",
    },
  },
  {
    path: "/liccancelamento/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicCancelamento"),
    props: {
      source: "licitacao/editar",
      titulo: "Cancelar Licitação",
      modo: "editar",
    },
  },
  {
    path: "/liccancelamento/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicCancelamento"),
    props: {
      source: "licitacao/deletar",
      titulo: "Cancelar Licitação",
      modo: "deletar",
    },
  },
  {
    path: "/liccancelamento/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicCancelamento"),
    props: {
      source: "licitacai/editar",
      titulo: "Cancelar Licitação",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO LICITAÇAO - ATA DE PRORROGACAO
  {
    path: "/licataprorrogacao",
    component: load("MctDataViewer"),
    props: { source: "licitacao/lista", titulo: "Suspender Licitação" },
  },
  {
    path: "/licataprorrogacao/novo",
    component: loadC("modulos/sigcad/licitacao/LicAtaProrrogacao"),
    props: {
      source: "licitacao/novo",
      titulo: "Ata de prorrogação",
      modo: "novo",
    },
  },
  {
    path: "/licataprorrogacao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicAtaProrrogacao"),
    props: {
      source: "licitacao/editar",
      titulo: "Ata de prorrogação",
      modo: "editar",
    },
  },
  {
    path: "/licataprorrogacao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicAtaProrrogacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "Ata de prorrogação",
      modo: "deletar",
    },
  },
  {
    path: "/licataprorrogacao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicAtaProrrogacao"),
    props: {
      source: "licitacao/editar",
      titulo: "Ata de prorrogação",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO LICITACAO - MANUTENCAO DE EXIGENCIAS
  {
    path: "/licmanutencaoexigencias",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: {
      source: "licmanutencaoexigencias/lista",
      titulo: "Manutenção de Exigências",
    },
  },
  {
    path: "/licmanutencaoexigencias/novo",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/novo",
      titulo: "Manutenção de Exigências",
      modo: "novo",
    },
  },
  {
    path: "/licmanutencaoexigencias/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/editar",
      titulo: "Manutenção de Exigências ",
      modo: "editar",
    },
  },
  {
    path: "/licmanutencaoexigencias/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/deletar",
      titulo: "Manutenção de Exigências ",
      modo: "deletar",
    },
  },
  {
    path: "/licmanutencaoexigencias/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/editar",
      titulo: "Manutenção de Exigências ",
      modo: "visualizar",
    },
  },

  {
    path: "/liclancarproposta",
    component: loadC("modulos/sigcad/licitacao/LicitacaoLista"),
    props: { source: "liclancarproposta/lista", titulo: "Lançar proposta" },
  },
  {
    path: "/liclancarproposta/novo",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/novo",
      titulo: "Lançar proposta",
      modo: "novo",
    },
  },
  {
    path: "/liclancarproposta/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/editar",
      titulo: "Lançar proposta",
      modo: "editar",
    },
  },
  {
    path: "/liclancarproposta/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/deletar",
      titulo: "Lançar proposta",
      modo: "deletar",
    },
  },
  {
    path: "/liclancarproposta/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/editar",
      titulo: "Lançar proposta",
      modo: "visualizar",
    },
  },

  //FRONT - APURACAO LICITAÇAO - APURACAO
  {
    path: "/licapuracao",
    component: loadC("modulos/sigcad/licitacao/LicApuracaoLista"),
    props: {
      source: "licapuracao/lista",
      titulo: "Apurar vencendor / Mapa de apuração",
    },
  },
  {
    path: "/licapuracao/novo",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/novo",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "novo",
    },
  },
  {
    path: "/licapuracao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/editar",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "editar",
    },
  },
  {
    path: "/licapuracao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/deletar",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "deletar",
    },
  },
  {
    path: "/licapuracao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/editar",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "visualizar",
    },
  },

  //FRONT - ATA JULGAMENTO LICITAÇAO - LICITACAO
  {
    path: "/licatajulgamento",
    component: load("MctDataViewer"),
    props: { source: "licatajulgamento/lista", titulo: "Ata de Julgamento" },
  },
  {
    path: "/licatajulgamento/novo",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/novo",
      titulo: "Ata de Julgamento",
      modo: "novo",
    },
  },
  {
    path: "/licatajulgamento/:codigo/editar",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/editar",
      titulo: "Ata de Julgamento",
      modo: "editar",
    },
  },
  {
    path: "/licatajulgamento/:codigo/deletar",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/deletar",
      titulo: "Ata de Julgamento",
      modo: "deletar",
    },
  },
  {
    path: "/licatajulgamento/:codigo/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/editar",
      titulo: "Ata de Julgamento",
      modo: "visualizar",
    },
  },

  //FRONT - PARECER DA COMISSÃO - LICITACAO
  {
    path: "/licparecercomissao/",
    component: load("MctDataViewerSB"),
    props: {
      source: "licitacao/lista",
      titulo: "Licitação - Parecer da Comissão",
    },
  },
  {
    path: "/licparecercomissao/novo",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/novo",
      titulo: "TLicitação - Parecer da Comissão",
      modo: "novo",
    },
  },
  {
    path: "/licparecercomissao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer da Comissão",
      modo: "editar",
    },
  },
  {
    path: "/licparecercomissao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/deletar",
      titulo: "Licitação - Parecer da Comissão",
      modo: "deletar",
    },
  },
  {
    path: "/licparecercomissao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer da Comissão",
      modo: "visualizar",
    },
  },

  //FRONT - PARECER JURDICO - LICITACAO
  {
    path: "/licparecerjuridico",
    component: load("MctDataViewerSB"),
    props: {
      source: "licitacao/lista",
      titulo: "Licitação - Parecer Jurídico",
    },
  },
  {
    path: "/licparecerjuridico/novo",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/novo",
      titulo: "TLicitação - Parecer Jurídico",
      modo: "novo",
    },
  },
  {
    path: "/licparecerjuridico/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer Jurídico",
      modo: "editar",
    },
  },
  {
    path: "/licparecerjuridico/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/deletar",
      titulo: "Licitação - Parecer Jurídico",
      modo: "deletar",
    },
  },
  {
    path: "/licparecerjuridico/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer Jurídico",
      modo: "visualizar",
    },
  },

  //FRONT - HOMOLOGAR ADJUDICAR - LICITACAO
  {
    path: "/licfinalizarlicitacao",
    component: load("MctDataViewerSB"),
    props: {
      source: "licitacao/lista",
      titulo: "Homolgar e Adjudicar a licitação",
    },
  },
  {
    path: "/licfinalizarlicitacao/novo",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/novo",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "novo",
    },
  },
  {
    path: "/licfinalizarlicitacao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/editar",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "editar",
    },
  },
  {
    path: "/licfinalizarlicitacao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/deletar",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "deletar",
    },
  },
  {
    path: "/licfinalizarlicitacao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/editar",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "visualizar",
    },
  },

  //FRONT - AVISO DE PUBLICAÇÃO - LICITACAO
  {
    path: "/licavisopublicacao",
    component: load("MctDataViewerSB"),
    props: { source: "licitacao/lista", titulo: "Aviso de publicação" },
  },
  {
    path: "/licavisopublicacao/novo",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/novo",
      titulo: "Aviso de publicação",
      modo: "novo",
    },
  },
  {
    path: "/licavisopublicacao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/editar",
      titulo: "Aviso de publicação",
      modo: "editar",
    },
  },
  {
    path: "/licavisopublicacao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "Aviso de publicação",
      modo: "deletar",
    },
  },
  {
    path: "/licavisopublicacao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/editar",
      titulo: "Aviso de publicação",
      modo: "visualizar",
    },
  },

  //FRONT - CONVOCAÇAÕ DESISTENCIA - LICITACAO
  {
    path: "/licconvocacaodesistencia",
    component: load("MctDataViewerSB"),
    props: { source: "licitacao/lista", titulo: "Convocação/Desistência" },
  },
  {
    path: "/licconvocacaodesistencia/novo",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/novo",
      titulo: "Convocação/Desistência",
      modo: "novo",
    },
  },
  {
    path: "/licconvocacaodesistencia/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/editar",
      titulo: "Convocação/Desistência",
      modo: "editar",
    },
  },
  {
    path: "/licconvocacaodesistencia/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/deletar",
      titulo: "Convocação/Desistência",
      modo: "deletar",
    },
  },
  {
    path: "/licconvocacaodesistencia/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicAvisoPublicacao"),
    props: {
      source: "licitacao/editar",
      titulo: "Convocação/Desistência",
      modo: "visualizar",
    },
  },

  //FRONT - LICITACAO - PREGÃO
  {
    path: "/licpregao",
    component: loadC("modulos/sigcad/licitacao/LicPregaoLista"),
    props: { source: "licitacao/lista", titulo: "Pregão" },
  },
  {
    path: "/licpregao/novo",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/novo", titulo: "Pregão", modo: "novo" },
  },
  {
    path: "/licpregao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/editar", titulo: "Pregão", modo: "editar" },
  },
  {
    path: "/licpregao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/deletar", titulo: "Pregão", modo: "deletar" },
  },
  {
    path: "/licpregao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/Licitacao"),
    props: { source: "licitacao/editar", titulo: "Pregão", modo: "visualizar" },
  },

  //FRONT - LICITACAO - PREGÃO - LANCAR PROPOSTA
  {
    path: "/liclancarpropostapregaocad",
    component: loadC("modulos/sigcad/licitacao/LicPregaoListaSB"),
    props: { source: "liclancarproposta/lista", titulo: "Lançar proposta" },
  },
  {
    path: "/liclancarpropostapregaocad/novo",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/novo",
      titulo: "Lançar proposta",
      modo: "novo",
    },
  },
  {
    path: "/liclancarpropostapregaocad/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/editar",
      titulo: "Lançar proposta",
      modo: "editar",
    },
  },
  {
    path: "/liclancarpropostapregaocad/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/deletar",
      titulo: "Lançar proposta",
      modo: "deletar",
    },
  },
  {
    path: "/liclancarpropostapregaocad/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicLancarProposta"),
    props: {
      source: "liclancarproposta/editar",
      titulo: "Lançar proposta",
      modo: "visualizar",
    },
  },

  //FRONT - PREGÃO - LANCES PRESENCIAISman
  {
    path: "/liclancarpresencial",
    component: loadC("modulos/sigcad/licitacao/LicPregaoListaSB"),
    props: { source: "liclancarpresencial/lista", titulo: "Lances Presencial" },
  },
  {
    path: "/liclancarpresencial/novo",
    component: loadC("modulos/sigcad/licitacao/LicLancesPresenciais"),
    props: {
      source: "liclancarpresencial/novo",
      titulo: "Lances Presencial",
      modo: "novo",
    },
  },
  {
    path: "/liclancarpresencial/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicLancesPresenciais"),
    props: {
      source: "liclancarpresencial/editar",
      titulo: "Lances Presencial",
      modo: "editar",
    },
  },
  {
    path: "/liclancarpresencial/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicLancesPresenciais"),
    props: {
      source: "liclancarpresencial/editar",
      titulo: "Lances Presencial",
      modo: "visualizar",
    },
  },
  {
    path: "/liclancarpresencial/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicLancesPresenciais"),
    props: {
      source: "liclancarpresencial/deletar",
      titulo: "Lances Presencial",
      modo: "deletar",
    },
  },
  {
    path: "/liclancarpresencial/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicLancesPresenciais"),
    props: {
      source: "liclancarpresencial/editar",
      titulo: "Lances Presencial",
      modo: "visualizar",
    },
  },

  //FRONT - APURACAO LICITAÇAO / PREGAO / APURACAO
  {
    path: "/licapuracaopregaocad",
    component: loadC("modulos/sigcad/licitacao/LicPregaoListaSB"),
    props: {
      source: "licapuracao/lista",
      titulo: "Apurar vencendor / Mapa de apuração",
    },
  },
  {
    path: "/licapuracaopregaocad/novo",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/novo",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "novo",
    },
  },
  {
    path: "/licapuracaopregaocad/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/editar",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "editar",
    },
  },
  {
    path: "/licapuracaopregaocad/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/deletar",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "deletar",
    },
  },
  {
    path: "/licapuracaopregaocad/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicApuracao"),
    props: {
      source: "licapuracao/editar",
      titulo: "Apurar vencendor / Mapa de apuração",
      modo: "visualizar",
    },
  },

  // licexigenciapregaocad
  {
    path: "/licexigenciapregaocad",
    component: loadC("modulos/sigcad/licitacao/LicPregaoListaSB"),
    props: {
      source: "licmanutencaoexigencias/lista",
      titulo: "Manutenção de Exigências",
    },
  },
  {
    path: "/licexigenciapregaocad/novo",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/novo",
      titulo: "Manutenção de Exigências",
      modo: "novo",
    },
  },
  {
    path: "/licexigenciapregaocad/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/editar",
      titulo: "Manutenção de Exigências ",
      modo: "editar",
    },
  },
  {
    path: "/licexigenciapregaocad/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/deletar",
      titulo: "Manutenção de Exigências ",
      modo: "deletar",
    },
  },
  {
    path: "/licexigenciapregaocad/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicManutencaoExigencias"),
    props: {
      source: "licmanutencaoexigencias/editar",
      titulo: "Manutenção de Exigências ",
      modo: "visualizar",
    },
  },

  //FRONT - LICITACAO - PREGAO - ATA JULGAMENTO LICITAÇAO -
  {
    path: "/licatajulgamentopregao",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamentoPregaoLista"),
    props: { source: "licatajulgamento/lista", titulo: "Ata de Julgamento" },
  },
  {
    path: "/licatajulgamentopregao/novo",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/novo",
      titulo: "Ata de Julgamento",
      modo: "novo",
    },
  },
  {
    path: "/licatajulgamentopregao/:lic_txt_modelo/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/editar",
      titulo: "Ata de Julgamento",
      modo: "editar",
    },
  },
  {
    path: "/licatajulgamentopregao/:lic_txt_modelo/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/deletar",
      titulo: "Ata de Julgamento",
      modo: "deletar",
    },
  },
  {
    path: "/licatajulgamentopregao/:lic_txt_modelo/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicAtaJulgamento"),
    props: {
      source: "licatajulgamento/editar",
      titulo: "Ata de Julgamento",
      modo: "visualizar",
    },
  },

  //FRONT - PARECER DA COMISSÃO - PREGAO - LICITACAO
  {
    path: "/licparecercomissaopregao/",
    component: loadC("modulos/sigcad/licitacao/LicPregaoListaSB"),
    props: {
      source: "licitacao/lista",
      titulo: "Licitação - Parecer da Comissão",
    },
  },
  {
    path: "/licparecercomissaopregao/novo",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/novo",
      titulo: "TLicitação - Parecer da Comissão",
      modo: "novo",
    },
  },
  {
    path: "/licparecercomissaopregao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer da Comissão",
      modo: "editar",
    },
  },
  {
    path: "/licparecercomissaopregao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/deletar",
      titulo: "Licitação - Parecer da Comissão",
      modo: "deletar",
    },
  },
  {
    path: "/licparecercomissaopregao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicParecerComissao"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer da Comissão",
      modo: "visualizar",
    },
  },

  //FRONT - LICITACAO - PREGAO - PARECER JURDICO
  {
    path: "/licparecerjuridicopregao",
    component: loadC("modulos/sigcad/licitacao/LicPregaoListaSB"),
    props: {
      source: "licitacao/lista",
      titulo: "Licitação - Parecer Jurídico",
    },
  },
  {
    path: "/licparecerjuridicopregao/novo",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/novo",
      titulo: "TLicitação - Parecer Jurídico",
      modo: "novo",
    },
  },
  {
    path: "/licparecerjuridicopregao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer Jurídico",
      modo: "editar",
    },
  },
  {
    path: "/licparecerjuridicopregao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/deletar",
      titulo: "Licitação - Parecer Jurídico",
      modo: "deletar",
    },
  },
  {
    path: "/licparecerjuridicopregao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicParecerJuridico"),
    props: {
      source: "licitacao/editar",
      titulo: "Licitação - Parecer Jurídico",
      modo: "visualizar",
    },
  },

  //FRONT - LICITACAO - PREGAO - HOMOLOGAR ADJUDICAR
  {
    path: "/licfinalizalicitacaopregao",
    component: loadC("modulos/sigcad/licitacao/LicPregaoListaSB"),
    props: {
      source: "licitacao/lista",
      titulo: "Homolgar e Adjudicar a licitação",
    },
  },
  {
    path: "/licfinalizalicitacaopregao/novo",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/novo",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "novo",
    },
  },
  {
    path: "/licfinalizalicitacaopregao/:lic_codigo/:lic_ug/:lic_exe/editar",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/editar",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "editar",
    },
  },
  {
    path: "/licfinalizalicitacaopregao/:lic_codigo/:lic_ug/:lic_exe/deletar",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/deletar",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "deletar",
    },
  },
  {
    path: "/licfinalizalicitacaopregao/:lic_codigo/:lic_ug/:lic_exe/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicHomologarAdjudicar"),
    props: {
      source: "licitacao/editar",
      titulo: "Homolgar e Adjudicar a licitação",
      modo: "visualizar",
    },
  },

  //FRONT - MODELOS TEXTOS - LICITACAO
  {
    path: "/licmodelostexto",
    component: load("MctDataViewer"),
    props: { source: "licmodelostexto/lista", titulo: "Modelos de Texto" },
  },
  {
    path: "/licmodelostexto/novo",
    component: loadC("modulos/sigcad/licitacao/LicModelosTexto"),
    props: {
      source: "licmodelostexto/novo",
      titulo: "Modelos de Texto",
      modo: "novo",
    },
  },
  {
    path: "/licmodelostexto/:codigo/editar",
    component: loadC("modulos/sigcad/licitacao/LicModelosTexto"),
    props: {
      source: "licmodelostexto/editar",
      titulo: "Modelos de Texto",
      modo: "editar",
    },
  },
  {
    path: "/licmodelostexto/:codigo/deletar",
    component: loadC("modulos/sigcad/licitacao/LicModelosTexto"),
    props: {
      source: "licmodelostexto/deletar",
      titulo: "Modelos de Texto",
      modo: "deletar",
    },
  },
  {
    path: "/licmodelostexto/:codigo/visualizar",
    component: loadC("modulos/sigcad/licitacao/LicModelosTexto"),
    props: {
      source: "licmodelostexto/editar",
      titulo: "Modelos de Texto",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO CARGOS LICITAÇAO - PROFISSAO
  {
    path: "/profissao",
    component: load("MctDataViewer"),
    props: { source: "profissao/lista", titulo: "Cargos Licitação" },
  },
  {
    path: "/profissao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "profissao/novo",
      titulo: "Cargos Licitação",
      modo: "novo",
    },
  },
  {
    path: "/profissao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "profissao/editar",
      titulo: "Cargos Licitação",
      modo: "editar",
    },
  },
  {
    path: "/profissao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "profissao/deletar",
      titulo: "Cargos Licitação",
      modo: "deletar",
    },
  },
  {
    path: "/profissao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "profissao/editar",
      titulo: "Cargos Licitação",
      modo: "visualizar",
    },
  },

  //FRONT - PATRIMONIO / BENS PATRIMONIAIS
  {
    path: "/cadpatbens",
    component: load("MctDataViewer"),
    props: { source: "cadpatbens/lista", titulo: "BENS PATRIMONIAIS" },
  },
  {
    path: "/cadpatbens/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatBens"),
    props: {
      source: "cadpatbens/novo",
      titulo: "BENS PATRIMONIAIS",
      modo: "novo",
    },
  },
  {
    path: "/cadpatbens/:pat_codigo/:pat_ug/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBens"),
    props: {
      source: "cadpatbens/editar",
      titulo: "BENS PATRIMONIAIS",
      modo: "editar",
    },
  },
  {
    path: "/cadpatbens/:pat_codigo/:pat_ug/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBens"),
    props: {
      source: "cadpatbens/deletar",
      titulo: "BENS PATRIMONIAIS",
      modo: "deletar",
    },
  },
  {
    path: "/cadpatbens/:pat_codigo/:pat_ug/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBens"),
    props: {
      source: "cadpatbens/editar",
      titulo: "BENS PATRIMONIAIS",
      modo: "visualizar",
    },
  },

  //FRONT -PATRIMONIO / TRANSFERÊNCIA DE BENS
  {
    path: "/cadpattransfbens",
    component: load("MctDataViewer"),
    props: {
      source: "cadpattransfbens/lista",
      titulo: "TRANSFERÊNCIA DE BENS",
    },
  },
  {
    path: "/cadpattransfbens/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatTransfBens"),
    props: {
      source: "cadpattransfbens/novo",
      titulo: "TRANSFERÊNCIA DE BENS",
      modo: "novo",
    },
  },
  {
    path: "/cadpattransfbens/:pat_tran_codigo/:pat_tran_ug/:pat_tran_exe/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTransfBens"),
    props: {
      source: "cadpattransfbens/editar",
      titulo: "TRANSFERÊNCIA DE BENS",
      modo: "editar",
    },
  },
  {
    path: "/cadpattransfbens/:pat_tran_codigo/:pat_tran_ug/:pat_tran_exe/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTransfBens"),
    props: {
      source: "cadpattransfbens/deletar",
      titulo: "TRANSFERÊNCIA DE BENS",
      modo: "deletar",
    },
  },
  {
    path: "/cadpattransfbens/:pat_tran_codigo/:pat_tran_ug/:pat_tran_exe/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTransfBens"),
    props: {
      source: "cadpattransfbens/editar",
      titulo: "TRANSFERÊNCIA DE BENS",
      modo: "visualizar",
    },
  },

  //FRONT -PATRIMONIO / TERMO DE RESPONSABILIDADE / CONFERÊNCIA DE BENS
  {
    path: "/cadpatbenstermo",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermoLista"),
    props: {
      source: "cadpatbenstermo/lista",
      titulo: "TERMO DE RESPONSABILIDADE / CONFERÊNCIA DE BENS",
    },
  },
  {
    path: "/cadpatbenstermo/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermo"),
    props: {
      source: "cadpatbenstermo/novo",
      titulo: "TERMO DE RESPONSABILIDADE / CONFERÊNCIA DE BENS",
      modo: "novo",
    },
  },
  {
    path: "/cadpatbenstermo/:pat_termo_codigo/:pat_termo_ug/:pat_termo_exe/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermo"),
    props: {
      source: "cadpatbenstermo/editar",
      titulo: "TERMO DE RESPONSABILIDADE / CONFERÊNCIA DE BENS",
      modo: "editar",
    },
  },
  {
    path: "/cadpatbenstermo/:pat_termo_codigo/:pat_termo_ug/:pat_termo_exe/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermo"),
    props: {
      source: "cadpatbenstermo/deletar",
      titulo: "TERMO DE RESPONSABILIDADE / CONFERÊNCIA DE BENS",
      modo: "deletar",
    },
  },
  {
    path: "/cadpatbenstermo/:pat_termo_codigo/:pat_termo_ug/:pat_termo_exe/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermo"),
    props: {
      source: "cadpatbenstermo/editar",
      titulo: "TERMO DE RESPONSABILIDADE / CONFERÊNCIA DE BENS",
      modo: "visualizar",
    },
  },

  //FRONT - PATRIMONIO/ TERMO DE COMODATO DE BENS
  {
    path: "/cadpatbenstermocomodato",
    component: load("MctDataViewer"),
    props: {
      source: "cadpatbenstermocomodato/lista",
      titulo: "TERMO DE COMODATO DE BENS",
    },
  },
  {
    path: "/cadpatbenstermocomodato/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermoComodato"),
    props: {
      source: "cadpatbenstermocomodato/novo",
      titulo: "TERMO DE COMODATO DE BENS",
      modo: "novo",
    },
  },
  {
    path: "/cadpatbenstermocomodato/:pat_como_codigo/:pat_como_ug/:pat_como_exe/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermoComodato"),
    props: {
      source: "cadpatbenstermocomodato/editar",
      titulo: "TERMO DE COMODATO DE BENS",
      modo: "editar",
    },
  },
  {
    path: "/cadpatbenstermocomodato/:pat_como_codigo/:pat_como_ug/:pat_como_exe/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermoComodato"),
    props: {
      source: "cadpatbenstermocomodato/deletar",
      titulo: "TERMO DE COMODATO DE BENS",
      modo: "deletar",
    },
  },
  {
    path: "/cadpatbenstermocomodato/:pat_como_codigo/:pat_como_ug/:pat_como_exe/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatBensTermoComodato"),
    props: {
      source: "cadpatbenstermocomodato/editar",
      titulo: "TERMO DE COMODATO DE BENS",
      modo: "visualizar",
    },
  },

  //FRONT - PATRIMONIO / TERMO DE COMODATO AVALIAÇÃO / REAVALIAÇÃO DE BENS
  {
    path: "/cadpatatualizabens",
    component: load("MctDataViewer"),
    props: {
      source: "cadpatatualizabens/lista",
      titulo: "AVALIAÇÃO/REAVALIAÇÃO DE BENS",
    },
  },
  {
    path: "/cadpatatualizabens/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatAtualizaBens"),
    props: {
      source: "cadpatatualizabens/novo",
      titulo: "AVALIAÇÃO/REAVALIAÇÃO DE BENS",
      modo: "novo",
    },
  },
  {
    path: "/cadpatatualizabens/:pat_atu_codigo/:pat_atu_ug/:pat_atu_exe/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatAtualizaBens"),
    props: {
      source: "cadpatatualizabens/editar",
      titulo: "AVALIAÇÃO/REAVALIAÇÃO DE BENS",
      modo: "editar",
    },
  },
  {
    path: "/cadpatatualizabens/:pat_atu_codigo/:pat_atu_ug/:pat_atu_exe/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatAtualizaBens"),
    props: {
      source: "cadpatatualizabens/deletar",
      titulo: "AVALIAÇÃO/REAVALIAÇÃO DE BENS",
      modo: "deletar",
    },
  },
  {
    path: "/cadpatatualizabens/:pat_atu_codigo/:pat_atu_ug/:pat_atu_exe/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatAtualizaBens"),
    props: {
      source: "cadpatatualizabens/editar",
      titulo: "AVALIAÇÃO/REAVALIAÇÃO DE BENS",
      modo: "visualizar",
    },
  },

  //FRONT - PATRIMONIO - DEPRECIAÇÃO DE BENS

  //FRONT -/ PATRIMONIO / GERAL/ ORIGEM
  {
    path: "/cadpattporigem",
    component: load("MctDataViewer"),
    props: { source: "cadpattporigem/lista", titulo: "ORIGEM PATRIMÔNIO" },
  },
  {
    path: "/cadpattporigem/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpOrigem"),
    props: {
      source: "cadpattporigem/novo",
      titulo: "ORIGEM PATRIMÔNIO",
      modo: "novo",
    },
  },
  {
    path: "/cadpattporigem/:codigo/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpOrigem"),
    props: {
      source: "cadpattporigem/editar",
      titulo: "ORIGEM PATRIMÔNIO",
      modo: "editar",
    },
  },
  {
    path: "/cadpattporigem/:codigo/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpOrigem"),
    props: {
      source: "cadpattporigem/deletar",
      titulo: "ORIGEM PATRIMÔNIO",
      modo: "deletar",
    },
  },
  {
    path: "/cadpattporigem/:codigo/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpOrigem"),
    props: {
      source: "cadpattporigem/editar",
      titulo: "ORIGEM PATRIMÔNIO",
      modo: "visualizar",
    },
  },

  //FRONT -/ PATRIMONIO / GERAL / ESTADO
  {
    path: "/cadpattpstatus",
    component: load("MctDataViewer"),
    props: { source: "cadpattpstatus/lista", titulo: "ESTADO DO PATRIMÔNIO" },
  },
  {
    path: "/cadpattpstatus/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpStatus"),
    props: {
      source: "cadpattpstatus/novo",
      titulo: "ESTADO DO PATRIMÔNIO",
      modo: "novo",
    },
  },
  {
    path: "/cadpattpstatus/:codigo/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpStatus"),
    props: {
      source: "cadpattpstatus/editar",
      titulo: "ESTADO DO PATRIMÔNIO",
      modo: "editar",
    },
  },
  {
    path: "/cadpattpstatus/:codigo/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpStatus"),
    props: {
      source: "cadpattpstatus/deletar",
      titulo: "ESTADO DO PATRIMÔNIO",
      modo: "deletar",
    },
  },
  {
    path: "/cadpattpstatus/:codigo/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpStatus"),
    props: {
      source: "cadpattpstatus/editar",
      titulo: "ESTADO DO PATRIMÔNIO",
      modo: "visualizar",
    },
  },

  //FRONT -/ PATRIMONIO/ GERAL / TIPO EMPENHO
  {
    path: "/cadpattpempenho",
    component: load("MctDataViewer"),
    props: {
      source: "cadpattpempenho/lista",
      titulo: "TIPO DE EMPENHO PARA PATRIMÔNIO",
    },
  },
  {
    path: "/cadpattpempenho/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpEmpenho"),
    props: {
      source: "cadpattpempenho/novo",
      titulo: "TIPO DE EMPENHO PARA PATRIMÔNIO",
      modo: "novo",
    },
  },
  {
    path: "/cadpattpempenho/:codigo/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpEmpenho"),
    props: {
      source: "cadpattpempenho/editar",
      titulo: "TIPO DE EMPENHO PARA PATRIMÔNIO",
      modo: "editar",
    },
  },
  {
    path: "/cadpattpempenho/:codigo/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpEmpenho"),
    props: {
      source: "cadpattpempenho/deletar",
      titulo: "TIPO DE EMPENHO PARA PATRIMÔNIO",
      modo: "deletar",
    },
  },
  {
    path: "/cadpattpempenho/:codigo/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpEmpenho"),
    props: {
      source: "cadpattpempenho/editar",
      titulo: "TIPO DE EMPENHO PARA PATRIMÔNIO",
      modo: "visualizar",
    },
  },

  //FRONT -/ PATRIMONIO / GERAL/ MÉTODOS DE LICITAÇÃO
  {
    path: "/cadlicmetodo",
    component: load("MctDataViewer"),
    props: { source: "cadlicmetodo/lista", titulo: "MÉTODOS DE LICITAÇÃO" },
  },
  {
    path: "/cadlicmetodo/novo",
    component: loadC("modulos/sigcad/patrimonio/CadLicMetodo"),
    props: {
      source: "cadlicmetodo/novo",
      titulo: "MÉTODOS DE LICITAÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/cadlicmetodo/:codigo/editar",
    component: loadC("modulos/sigcad/patrimonio/CadLicMetodo"),
    props: {
      source: "cadlicmetodo/editar",
      titulo: "MÉTODOS DE LICITAÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/cadlicmetodo/:codigo/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadLicMetodo"),
    props: {
      source: "cadlicmetodo/deletar",
      titulo: "MÉTODOS DE LICITAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/cadlicmetodo/:codigo/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadLicMetodo"),
    props: {
      source: "cadlicmetodo/editar",
      titulo: "MÉTODOS DE LICITAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT -/ PATRIMONIO / GERAL / TIPO CLASSIFICAÇÃO
  {
    path: "/cadpattpclassificacao",
    component: load("MctDataViewer"),
    props: {
      source: "cadpattpclassificacao/lista",
      titulo: "TIPO DE CLASSIFICAÇÃO",
    },
  },
  {
    path: "/cadpattpclassificacao/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpClassificacao"),
    props: {
      source: "cadpattpclassificacao/novo",
      titulo: "TIPO DE CLASSIFICAÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/cadpattpclassificacao/:codigo/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpClassificacao"),
    props: {
      source: "cadpattpclassificacao/editar",
      titulo: "TIPO DE CLASSIFICAÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/cadpattpclassificacao/:codigo/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpClassificacao"),
    props: {
      source: "cadpattpclassificacao/deletar",
      titulo: "TIPO DE CLASSIFICAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/cadpattpclassificacao/:codigo/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpClassificacao"),
    props: {
      source: "cadpattpclassificacao/editar",
      titulo: "TIPO DE CLASSIFICAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT -/ PATRIMONIO/ GERAL/ TIPO AVALIAÇÃO
  {
    path: "/cadpattpavaliacao",
    component: load("MctDataViewer"),
    props: { source: "cadpattpavaliacao/lista", titulo: "TIPO DE AVALIAÇÃO" },
  },
  {
    path: "/cadpattpavaliacao/novo",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpAvaliacao"),
    props: {
      source: "cadpattpavaliacao/novo",
      titulo: "TIPO DE AVALIAÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/cadpattpavaliacao/:codigo/editar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpAvaliacao"),
    props: {
      source: "cadpattpavaliacao/editar",
      titulo: "TIPO DE AVALIAÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/cadpattpavaliacao/:codigo/deletar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpAvaliacao"),
    props: {
      source: "cadpattpavaliacao/deletar",
      titulo: "TIPO DE AVALIAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/cadpattpavaliacao/:codigo/visualizar",
    component: loadC("modulos/sigcad/patrimonio/CadPatTpAvaliacao"),
    props: {
      source: "cadpattpavaliacao/editar",
      titulo: "TIPO DE AVALIAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT - PATRIMONIO / BENS IMOVEL
  {
    path: "/cadpatimovel",
    component: load("MctDataViewer"),
    props: { source: "cadpatimovel/lista", titulo: "BENS IMÓVEIS" },
  },
  {
    path: "/cadpatimovel/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadpatimovel/novo",
      titulo: "BENS IMÓVEIS",
      modo: "novo",
    },
  },
  {
    path: "/cadpatimovel/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadpatimovel/editar",
      titulo: "BENS IMÓVEIS",
      modo: "editar",
    },
  },
  {
    path: "/cadpatimovel/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadpatimovel/deletar",
      titulo: "BENS IMÓVEIS",
      modo: "deletar",
    },
  },
  {
    path: "/cadpatimovel/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadpatimovel/editar",
      titulo: "BENS IMÓVEIS",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/CONTRATOS/DISTRATOSadministrativo/
  {
    path: "/cadcontratosdocumentos",
    component: load("MctDataViewer"),
    props: {
      source: "cadcontratosdocumentos/lista",
      titulo: "CONTRATO DOCUMENTOS",
    },
  },
  {
    path: "/cadcontratosdocumentos/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdocumentos/novo",
      titulo: "CONTRATO DOCUMENTOS",
      modo: "novo",
    },
  },
  {
    path: "/cadcontratosdocumentos/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdocumentos/editar",
      titulo: "CONTRATO DOCUMENTOS",
      modo: "editar",
    },
  },
  {
    path: "/cadcontratosdocumentos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdocumentos/deletar",
      titulo: "CONTRATO DOCUMENTOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadcontratosdocumentos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdocumentos/editar",
      titulo: "CONTRATO DOCUMENTOS",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/CONTRATOS/DISTRATOSadministrativo/
  {
    path: "/cadcontratosdotacao",
    component: load("MctDataViewer"),
    props: { source: "cadcontratosdotacao/lista", titulo: "CONTRATO DOTAÇÃO" },
  },
  {
    path: "/cadcontratosdotacao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdotacao/novo",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/cadcontratosdotacao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdotacao/editar",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/cadcontratosdotacao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdotacao/deletar",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/cadcontratosdotacao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosdotacao/editar",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/CONTRATOS/DISTRATOSadministrativo/
  {
    path: "/cadcontratostipos",
    component: load("MctDataViewer"),
    props: { source: "cadcontratostipos/lista", titulo: "CONTRATO TIPOS" },
  },
  {
    path: "/cadcontratostipos/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratostipos/novo",
      titulo: "CONTRATO TIPOS",
      modo: "novo",
    },
  },
  {
    path: "/cadcontratostipos/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratostipos/editar",
      titulo: "CONTRATO TIPOS",
      modo: "editar",
    },
  },
  {
    path: "/cadcontratostipos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratostipos/deletar",
      titulo: "CONTRATO TIPOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadcontratostipos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratostipos/editar",
      titulo: "CONTRATO TIPOS",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/CONTRATOS/DISTRATOSadministrativo/
  {
    path: "/cadcontratosnatureza",
    component: load("MctDataViewer"),
    props: { source: "cadcontratosnatureza/lista", titulo: "CONTRATO DOTAÇÃO" },
  },
  {
    path: "/cadcontratosnatureza/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosnatureza/novo",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "novo",
    },
  },
  {
    path: "/cadcontratosnatureza/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosnatureza/editar",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "editar",
    },
  },
  {
    path: "/cadcontratosnatureza/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosnatureza/deletar",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "deletar",
    },
  },
  {
    path: "/cadcontratosnatureza/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadcontratosnatureza/editar",
      titulo: "CONTRATO DOTAÇÃO",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/ALMOXARIFADO/ENTRADA ITENS ent_codigo, ent_ug, ent_exe, ent_item_cod, ent_item_ug
  {
    path: "/cadentradaitens",
    component: load("MctDataViewer"),
    props: {
      source: "cadentradaitens/lista",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS/ITENS",
    },
  },
  {
    path: "/cadentradaitens/novo",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaitens/novo",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS/ITENS",
      modo: "novo",
    },
  },
  {
    path: "/cadentradaitens/:ent_codigo/:ent_exe/:ent_item_cod/:ent_item_ug/editar",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaitens/editar",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS/ITENS",
      modo: "editar",
    },
  },
  {
    path: "/cadentradaitens/:ent_codigo/:ent_exe/:ent_item_cod/:ent_item_ug/deletar",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaitens/deletar",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS/ITENS",
      modo: "deletar",
    },
  },
  {
    path: "/cadentradaitens/:ent_codigo/:ent_exe/:ent_item_cod/:ent_item_ug/visualizar",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaitens/editar",
      titulo: "ENTRADA DE PRODUTOS/SERVIÇOS/ITENS",
      modo: "visualizar",
    },
  },

  //FRONT -ADM/ALMOXARIFADO/ENTRADA PARCELAS ent_codigo, ent_ug, ent_exe, ent_par_parcela)
  {
    path: "/cadentradaparcelas",
    component: load("MctDataViewer"),
    props: {
      source: "cadentradaparcelas/lista",
      titulo: "ENTRADA DE PRODUTOS/PARCELAS",
    },
  },
  {
    path: "/cadentradaparcelas/novo",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaparcelas/novo",
      titulo: "ENTRADA DE PRODUTOS/PARCELAS",
      modo: "novo",
    },
  },
  {
    path: "/cadentradaparcelas/:ent_codigo/:ent_ug/:ent_exe/:ent_par_parcela/editar",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaparcelas/editar",
      titulo: "ENTRADA DE PRODUTOS/PARCELAS",
      modo: "editar",
    },
  },
  {
    path: "/cadentradaparcelas/:ent_codigo/:ent_ug/:ent_exe/:ent_par_parcela/deletar",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaparcelas/deletar",
      titulo: "ENTRADA DE PRODUTOS/PARCELAS",
      modo: "deletar",
    },
  },
  {
    path: "/cadentradaparcelas/:ent_codigo/:ent_ug/:ent_exe/:ent_par_parcela/visualizar",
    component: load("MctForViewer"),
    props: {
      source: "cadentradaparcelas/editar",
      titulo: "ENTRADA DE PRODUTOS/PARCELAS",
      modo: "visualizar",
    },
  },

  //FRONT -/ALMOXARIFADO/SAÍDA DE PRODUTOS/SERVIÇOS sai_codigo, sai_ug, sai_exe, sai_item_cod, sai_item_ug
  {
    path: "/cadsaidaitem",
    component: load("MctDataViewer"),
    props: {
      source: "cadsaidaitem/lista",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
    },
  },
  {
    path: "/cadsaidaitem/novo",
    component: load("MctForViewer"),
    props: {
      source: "cadsaidaitem/novo",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "novo",
    },
  },
  {
    path: "/cadsaidaitem/:sai_codigo/:sai_ug/:sai_exe/sai_item_cod/sai_item_ug/editar",
    component: load("MctForViewer"),
    props: {
      source: "cadsaidaitem/editar",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "editar",
    },
  },
  {
    path: "/cadsaidaitem/:sai_codigo/:sai_ug/:sai_exe/sai_item_cod/sai_item_ug/deletar",
    component: load("MctForViewer"),
    props: {
      source: "cadsaidaitem/deletar",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "deletar",
    },
  },
  {
    path: "/cadsaidaitem/:sai_codigo/:sai_ug/:sai_exe/sai_item_cod/sai_item_ug/visualizar",
    component: load("MctForViewer"),
    props: {
      source: "cadsaida/editar",
      titulo: "SAÍDA DE PRODUTOS/SERVIÇOS",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS/TERMO REFERENCIA PROCESSOS/ trm_codigo, trm_ug, trm_exe, proc_codigo, proc_ug, proc_exe)
  {
    path: "/cadtermoreferenciaprocessos",
    component: load("MctDataViewer"),
    props: {
      source: "cadtermoreferenciaprocessos/lista",
      titulo: "TERMOS DE REFERÊNCIA",
    },
  },
  {
    path: "/cadtermoreferenciaprocessos/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadtermoreferenciaprocessos/novo",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "novo",
    },
  },
  {
    path: "/cadtermoreferenciaprocessos/:trm_codigo/:trm_ug/:trm_exe:/proc_codigo/:proc_ug/:proc_exe/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtermoreferenciaprocessos/editar",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "editar",
    },
  },
  {
    path: "/cadtermoreferenciaprocessos/:trm_codigo/:trm_ug/:trm_exe:/proc_codigo/:proc_ug/:proc_exe/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtermoreferenciaprocessos/deletar",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "deletar",
    },
  },
  {
    path: "/cadtermoreferenciaprocessos/:trm_codigo/:trm_ug/:trm_exe:/proc_codigo/:proc_ug/:proc_exe/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadtermoreferenciaprocessos/editar",
      titulo: "TERMOS DE REFERÊNCIA",
      modo: "visualizar",
    },
  },

  //FRONT - CAD-ORDEM-ITEM COMPRAS/ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS
  {
    path: "/cadordemcompraitens",
    component: load("MctDataViewer"),
    props: { source: "cadordemcompraitens/lista", titulo: "CAD ORDEM ITEM" },
  },
  {
    path: "/cadordemcompraitens/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadordemcompraitens/novo",
      titulo: "CAD ORDEM ITEM",
      modo: "novo",
    },
  },
  {
    path: "/cadordemcompraitens/:cmpo_item_codigo/:cmpo_codigo/:cmpo_ug/:cmpo_exe/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadordemcompraitens/editar",
      titulo: "CAD ORDEM ITEM",
      modo: "editar",
    },
  },
  {
    path: "/cadordemcompraitens/:cmpo_item_codigo/:cmpo_codigo/:cmpo_ug/:cmpo_exe/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadordemcompraitens/deletar",
      titulo: "CAD ORDEM ITEM",
      modo: "deletar",
    },
  },
  {
    path: "/cadordemcompraitens/:cmpo_item_codigo/:cmpo_codigo/:cmpo_ug/:cmpo_exe/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadordemcompraitens/editar",
      titulo: "CAD ORDEM ITEM",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS/ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS
  {
    path: "/unimedida",
    component: load("MctDataViewer"),
    props: { source: "unimedida/lista", titulo: "UNIDADE DE MEDIDA" },
  },
  {
    path: "/unimedida/novo",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/novo",
      titulo: "UNIDADE DE MEDIDA",
      modo: "novo",
    },
  },
  {
    path: "/unimedida/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/editar",
      titulo: "UNIDADE DE MEDIDA",
      modo: "editar",
    },
  },
  {
    path: "/unimedida/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/deletar",
      titulo: "UNIDADE DE MEDIDA",
      modo: "deletar",
    },
  },
  {
    path: "/unimedida/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "unimedida/editar",
      titulo: "UNIDADE DE MEDIDA",
      modo: "visualizar",
    },
  },

  //FRONT - COMPRAS/ORDENS DE FORNECIMENTO E PRÉ-EMPENHO DE PRODUTOS / SERVIÇOS
  {
    path: "/uniorcamentarias",
    component: load("MctDataViewer"),
    props: {
      source: "uniorcamentarias/lista",
      titulo: "UNIDADE ORÇAMENTARIAS",
    },
  },
  {
    path: "/uniorcamentarias/novo",
    component: load("MctFormViewer"),
    props: {
      source: "uniorcamentarias/novo",
      titulo: "UNIDADE ORÇAMENTARIAS",
      modo: "novo",
    },
  },

  {
    path: "/uniorcamentarias/:uni_codigo/:org_codigo/:ug_codigo/:exe_codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "uniorcamentarias/editar",
      titulo: "Unidade Orçamentária",
      modo: "editar",
    },
  },
  {
    path: "/uniorcamentarias/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "uniorcamentarias/editar",
      titulo: "UNIDADE ORÇAMENTARIAS",
      modo: "editar",
    },
  },
  {
    path: "/uniorcamentarias/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "uniorcamentarias/deletar",
      titulo: "UNIDADE ORÇAMENTARIAS",
      modo: "deletar",
    },
  },
  {
    path: "/uniorcamentarias/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "uniorcamentarias/editar",
      titulo: "UNIDADE ORÇAMENTARIAS",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO  CONSELHOS / CONSELHO CARGOS
  {
    path: "/cadconselhocargos",
    component: load("MctDataViewer"),
    props: { source: "cadconselhocargos/lista", titulo: "Conselho Cargos" },
  },
  {
    path: "/cadconselhocargos/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhocargos/novo",
      titulo: "Conselho Cargos",
      modo: "novo",
    },
  },
  {
    path: "/cadconselhocargos/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhocargos/editar",
      titulo: "Conselho Cargos",
      modo: "editar",
    },
  },
  {
    path: "/cadconselhocargos/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhocargos/deletar",
      titulo: "Conselho Cargos",
      modo: "deletar",
    },
  },
  {
    path: "/cadconselhocargos/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhocargos/editar",
      titulo: "Conselho Cargos",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO  CONSELHOS / CONSELHO MWMBROS
  {
    path: "/cadconselhomembros",
    component: load("MctDataViewer"),
    props: { source: "cadconselhomembros/lista", titulo: "Conselho Cargos" },
  },
  {
    path: "/cadconselhomembros/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhomembros/novo",
      titulo: "Conselho Cargos",
      modo: "novo",
    },
  },
  {
    path: "/cadconselhomembros/:cons_codigo/:cons_ug/:cons_mem_membro_cod/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhomembros/editar",
      titulo: "Conselho Cargos",
      modo: "editar",
    },
  },
  {
    path: "/cadconselhomembros/:cons_codigo/:cons_ug/:cons_mem_membro_cod/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhomembros/deletar",
      titulo: "Conselho Cargos",
      modo: "deletar",
    },
  },
  {
    path: "/cadconselhomembros/:cons_codigo/:cons_ug/:cons_mem_membro_cod/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhomembros/editar",
      titulo: "Conselho Cargos",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO  CONSELHOS / CONSELHO NATUREZA
  {
    path: "/cadconselhonatureza",
    component: load("MctDataViewer"),
    props: {
      source: "cadconselhonatureza/lista",
      titulo: "NATUREZA NO CONSELHO",
    },
  },
  {
    path: "/cadconselhonatureza/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhonatureza/novo",
      titulo: "NATUREZA NO CONSELHO",
      modo: "novo",
    },
  },
  {
    path: "/cadconselhonatureza/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhonatureza/editar",
      titulo: "NATUREZA NO CONSELHO",
      modo: "editar",
    },
  },
  {
    path: "/cadconselhonatureza/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhonatureza/deletar",
      titulo: "NATUREZA NO CONSELHO",
      modo: "deletar",
    },
  },
  {
    path: "/cadconselhonatureza/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhonatureza/editar",
      titulo: "NATUREZA NO CONSELHO",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO  CONSELHOS / CONSELHO TIPO DE CONSELHO
  {
    path: "/cadconselhotipo",
    component: load("MctDataViewer"),
    props: { source: "cadconselhotipo/lista", titulo: "TIPO DE CONSELHO" },
  },
  {
    path: "/cadconselhotipo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhotipo/novo",
      titulo: "TIPO DE CONSELHO",
      modo: "novo",
    },
  },
  {
    path: "/cadconselhotipo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhotipo/editar",
      titulo: "TIPO DE CONSELHO",
      modo: "editar",
    },
  },
  {
    path: "/cadconselhotipo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhotipo/deletar",
      titulo: "TIPO DE CONSELHO",
      modo: "deletar",
    },
  },
  {
    path: "/cadconselhotipo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cadconselhotipo/editar",
      titulo: "TIPO DE CONSELHO",
      modo: "visualizar",
    },
  },

  //FRONT - CADASTRO  CONSELHOS / CONSELHO ATA DO CONSELHO MUNICIPAL
  {
    path: "/cadconselhoata",
    component: load("MctDataViewer"),
    props: {
      source: "cadconselhoata/lista",
      titulo: "ATA DO CONSELHO MUNICIPAL",
    },
  },
  {
    path: "/cadconselhoata/novo",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/novo",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "novo",
    },
  },
  {
    path: "/cadconselhoata/:cons_ata_codigo/:cons_codigo/:cons_ug/editar",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/editar",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "editar",
    },
  },
  {
    path: "/cadconselhoata/:cons_ata_codigo/:cons_codigo/:cons_ug/deletar",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/deletar",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "deletar",
    },
  },
  {
    path: "/cadconselhoata/:cons_ata_codigo/:cons_codigo/:cons_ug/visualizar",
    component: loadC("modulos/sigcad/cadastro/CadConselhoAta"),
    props: {
      source: "cadconselhoata/editar",
      titulo: "ATA DO CONSELHO MUNICIPAL",
      modo: "visualizar",
    },
  },

  {
    path: "/moedas",
    component: load("MctDataViewer"),
    props: { source: "moedas/lista", titulo: "MOEDAS" },
  },
  {
    path: "/moedas/novo",
    component: load("MctFormViewer"),
    props: { source: "moedas/novo", titulo: "MOEDAS", modo: "novo" },
  },
  {
    path: "/moedas/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "moedas/editar", titulo: "MOEDAS", modo: "editar" },
  },
  {
    path: "/moedas/:codigo/deletar",
    component: load("MctFormViewer"),
    props: { source: "moedas/deletar", titulo: "MOEDAS", modo: "deletar" },
  },
  {
    path: "/moedas/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: { source: "moedas/editar", titulo: "MOEDAS", modo: "visualizar" },
  },
];
