import * as MutationTypes from "./MutationTypes";
import axios from "@/http";
import axiosRH from "@/httpRH";

const state = {
  infoLogado: [],
  token: "",
  usuLogado: false,
  infoModulo: 0,
  infoEntidade: {},
  Entidade: 0,
  EntidadeOrc: "",
  Exercicio: 0,
  Competencia: 1,
  infoSubModulo: 0,
  IP: "",
  baseURL: "http://localhost:8000/",
  infoMenu: [],
  layout: "div",
};

const mutations = {
  [MutationTypes.USU_LOGADO](state, response) {
    state.infoLogado = response.user;
    state.infoModulo = 0;
    state.Entidade = response.entidade;
    //state.EntidadeOrc = 'EL'
    //state.Exercicio = 2018
    //state.Competencia = '01'
    //state.infoSubModulo = 0
    localStorage.setItem("id_token", response.token);
    localStorage.setItem("mct_entidade", state.Entidade);
    //localStorage.setItem('mct_exercicio', state.Exercicio)
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("id_token");
    axiosRH.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("id_token");
    //axios.defaults.params = {'mct_entidade': state.Entidade,'mct_exercicio': state.Exercicio}
  },
  [MutationTypes.USU_LOGOUT](state) {
    state.token = "";
    state.infoLogado = [];
    state.infoModulo = [];
    state.infoEntidade = {};
    state.Entidade = 0;
    state.EntidadeOrc = "";
    state.Exercicio = 0;
    state.Competencia = 1;
    state.infoSubModulo = [];
    axios.defaults.params = "";
    //console.log('deslogado');
  },
  [MutationTypes.USU_LOGAR_SUCCESS](state, response) {
    state.usuLogado = response;
  },
  [MutationTypes.USU_MODULO](state, response) {
    state.infoModulo = response;
    state.infoSubModulo = [];
  },
  [MutationTypes.USU_SUBMODULO](state, response) {
    state.infoModulo = response.modulo;
    state.infoSubModulo = response;
  },
  [MutationTypes.MENU](state, response) {
    state.infoMenu = response;
  },
  [MutationTypes.USU_EXERCICIO](state, response) {
    state.Exercicio = response;
    localStorage.setItem("mct_exercicio", state.Exercicio);
    axios.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
    axiosRH.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
  },
  [MutationTypes.USU_COMPETENCIA](state, response) {
    state.Competencia = response;
    localStorage.setItem("mct_competencia", state.Competencia);
    axios.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
    axiosRH.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
  },
  [MutationTypes.USU_ULTEXERCICIO](state, response) {
    state.Entidade = response[0].ug_codigo;
    state.Exercicio = response[0].exe_codigo;
    state.EntidadeOrc = response[0].ug_orcamento;
    state.Competencia = response[0].comp_codigo ?? 1;
    state.infoEntidade = response[0];
    localStorage.setItem("mct_exercicio", state.Exercicio);
    axios.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
    axiosRH.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
  },
  [MutationTypes.USU_IP](state, response) {
    state.IP = response;
    axios.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
    axiosRH.defaults.params = {
      mct_entidade: state.Entidade,
      mct_exercicio: state.Exercicio,
      mct_competencia: state.Competencia,
      mct_ip: state.IP,
    };
  },
  [MutationTypes.LAYOUT](state, response) {
    state.layout = response;
  },
};

const actions = {
  [MutationTypes.USU_BUSCA]({ commit }) {
    axios
      .get("/user")
      .then((response) => {
        //console.log('STATUS: '+ response.status);
        if (response.status === 200) {
          commit(MutationTypes.USU_LOGAR_SUCCESS, true);
        } else {
          commit(MutationTypes.USU_LOGOUT);
        }
      })
      .catch(function (response, error) {
        if (response.response.status === 401) {
          commit(MutationTypes.USU_LOGOUT);
        }
      });
  },
  [MutationTypes.USU_LOGADO]({ commit }, response) {
    commit(MutationTypes.USU_LOGADO, response);
  },
  [MutationTypes.USU_LOGAR_SUCCESS]({ commit }, response) {
    commit(MutationTypes.USU_LOGAR_SUCCESS, response);
  },
  [MutationTypes.USU_LOGOUT]({ commit }) {
    commit(MutationTypes.USU_LOGOUT);
  },
  [MutationTypes.USU_MODULO]({ commit }, response) {
    commit(MutationTypes.USU_MODULO, response);
    //dispatch(MutationTypes.MENU);
  },
  [MutationTypes.USU_SUBMODULO]({ commit }, response) {
    commit(MutationTypes.USU_SUBMODULO, response);
    //dispatch(MutationTypes.MENU);
  },
  [MutationTypes.USU_EXERCICIO]({ commit }, response) {
    commit(MutationTypes.USU_EXERCICIO, response);
  },
  [MutationTypes.USU_COMPETENCIA]({ commit }, response) {
    commit(MutationTypes.USU_COMPETENCIA, response);
  },
  [MutationTypes.MENU]({ commit }) {
    let submodulo =
      state.infoSubModulo.smod_codigo !== undefined
        ? state.infoSubModulo.smod_codigo
        : 0;
    axios
      .get(
        `menu/` +
          state.Entidade +
          `/` +
          state.infoModulo.mod_codigo +
          `/` +
          submodulo +
          `/` +
          state.infoLogado.per_codigo
      )
      .then(function (response) {
        commit(MutationTypes.MENU, response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  },
  [MutationTypes.USU_ULTEXERCICIO]({ commit }) {
    axios
      .get(`exercicio/ultimo/` + state.Entidade)
      .then(function (response) {
        commit(MutationTypes.USU_ULTEXERCICIO, response.data);
      })
      .catch(function (response) {
        console.log(response);
      });
  },
  [MutationTypes.USU_IP]({ commit }) {
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        commit(MutationTypes.USU_IP, ip);
        axios.defaults.params = {
          mct_entidade: state.Entidade,
          mct_exercicio: state.Exercicio,
          mct_competencia: state.Competencia,
          mct_ip: state.IP,
        };
      })
      .catch((response) => {
        console.log(response);
      });
  },
  [MutationTypes.LAYOUT]({ commit }, response) {
    commit(MutationTypes.LAYOUT, response);
  },
};

const getters = {
  infoLogado: (state) => state.infoLogado,
  infoModulo: (state) => state.infoModulo,
  infoEntidade: (state) => state.infoEntidade,
  usuLogado: (state) => state.usuLogado,
  Entidade: (state) => state.Entidade,
  EntidadeOrc: (state) => state.EntidadeOrc,
  Exercicio: (state) => state.Exercicio,
  Competencia: (state) => state.Competencia,
  infoSubModulo: (state) => state.infoSubModulo,
  infoMenu: (state) => state.infoMenu,
  baseURL: (state) => state.baseURL,
  IP: (state) => state.IP,
  layout: (state) => state.layout,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
