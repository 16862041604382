function load(component) {
  return () => import(`../components/comum/${component}.vue`);
}

function loadC(component) {
  return () => import(`../components/${component}.vue`);
}

export default [
  /* CADASTROS */
  {
    path: "/orgao",
    component: load("MctDataViewer"),
    props: { source: "orgaos/lista", titulo: "Horário de Funcionamento" },
  },
  {
    path: "/orgao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "orgaos/novo",
      titulo: "Horário de Funcionamento",
      modo: "novo",
    },
  },
  {
    path: "/orgao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "orgaos/editar",
      titulo: "Horário de Funcionamento",
      modo: "editar",
    },
  },
  {
    path: "/orgao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "orgaos/deletar",
      titulo: "Horário de Funcionamento",
      modo: "deletar",
    },
  },
  {
    path: "/orgao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "orgaos/editar",
      titulo: "Horário de Funcionamento",
      modo: "visualizar",
    },
  },

  {
    path: "/horariofunc",
    component: load("MctDataViewer"),
    props: { source: "horario/lista", titulo: "Horário de Funcionamento" },
  },
  {
    path: "/horariofunc/novo",
    component: loadC("modulos/sigmun/cadastro/HorarioFunc"),
    props: {
      source: "horario/novo",
      titulo: "Horário de Funcionamento",
      modo: "novo",
    },
  },
  {
    path: "/horariofunc/:codigo/editar",
    component: loadC("modulos/sigmun/cadastro/HorarioFunc"),
    props: {
      source: "horario/editar",
      titulo: "Horário de Funcionamento",
      modo: "editar",
    },
  },
  {
    path: "/horariofunc/:codigo/deletar",
    component: loadC("modulos/sigmun/cadastro/HorarioFunc"),
    props: {
      source: "horario/deletar",
      titulo: "Horário de Funcionamento",
      modo: "deletar",
    },
  },
  {
    path: "/horariofunc/:codigo/visualizar",
    component: loadC("modulos/sigmun/cadastro/HorarioFunc"),
    props: {
      source: "horario/editar",
      titulo: "Horário de Funcionamento",
      modo: "visualizar",
    },
  },

  {
    path: "/ramoatividade",
    component: load("MctDataViewer"),
    props: { source: "ramoatividade/lista", titulo: "Ramo de Atividade" },
  },
  {
    path: "/ramoatividade/novo",
    component: load("MctFormViewer"),
    props: {
      source: "ramoatividade/novo",
      titulo: "Ramo de Atividade",
      modo: "novo",
    },
  },
  {
    path: "/ramoatividade/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "ramoatividade/editar",
      titulo: "Ramo de Atividade",
      modo: "editar",
    },
  },
  {
    path: "/ramoatividade/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "ramoatividade/deletar",
      titulo: "Ramo de Atividade",
      modo: "deletar",
    },
  },
  {
    path: "/ramoatividade/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "ramoatividade/editar",
      titulo: "Ramo de Atividade",
      modo: "visualizar",
    },
  },

  {
    path: "/naturezajuridica",
    component: load("MctDataViewer"),
    props: { source: "naturezajuridica/lista", titulo: "Natureza Jurídica" },
  },
  {
    path: "/naturezajuridica/novo",
    component: load("MctFormViewer"),
    props: {
      source: "naturezajuridica/novo",
      titulo: "Natureza Jurídica",
      modo: "novo",
    },
  },
  {
    path: "/naturezajuridica/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "naturezajuridica/editar",
      titulo: "Natureza Jurídica",
      modo: "editar",
    },
  },
  {
    path: "/naturezajuridica/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "naturezajuridica/deletar",
      titulo: "Natureza Jurídica",
      modo: "deletar",
    },
  },
  {
    path: "/naturezajuridica/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "naturezajuridica/editar",
      titulo: "Natureza Jurídica",
      modo: "visualizar",
    },
  },

  {
    path: "/servicogrupo",
    component: load("MctDataViewer"),
    props: { source: "servicogrupo/lista", titulo: "Grupo de Serviços" },
  },
  {
    path: "/servicogrupo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "servicogrupo/novo",
      titulo: "Grupo de Serviços",
      modo: "novo",
    },
  },
  {
    path: "/servicogrupo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "servicogrupo/editar",
      titulo: "Grupo de Serviços",
      modo: "editar",
    },
  },
  {
    path: "/servicogrupo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "servicogrupo/deletar",
      titulo: "Grupo de Serviços",
      modo: "deletar",
    },
  },
  {
    path: "/servicogrupo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "servicogrupo/editar",
      titulo: "Grupo de Serviços",
      modo: "visualizar",
    },
  },

  {
    name: "servico",
    path: "/servico",
    component: load("MctDataViewer"),
    props: { source: "servico/lista", titulo: "Serviços" },
  },
  {
    name: "servico",
    path: "/servico/novo",
    component: load("MctFormViewer"),
    props: { source: "servico/novo", titulo: "Serviços", modo: "novo" },
  },
  {
    name: "servico",
    path: "/servico/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "servico/editar", titulo: "Serviços", modo: "editar" },
  },
  {
    name: "servico",
    path: "/servico/:codigo/deletar",
    component: load("MctFormViewer"),
    props: { source: "servico/deletar", titulo: "Serviços", modo: "deletar" },
  },
  {
    name: "servico",
    path: "/servico/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: { source: "servico/editar", titulo: "Serviços", modo: "visualizar" },
  },

  {
    path: "/cnaeclasse",
    component: load("MctDataViewer"),
    props: { source: "cnaeclasse/lista", titulo: "CLASSE (CNAE)" },
  },
  {
    path: "/cnaeclasse/novo",
    component: load("MctFormViewer"),
    props: { source: "cnaeclasse/novo", titulo: "CLASSE (CNAE)", modo: "novo" },
  },
  {
    path: "/cnaeclasse/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaeclasse/editar",
      titulo: "CLASSE (CNAE)",
      modo: "editar",
    },
  },
  {
    path: "/cnaeclasse/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaeclasse/deletar",
      titulo: "CLASSE (CNAE)",
      modo: "deletar",
    },
  },
  {
    path: "/cnaeclasse/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaeclasse/editar",
      titulo: "CLASSE (CNAE)",
      modo: "visualizar",
    },
  },

  {
    path: "/cnaedivisao",
    component: load("MctDataViewer"),
    props: { source: "cnaedivisao/lista", titulo: "DIVISÃO (CNAE)" },
  },
  {
    path: "/cnaedivisao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cnaedivisao/novo",
      titulo: "DIVISÃO (CNAE)",
      modo: "novo",
    },
  },
  {
    path: "/cnaedivisao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaedivisao/editar",
      titulo: "DIVISÃO (CNAE)",
      modo: "editar",
    },
  },
  {
    path: "/cnaedivisao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaedivisao/deletar",
      titulo: "DIVISÃO (CNAE)",
      modo: "deletar",
    },
  },
  {
    path: "/cnaedivisao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaedivisao/editar",
      titulo: "DIVISÃO (CNAE)",
      modo: "visualizar",
    },
  },

  {
    path: "/cnaegrupo",
    component: load("MctDataViewer"),
    props: { source: "cnaegrupo/lista", titulo: "GRUPO (CNAE)" },
  },
  {
    path: "/cnaegrupo/novo",
    component: load("MctFormViewer"),
    props: { source: "cnaegrupo/novo", titulo: "GRUPO (CNAE)", modo: "novo" },
  },
  {
    path: "/cnaegrupo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaegrupo/editar",
      titulo: "GRUPO (CNAE)",
      modo: "editar",
    },
  },
  {
    path: "/cnaegrupo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaegrupo/deletar",
      titulo: "GRUPO (CNAE)",
      modo: "deletar",
    },
  },
  {
    path: "/cnaegrupo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaegrupo/editar",
      titulo: "GRUPO (CNAE)",
      modo: "visualizar",
    },
  },

  {
    path: "/cnaesecao",
    component: load("MctDataViewer"),
    props: { source: "cnaesecao/lista", titulo: "SEÇÃO (CNAE)" },
  },
  {
    path: "/cnaesecao/novo",
    component: load("MctFormViewer"),
    props: { source: "cnaesecao/novo", titulo: "SEÇÃO (CNAE)", modo: "novo" },
  },
  {
    path: "/cnaesecao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaesecao/editar",
      titulo: "SEÇÃO (CNAE)",
      modo: "editar",
    },
  },
  {
    path: "/cnaesecao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaesecao/deletar",
      titulo: "SEÇÃO (CNAE)",
      modo: "deletar",
    },
  },
  {
    path: "/cnaesecao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaesecao/editar",
      titulo: "SEÇÃO (CNAE)",
      modo: "visualizar",
    },
  },

  {
    path: "/cnaesubclasse",
    component: load("MctDataViewer"),
    props: { source: "cnaesubclasse/lista", titulo: "SUB-CLASSE (CNAE)" },
  },
  {
    path: "/cnaesubclasse/novo",
    component: load("MctFormViewer"),
    props: {
      source: "cnaesubclasse/novo",
      titulo: "SUB-CLASSE (CNAE)",
      modo: "novo",
    },
  },
  {
    path: "/cnaesubclasse/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaesubclasse/editar",
      titulo: "SUB-CLASSE (CNAE)",
      modo: "editar",
    },
  },
  {
    path: "/cnaesubclasse/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaesubclasse/deletar",
      titulo: "SUB-CLASSE (CNAE)",
      modo: "deletar",
    },
  },
  {
    path: "/cnaesubclasse/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "cnaesubclasse/editar",
      titulo: "SUB-CLASSE (CNAE)",
      modo: "visualizar",
    },
  },

  {
    path: "/categoria",
    component: load("MctDataViewer"),
    props: { source: "categoria/lista", titulo: "CATEGORIA" },
  },
  {
    path: "/categoria/novo",
    component: load("MctFormViewer"),
    props: { source: "categoria/novo", titulo: "CATEGORIA", modo: "novo" },
  },
  {
    path: "/categoria/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "categoria/editar", titulo: "CATEGORIA", modo: "editar" },
  },
  {
    path: "/categoria/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "categoria/deletar",
      titulo: "CATEGORIA",
      modo: "deletar",
    },
  },
  {
    path: "/categoria/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "categoria/editar",
      titulo: "CATEGORIA",
      modo: "visualizar",
    },
  },

  {
    path: "/subcategoria",
    component: load("MctDataViewer"),
    props: { source: "subcategoria/lista", titulo: "SUB-CATEGORIA" },
  },
  {
    path: "/subcategoria/novo",
    component: loadC("modulos/sigmun/cadastro/SubCategoria"),
    props: {
      source: "subcategoria/novo",
      titulo: "SUB-CATEGORIA",
      modo: "novo",
    },
  },
  {
    path: "/subcategoria/:sub_ctg_codigo/:exe_codigo/:ug_codigo/editar",
    component: loadC("modulos/sigmun/cadastro/SubCategoria"),
    props: {
      source: "subcategoria/editar",
      titulo: "SUB-CATEGORIA",
      modo: "editar",
    },
  },
  {
    path: "/subcategoria/:sub_ctg_codigo/:exe_codigo/:ug_codigo/deletar",
    component: loadC("modulos/sigmun/cadastro/SubCategoria"),
    props: {
      source: "subcategoria/deletar",
      titulo: "SUB-CATEGORIA",
      modo: "deletar",
    },
  },
  {
    path: "/subcategoria/:sub_ctg_codigo/:exe_codigo/:ug_codigo/visualizar",
    component: loadC("modulos/sigmun/cadastro/SubCategoria"),
    props: {
      source: "subcategoria/editar",
      titulo: "SUB-CATEGORIA",
      modo: "visualizar",
    },
  },

  {
    path: "/indice",
    component: load("MctDataViewer"),
    props: { source: "indice/lista", titulo: "Índice e Valor" },
  },
  {
    path: "/indice/novo",
    component: load("MctFormViewer"),
    props: { source: "indice/novo", titulo: "Índice e Valor", modo: "novo" },
  },
  {
    path: "/indice/:ind_codigo/:ind_exercicio/:ind_ug/editar",
    component: load("MctFormViewer"),
    props: {
      source: "indice/editar",
      titulo: "Índice e Valor",
      modo: "editar",
    },
  },
  {
    path: "/indice/:ind_codigo/:ind_exercicio/:ind_ug/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "indice/deletar",
      titulo: "Índice e Valor",
      modo: "deletar",
    },
  },
  {
    path: "/indice/:ind_codigo/:ind_exercicio/:ind_ug/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "indice/editar",
      titulo: "Índice e Valor",
      modo: "visualizar",
    },
  },

  {
    path: "/taxas",
    component: load("MctDataViewer"),
    props: { source: "taxas/lista", titulo: "TAXAS E SERVIÇOS" },
  },
  {
    path: "/taxas/novo",
    component: loadC("modulos/sigmun/cadastro/TaxasServicos"),
    props: { source: "taxas/novo", titulo: "TAXAS E SERVIÇOS", modo: "novo" },
  },
  {
    path: "/taxas/:codigo/editar",
    component: loadC("modulos/sigmun/cadastro/TaxasServicos"),
    props: {
      source: "taxas/editar",
      titulo: "TAXAS E SERVIÇOS",
      modo: "editar",
    },
  },
  {
    path: "/taxas/:codigo/deletar",
    component: loadC("modulos/sigmun/cadastro/TaxasServicos"),
    props: {
      source: "taxas/deletar",
      titulo: "TAXAS E SERVIÇOS",
      modo: "deletar",
    },
  },
  {
    path: "/taxas/:codigo/visualizar",
    component: loadC("modulos/sigmun/cadastro/TaxasServicos"),
    props: {
      source: "taxas/editar",
      titulo: "TAXAS E SERVIÇOS",
      modo: "visualizar",
    },
  },

  {
    path: "/inss",
    component: load("MctDataViewer"),
    props: { source: "inss/lista", titulo: "INSS" },
  },
  {
    path: "/inss/novo",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/novo", titulo: "INSS", modo: "novo" },
  },
  {
    path: "/inss/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/editar", titulo: "INSS", modo: "editar" },
  },
  {
    path: "/inss/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/deletar", titulo: "INSS", modo: "deletar" },
  },
  {
    path: "/inss/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Inss"),
    props: { source: "inss/editar", titulo: "INSS", modo: "visualizar" },
  },

  {
    path: "/irrf",
    component: load("MctDataViewer"),
    props: { source: "irrf/lista", titulo: "IRRF" },
  },
  {
    path: "/irrf/novo",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/novo", titulo: "IRRF", modo: "novo" },
  },
  {
    path: "/irrf/:codigo/editar",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/editar", titulo: "IRRF", modo: "editar" },
  },
  {
    path: "/irrf/:codigo/deletar",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/deletar", titulo: "IRRF", modo: "deletar" },
  },
  {
    path: "/irrf/:codigo/visualizar",
    component: loadC("modulos/sigrh/cadastro/Irrf"),
    props: { source: "irrf/editar", titulo: "IRRF", modo: "visualizar" },
  },

  {
    path: "/aplicreceita",
    component: load("MctDataViewer"),
    props: { source: "aplicreceita/lista", titulo: "ESPECIFICAÇÃO DA RECEITA" },
  },
  {
    path: "/aplicreceita/novo",
    component: load("MctFormViewer"),
    props: {
      source: "aplicreceita/novo",
      titulo: "ESPECIFICAÇÃO DA RECEITA",
      modo: "novo",
    },
  },
  {
    path: "/aplicreceita/:rec_codigo/:rec_ug/:rec_exercicio/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aplicreceita/editar",
      titulo: "ESPECIFICAÇÃO DA RECEITA",
      modo: "editar",
    },
  },
  {
    path: "/aplicreceita/:rec_codigo/:rec_ug/:rec_exercicio/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aplicreceita/deletar",
      titulo: "ESPECIFICAÇÃO DA RECEITA",
      modo: "deletar",
    },
  },
  {
    path: "/aplicreceita/:rec_codigo/:rec_ug/:rec_exercicio/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aplicreceita/editar",
      titulo: "ESPECIFICAÇÃO DA RECEITA",
      modo: "visualizar",
    },
  },

  {
    name: "imoveis",
    path: "/imoveis",
    component: load("MctDataViewer"),
    props: { source: "lote/lista", titulo: "Imóveis" },
  },
  {
    name: "imoveis",
    path: "/imoveis/novo",
    component: loadC("modulos/sigmun/cadastro/Imoveis"),
    props: { source: "lote/novo", titulo: "Imóveis", modo: "novo" },
  },
  {
    name: "imoveis",
    path: "/imoveis/:codigo/editar",
    component: loadC("modulos/sigmun/cadastro/Imoveis"),
    props: { source: "lote/editar", titulo: "Imóveis", modo: "editar" },
  },
  {
    name: "imoveis",
    path: "/imoveis/:codigo/deletar",
    component: loadC("modulos/sigmun/cadastro/Imoveis"),
    props: { source: "lote/deletar", titulo: "Imóveis", modo: "deletar" },
  },
  {
    name: "imoveis",
    path: "/imoveis/:codigo/visualizar",
    component: loadC("modulos/sigmun/cadastro/Imoveis"),
    props: { source: "lote/editar", titulo: "Imóveis", modo: "visualizar" },
  },

  {
    path: "/regiaofiscal",
    component: load("MctDataViewer"),
    props: {
      source: "regiaofiscal/lista",
      titulo: "REGIÕES FISCAIS / VALOR m² TERRITORIAL",
    },
  },
  {
    path: "/regiaofiscal/novo",
    component: load("MctFormViewer"),
    props: {
      source: "regiaofiscal/novo",
      titulo: "REGIÕES FISCAIS / VALOR m² TERRITORIAL",
      modo: "novo",
    },
  },
  {
    path: "/regiaofiscal/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "regiaofiscal/editar",
      titulo: "REGIÕES FISCAIS / VALOR m² TERRITORIAL",
      modo: "editar",
    },
  },
  {
    path: "/regiaofiscal/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "regiaofiscal/deletar",
      titulo: "REGIÕES FISCAIS / VALOR m² TERRITORIAL",
      modo: "deletar",
    },
  },
  {
    path: "/regiaofiscal/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "regiaofiscal/editar",
      titulo: "REGIÕES FISCAIS / VALOR m² TERRITORIAL",
      modo: "visualizar",
    },
  },

  {
    path: "/parametroreceita",
    component: load("MctDataViewer"),
    props: {
      source: "parametroreceita/lista",
      titulo: "PARÂMETROS DE IMPOSTOS",
    },
  },
  {
    path: "/parametroreceita/novo",
    component: loadC("modulos/sigmun/cadastro/ParametrosReceita"),
    props: {
      source: "parametroreceita/novo",
      titulo: "PARÂMETROS DE IMPOSTOS",
      modo: "novo",
    },
  },
  {
    path: "/parametroreceita/:prec_codigo/:prec_exe_codigo/:prec_ug_codigo/editar",
    component: loadC("modulos/sigmun/cadastro/ParametrosReceita"),
    props: {
      source: "parametroreceita/editar",
      titulo: "PARÂMETROS DE IMPOSTOS",
      modo: "editar",
    },
  },
  {
    path: "/parametroreceita/:prec_codigo/:prec_exe_codigo/:prec_ug_codigo/editar",
    component: loadC("modulos/sigmun/cadastro/ParametrosReceita"),
    props: {
      source: "parametroreceita/deletar",
      titulo: "PARÂMETROS DE IMPOSTOS",
      modo: "deletar",
    },
  },
  {
    path: "/parametroreceita/:prec_codigo/:prec_exe_codigo/:prec_ug_codigo/editar",
    component: loadC("modulos/sigmun/cadastro/ParametrosReceita"),
    props: {
      source: "parametroreceita/editar",
      titulo: "PARÂMETROS DE IMPOSTOS",
      modo: "visualizar",
    },
  },

  {
    path: "/contasbancarias",
    component: load("MctDataViewer"),
    props: { source: "contasbancarias/lista", titulo: "Contas Bancárias" },
  },
  {
    path: "/contasbancarias/novo",
    component: load("MctFormViewer"),
    props: {
      source: "contasbancarias/novo",
      titulo: "Contas Bancárias",
      modo: "novo",
    },
  },
  {
    path: "/contasbancarias/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "contasbancarias/editar",
      titulo: "Contas Bancárias",
      modo: "editar",
    },
  },
  {
    path: "/contasbancarias/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "contasbancarias/deletar",
      titulo: "Contas Bancárias",
      modo: "deletar",
    },
  },
  {
    path: "/contasbancarias/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "contasbancarias/editar",
      titulo: "Contas Bancárias",
      modo: "visualizar",
    },
  },

  /* AGUA */
  {
    path: "/aguaarrecadador",
    component: load("MctDataViewer"),
    props: { source: "aguaarrecadador/lista", titulo: "Local Arrecadador" },
  },
  {
    path: "/aguaarrecadador/novo",
    component: load("MctFormViewer"),
    props: {
      source: "aguaarrecadador/novo",
      titulo: "Local Arrecadador",
      modo: "novo",
    },
  },
  {
    path: "/aguaarrecadador/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguaarrecadador/editar",
      titulo: "Local Arrecadador",
      modo: "editar",
    },
  },
  {
    path: "/aguaarrecadador/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguaarrecadador/deletar",
      titulo: "Local Arrecadador",
      modo: "deletar",
    },
  },
  {
    path: "/aguaarrecadador/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguaarrecadador/editar",
      titulo: "Local Arrecadador",
      modo: "visualizar",
    },
  },

  {
    path: "/aguacategoria",
    component: load("MctDataViewer"),
    props: { source: "aguacategoria/lista", titulo: "Categorias" },
  },
  {
    path: "/aguacategoria/novo",
    component: load("MctFormViewer"),
    props: { source: "aguacategoria/novo", titulo: "Categorias", modo: "novo" },
  },
  {
    path: "/aguacategoria/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguacategoria/editar",
      titulo: "Categorias",
      modo: "editar",
    },
  },
  {
    path: "/aguacategoria/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguacategoria/deletar",
      titulo: "Categorias",
      modo: "deletar",
    },
  },
  {
    path: "/aguacategoria/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguacategoria/editar",
      titulo: "Categorias",
      modo: "visualizar",
    },
  },

  {
    path: "/aguafaixas",
    component: load("MctDataViewer"),
    props: { source: "aguafaixas/lista", titulo: "Faixas" },
  },
  {
    path: "/aguafaixas/novo",
    component: loadC("modulos/sigmun/agua/AguaFaixas"),
    props: { source: "aguafaixas/novo", titulo: "Faixas", modo: "novo" },
  },
  {
    path: "/aguafaixas/:agua_fax_codigo/:agua_fax_ug/:agua_fax_exercicio/:agua_ctg_codigo/editar",
    component: loadC("modulos/sigmun/agua/AguaFaixas"),
    props: { source: "aguafaixas/editar", titulo: "Faixas", modo: "editar" },
  },
  {
    path: "/aguafaixas/:agua_fax_codigo/:agua_fax_ug/:agua_fax_exercicio/:agua_ctg_codigo/deletar",
    component: loadC("modulos/sigmun/agua/AguaFaixas"),
    props: { source: "aguafaixas/deletar", titulo: "Faixas", modo: "deletar" },
  },
  {
    path: "/aguafaixas/:agua_fax_codigo/:agua_fax_ug/:agua_fax_exercicio/:agua_ctg_codigo/visualizar",
    component: loadC("modulos/sigmun/agua/AguaFaixas"),
    props: {
      source: "aguafaixas/editar",
      titulo: "Faixas",
      modo: "visualizar",
    },
  },

  {
    path: "/aguataxas",
    component: load("MctDataViewer"),
    props: { source: "aguataxas/lista", titulo: "Taxas" },
  },
  {
    path: "/aguataxas/novo",
    component: load("MctFormViewer"),
    props: { source: "aguataxas/novo", titulo: "Taxas", modo: "novo" },
  },
  {
    path: "/aguataxas/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "aguataxas/editar", titulo: "Taxas", modo: "editar" },
  },
  {
    path: "/aguataxas/:codigo/deletar",
    component: load("MctFormViewer"),
    props: { source: "aguataxas/deletar", titulo: "Taxas", modo: "deletar" },
  },
  {
    path: "/aguataxas/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: { source: "aguataxas/editar", titulo: "Taxas", modo: "visualizar" },
  },

  {
    path: "/aguareservatorio",
    component: load("MctDataViewer"),
    props: { source: "aguareservatorio/lista", titulo: "Reservatórios" },
  },
  {
    path: "/aguareservatorio/novo",
    component: load("MctFormViewer"),
    props: {
      source: "aguareservatorio/novo",
      titulo: "Reservatórios",
      modo: "novo",
    },
  },
  {
    path: "/aguareservatorio/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguareservatorio/editar",
      titulo: "Reservatórios",
      modo: "editar",
    },
  },
  {
    path: "/aguareservatorio/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguareservatorio/deletar",
      titulo: "Reservatórios",
      modo: "deletar",
    },
  },
  {
    path: "/aguareservatorio/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguareservatorio/editar",
      titulo: "Reservatórios",
      modo: "visualizar",
    },
  },

  {
    path: "/aguaparametros",
    component: loadC(
      "modulos/sigmun/agua/AguaParametrosLista"
    ) /*load('MctDataViewer')*/,
    props: {
      source: "aguaparametros/lista",
      titulo: "Lançar Parâmetros da Água",
    },
  },
  {
    path: "/aguaparametros/novo",
    component: loadC("modulos/sigmun/agua/AguaParametros"),
    props: {
      source: "aguaparametros/novo",
      titulo: "Lançar Parâmetros da Água",
      modo: "novo",
    },
  },
  {
    path: "/aguaparametros/:agua_par_ano/:agua_par_mes/:agua_par_res_codigo/editar",
    component: loadC("modulos/sigmun/agua/AguaParametros"),
    props: {
      source: "aguaparametros/editar",
      titulo: "Lançar Parâmetros da Água",
      modo: "editar",
    },
  },
  {
    path: "/aguaparametros/:agua_par_ano/:agua_par_mes/:agua_par_res_codigo/deletar",
    component: loadC("modulos/sigmun/agua/AguaParametros"),
    props: {
      source: "aguaparametros/deletar",
      titulo: "Lançar Parâmetros da Água",
      modo: "deletar",
    },
  },
  {
    path: "/aguaparametros/:agua_par_ano/:agua_par_mes/:agua_par_res_codigo/visualizar",
    component: loadC("modulos/sigmun/agua/AguaParametros"),
    props: {
      source: "aguaparametros/editar",
      titulo: "Lançar Parâmetros da Água",
      modo: "visualizar",
    },
  },

  {
    path: "/agualeituristas",
    component: load("MctDataViewer"),
    props: { source: "agualeituristas/lista", titulo: "Leituristas" },
  },
  {
    path: "/agualeituristas/novo",
    component: load("MctFormViewer"),
    props: {
      source: "agualeituristas/novo",
      titulo: "Leituristas",
      modo: "novo",
    },
  },
  {
    path: "/agualeituristas/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "agualeituristas/editar",
      titulo: "Leituristas",
      modo: "editar",
    },
  },
  {
    path: "/agualeituristas/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "agualeituristas/deletar",
      titulo: "Leituristas",
      modo: "deletar",
    },
  },
  {
    path: "/agualeituristas/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "agualeituristas/editar",
      titulo: "Leituristas",
      modo: "visualizar",
    },
  },

  {
    path: "/aguaperfilusuarios",
    component: load("MctDataViewer"),
    props: {
      source: "aguaperfilusuarios/lista",
      titulo: "Perfil dos Usuários",
    },
  },
  {
    path: "/aguaperfilusuarios/novo",
    component: load("MctFormViewer"),
    props: {
      source: "aguaperfilusuarios/novo",
      titulo: "Perfil dos Usuários",
      modo: "novo",
    },
  },
  {
    path: "/aguaperfilusuarios/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguaperfilusuarios/editar",
      titulo: "Perfil dos Usuários",
      modo: "editar",
    },
  },
  {
    path: "/aguaperfilusuarios/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguaperfilusuarios/deletar",
      titulo: "Perfil dos Usuários",
      modo: "deletar",
    },
  },
  {
    path: "/aguaperfilusuarios/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguaperfilusuarios/editar",
      titulo: "Perfil dos Usuários",
      modo: "visualizar",
    },
  },

  {
    path: "/aguamatricula",
    component: load("MctDataViewer"),
    props: { source: "aguamatricula/lista", titulo: "Matriculas" },
  },
  {
    path: "/aguamatricula/novo",
    component: loadC("modulos/sigmun/agua/AguaMatriculas"),
    props: { source: "aguamatricula/novo", titulo: "Matriculas", modo: "novo" },
  },
  {
    path: "/aguamatricula/:codigo/editar",
    component: loadC("modulos/sigmun/agua/AguaMatriculas"),
    props: {
      source: "aguamatricula/editar",
      titulo: "Matriculas",
      modo: "editar",
    },
  },
  {
    path: "/aguamatricula/:codigo/deletar",
    component: loadC("modulos/sigmun/agua/AguaMatriculas"),
    props: {
      source: "aguamatricula/deletar",
      titulo: "Matriculas",
      modo: "deletar",
    },
  },
  {
    path: "/aguamatricula/:codigo/visualizar",
    component: loadC("modulos/sigmun/agua/AguaMatriculas"),
    props: {
      source: "aguamatricula/editar",
      titulo: "Matriculas",
      modo: "visualizar",
    },
  },

  /** HIDROMETRO **/
  {
    path: "/aguacavaletes",
    component: load("MctDataViewer"),
    props: { source: "aguacavaletes/lista", titulo: "Cavalete" },
  },
  {
    path: "/aguacavaletes/novo",
    component: load("MctFormViewer"),
    props: { source: "aguacavaletes/novo", titulo: "Cavalete", modo: "novo" },
  },
  {
    path: "/aguacavaletes/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguacavaletes/editar",
      titulo: "Cavalete",
      modo: "editar",
    },
  },
  {
    path: "/aguacavaletes/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguacavaletes/deletar",
      titulo: "Cavalete",
      modo: "deletar",
    },
  },
  {
    path: "/aguacavaletes/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguacavaletes/editar",
      titulo: "Cavalete",
      modo: "visualizar",
    },
  },

  {
    path: "/aguahidrometros",
    component: load("MctDataViewer"),
    props: { source: "aguahidrometros/lista", titulo: "Hidrômetros" },
  },
  {
    path: "/aguahidrometros/novo",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidrometros/novo",
      titulo: "Hidrômetros",
      modo: "novo",
    },
  },
  {
    path: "/aguahidrometros/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidrometros/editar",
      titulo: "Hidrômetros",
      modo: "editar",
    },
  },
  {
    path: "/aguahidrometros/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidrometros/deletar",
      titulo: "Hidrômetros",
      modo: "deletar",
    },
  },
  {
    path: "/aguahidrometros/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidrometros/editar",
      titulo: "Hidrômetros",
      modo: "visualizar",
    },
  },

  {
    path: "/aguahidromarca",
    component: load("MctDataViewer"),
    props: { source: "aguahidromarca/lista", titulo: "Marcas de Hidrômetros" },
  },
  {
    path: "/aguahidromarca/novo",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromarca/novo",
      titulo: "Marcas de Hidrômetros",
      modo: "novo",
    },
  },
  {
    path: "/aguahidromarca/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromarca/editar",
      titulo: "Marcas de Hidrômetros",
      modo: "editar",
    },
  },
  {
    path: "/aguahidromarca/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromarca/deletar",
      titulo: "Marcas de Hidrômetros",
      modo: "deletar",
    },
  },
  {
    path: "/aguahidromarca/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromarca/editar",
      titulo: "Marcas de Hidrômetros",
      modo: "visualizar",
    },
  },

  {
    path: "/aguahidromodelo",
    component: load("MctDataViewer"),
    props: {
      source: "aguahidromodelo/lista",
      titulo: "Modelos de Hidrômetros",
    },
  },
  {
    path: "/aguahidromodelo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromodelo/novo",
      titulo: "Modelos de Hidrômetros",
      modo: "novo",
    },
  },
  {
    path: "/aguahidromodelo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromodelo/editar",
      titulo: "Modelos de Hidrômetros",
      modo: "editar",
    },
  },
  {
    path: "/aguahidromodelo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromodelo/deletar",
      titulo: "Modelos de Hidrômetros",
      modo: "deletar",
    },
  },
  {
    path: "/aguahidromodelo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "aguahidromodelo/editar",
      titulo: "Modelos de Hidrômetros",
      modo: "visualizar",
    },
  },

  {
    path: "/hidrometrosituacao",
    component: load("MctDataViewer"),
    props: {
      source: "hidrometrosituacao/lista",
      titulo: "Situação dos Hidrômetros",
    },
  },
  {
    path: "/hidrometrosituacao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "hidrometrosituacao/novo",
      titulo: "Situação dos Hidrômetros",
      modo: "novo",
    },
  },
  {
    path: "/hidrometrosituacao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "hidrometrosituacao/editar",
      titulo: "Situação dos Hidrômetros",
      modo: "editar",
    },
  },
  {
    path: "/hidrometrosituacao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "hidrometrosituacao/deletar",
      titulo: "Situação dos Hidrômetros",
      modo: "deletar",
    },
  },
  {
    path: "/hidrometrosituacao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "hidrometrosituacao/editar",
      titulo: "Situação dos Hidrômetros",
      modo: "visualizar",
    },
  },

  {
    path: "/hidrometromanutencao",
    component: load("MctDataViewer"),
    props: {
      source: "hidrometromanutencao/lista",
      titulo: "Manutenção de Hidrômetros",
    },
  },
  {
    path: "/hidrometromanutencao/novo",
    component: loadC("modulos/sigmun/agua/AguaManutencao"),
    props: {
      source: "hidrometromanutencao/novo",
      titulo: "Manutenção de Hidrômetros",
      modo: "novo",
    },
  },
  {
    path: "/hidrometromanutencao/:codigo/editar",
    component: loadC("modulos/sigmun/agua/AguaManutencao"),
    props: {
      source: "hidrometromanutencao/editar",
      titulo: "Manutenção de Hidrômetros",
      modo: "editar",
    },
  },
  {
    path: "/hidrometromanutencao/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "hidrometromanutencao/deletar",
      titulo: "Manutenção de Hidrômetros",
      modo: "deletar",
    },
  },
  {
    path: "/hidrometromanutencao/:codigo/visualizar",
    component: loadC("modulos/sigmun/agua/AguaManutencao"),
    props: {
      source: "hidrometromanutencao/editar",
      titulo: "Manutenção de Hidrômetros",
      modo: "visualizar",
    },
  },

  /* LEITURAS E FATURAS */
  {
    path: "/agualeituras",
    component: loadC("modulos/sigmun/agua/AguaLeituras"),
    props: {
      source: "aguafaturas",
      titulo: "Lançar Leitura de Hidrômetro",
      modo: "editar",
    },
  },
  {
    path: "/aguacalculafaturas",
    component: loadC("modulos/sigmun/agua/AguaCalculaFaturas"),
    props: { source: "aguafaturas", titulo: "Calcular Fatura(s)" },
  },
  /*{ path: '/agualeituristas/novo', component: load('MctFormViewer'), props: {source:"agualeituristas/novo",titulo:"Leituristas",modo:'novo'} },
    { path: '/agualeituristas/:codigo/editar', component: load('MctFormViewer'), props: {source:"agualeituristas/editar",titulo:"Leituristas",modo:'editar'} },
    { path: '/agualeituristas/:codigo/deletar', component: load('MctFormViewer'), props: {source:"agualeituristas/deletar",titulo:"Leituristas",modo:'deletar'} },
    { path: '/agualeituristas/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"agualeituristas/editar",titulo:"Leituristas",modo:'visualizar'} },*/

  /* ALVARA */
  {
    path: "/geraralvara",
    component: loadC("modulos/sigmun/alvara/GerarAlvara"),
    props: { source: "geraralvara/novo", titulo: "Gerar Alvará", modo: "novo" },
  },
  /*{ path: '/geraralvara/novo', component: loadC('modulos/sigmun/alvara/GerarAlvara'), props: {source:"geraralvara/novo",titulo:"Gerar Alvará",modo:'novo'} },
    { path: '/geraralvara/:codigo/editar', component: loadC('modulos/sigmun/alvara/GerarAlvara'), props: {source:"geraralvara/editar",titulo:"Gerar Alvará",modo:'editar'} },
    { path: '/geraralvara/:codigo/deletar', component: loadC('modulos/sigmun/alvara/GerarAlvara'), props: {source:"geraralvara/deletar",titulo:"Gerar Alvará",modo:'deletar'} },
    { path: '/geraralvara/:codigo/visualizar', component:loadC('modulos/sigmun/alvara/GerarAlvara'), props: {source:"geraralvara/editar",titulo:"Gerar Alvará",modo:'visualizar'} },*/

  {
    path: "/consultaralvara",
    component: load("MctDataViewerSBF"),
    props: { source: "consultaralvara/lista", titulo: "Consultar Alvará" },
  },
  //{ path: '/consultaralvara/novo', component: loadC('modulos/sigmun/alvara/ConsultarAlvara'), props: {source:"consultaralvara/novo", titulo:"Consultar Alvará", modo:'novo'} },
  {
    path: "/consultaralvara/:alv_codigo/:alv_exercicio/:alv_ug/editar",
    component: loadC("modulos/sigmun/alvara/ConsultarAlvara"),
    props: {
      source: "consultaralvara/editar",
      titulo: "Consultar Alvará",
      modo: "editar",
    },
  },
  {
    path: "/consultaralvara/:alv_codigo/:alv_exercicio/:alv_ug/deletar",
    component: loadC("modulos/sigmun/alvara/ConsultarAlvara"),
    props: {
      source: "consultaralvara/deletar",
      titulo: "Consultar Alvará",
      modo: "deletar",
    },
  },
  {
    path: "/consultaralvara/:alv_codigo/:alv_exercicio/:alv_ug/visualizar",
    component: loadC("modulos/sigmun/alvara/ConsultarAlvara"),
    props: {
      source: "consultaralvara/editar",
      titulo: "Consultar Alvará",
      modo: "visualizar",
    },
  },

  /* DIVIDA ATIVA */
  {
    path: "/dividaativa",
    component: load("MctDataViewer"),
    props: {
      source: "dividaativa/lista",
      titulo: "Lançamento de Dívida Ativa - Manual",
    },
  },
  {
    path: "/dividaativa/novo",
    component: loadC("modulos/sigmun/dividaativa/DividaAtiva"),
    props: {
      source: "dividaativa/novo",
      titulo: "Lançamento de Dívida Ativa - Manual",
      modo: "novo",
    },
  },
  {
    path: "/dividaativa/:divida_codigo/:divida_exercicio/:divida_ug/editar",
    component: loadC("modulos/sigmun/dividaativa/DividaAtiva"),
    props: {
      source: "dividaativa/editar",
      titulo: "Lançamento de Dívida Ativa - Manual",
      modo: "editar",
    },
  },
  {
    path: "/dividaativa/:divida_codigo/:divida_exercicio/:divida_ug/deletar",
    component: loadC("modulos/sigmun/dividaativa/DividaAtiva"),
    props: {
      source: "dividaativa/deletar",
      titulo: "Lançamento de Dívida Ativa - Manual",
      modo: "deletar",
    },
  },
  {
    path: "/dividaativa/:divida_codigo/:divida_exercicio/:divida_ug/visualizar",
    component: loadC("modulos/sigmun/dividaativa/DividaAtiva"),
    props: {
      source: "dividaativa/editar",
      titulo: "Lançamento de Dívida Ativa - Manual",
      modo: "visualizar",
    },
  },

  {
    path: "/dividaativaautomatica",
    component: loadC("modulos/sigmun/dividaativa/DividaAtivaAutomatica"),
    props: { titulo: "Lançamento de Dívida Ativa - Automático" },
  },

  {
    path: "/dividaativaparcelamento",
    component: loadC("modulos/sigmun/dividaativa/DividaAtivaParcelamento"),
    props: { titulo: "Parcelamento de Dívida Ativa" },
  },

  {
    path: "/dividaativaatualiza",
    component: loadC("modulos/sigmun/dividaativa/DividaAtivaAtualiza"),
    props: { titulo: "Atualiza Parcelamento de Dívida Ativa" },
  },

  {
    path: "/dividaativacancelamento",
    component: loadC("modulos/sigmun/dividaativa/DividaAtivaAtualiza"),
    props: { titulo: "Cancelamento de Dívida Ativa" },
  },

  // I.T.B.I.
  {
    path: "/itbi",
    component: load("MctDataViewer"),
    props: {
      source: "itbi/lista",
      titulo: "Gerar Guia de I.T.B.I. de Imóvel Rural ou Urbano",
    },
  },
  {
    path: "/itbi/novo",
    component: loadC("modulos/sigmun/ITBI/GuiaItbi"),
    props: {
      source: "itbi/novo",
      titulo: "Gerar Guia de I.T.B.I. de Imóvel Rural ou Urbano",
      modo: "novo",
    },
  },
  {
    path: "/itbi/:itbi_codigo/:itbi_exercicio/:itbi_ug/editar",
    component: loadC("modulos/sigmun/ITBI/GuiaItbi"),
    props: {
      source: "itbi/editar",
      titulo: "Gerar Guia de I.T.B.I. de Imóvel Rural ou Urbano",
      modo: "editar",
    },
  },
  {
    path: "/itbi/:itbi_codigo/:itbi_exercicio/:itbi_ug/deletar",
    component: loadC("modulos/sigmun/ITBI/GuiaItbi"),
    props: {
      source: "itbi/deletar",
      titulo: "Gerar Guia de I.T.B.I. de Imóvel Rural ou Urbano",
      modo: "deletar",
    },
  },
  {
    path: "/itbi/:itbi_codigo/:itbi_exercicio/:itbi_ug/visualizar",
    component: loadC("modulos/sigmun/ITBI/GuiaItbi"),
    props: {
      source: "itbi/editar",
      titulo: "Gerar Guia de I.T.B.I. de Imóvel Rural ou Urbano",
      modo: "visualizar",
    },
  },

  /* I.P.T.U */

  {
    path: "/dadosgrp",
    component: load("MctDataViewer"),
    props: { source: "dadosgrupo/lista", titulo: "Grupo de Dados" },
  },
  {
    path: "/dadosgrp/novo",
    component: load("MctFormViewer"),
    props: {
      source: "dadosgrupo/novo",
      titulo: "Grupo de Dados",
      modo: "novo",
    },
  },
  {
    path: "/dadosgrp/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "dadosgrupo/editar",
      titulo: "Grupo de Dados",
      modo: "editar",
    },
  },
  {
    path: "/dadosgrp/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "dadosgrupo/deletar",
      titulo: "Grupo de Dados",
      modo: "deletar",
    },
  },
  {
    path: "/dadosgrp/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "dadosgrupo/editar",
      titulo: "Grupo de Dados",
      modo: "visualizar",
    },
  },

  {
    path: "/dadossubgrp",
    component: load("MctDataViewer"),
    props: { source: "dadossubgrupo/lista", titulo: "SubGrupo de Dados" },
  },
  {
    path: "/dadossubgrp/novo",
    component: load("MctFormViewer"),
    props: {
      source: "dadossubgrupo/novo",
      titulo: "SubGrupo de Dados",
      modo: "novo",
    },
  },
  {
    path: "/dadossubgrp/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "dadossubgrupo/editar",
      titulo: "SubGrupo de Dados",
      modo: "editar",
    },
  },
  {
    path: "/dadossubgrp/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "dadossubgrupo/deletar",
      titulo: "SubGrupo de Dados",
      modo: "deletar",
    },
  },
  {
    path: "/dadossubgrp/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "dadossubgrupo/editar",
      titulo: "SubGrupo de Dados",
      modo: "visualizar",
    },
  },

  {
    path: "/categoriaimovel",
    component: load("MctDataViewer"),
    props: { source: "categoriaimovel/lista", titulo: "Categoria de Imóvel" },
  },
  {
    path: "/categoriaimovel/novo",
    component: loadC("modulos/sigmun/IPTU/CategoriasImoveis"),
    props: {
      source: "categoriaimovel/novo",
      titulo: "Categoria de Imóvel",
      modo: "novo",
    },
  },
  {
    path: "/categoriaimovel/:codigo/editar",
    component: loadC("modulos/sigmun/IPTU/CategoriasImoveis"),
    props: {
      source: "categoriaimovel/editar",
      titulo: "Categoria de Imóvel",
      modo: "editar",
    },
  },
  {
    path: "/categoriaimovel/:codigo/deletar",
    component: loadC("modulos/sigmun/IPTU/CategoriasImoveis"),
    props: {
      source: "categoriaimovel/deletar",
      titulo: "Categoria de Imóvel",
      modo: "deletar",
    },
  },
  {
    path: "/categoriaimovel/:codigo/visualizar",
    component: loadC("modulos/sigmun/IPTU/CategoriasImoveis"),
    props: {
      source: "categoriaimovel/editar",
      titulo: "Categoria de Imóvel",
      modo: "visualizar",
    },
  },

  {
    path: "/tplanc",
    component: load("MctDataViewer"),
    props: { source: "tplancamento/lista", titulo: "Tipos de Lançamento" },
  },
  {
    path: "/tplanc/novo",
    component: load("MctFormViewer"),
    props: {
      source: "tplancamento/novo",
      titulo: "Tipos de Lançamento",
      modo: "novo",
    },
  },
  {
    path: "/tplanc/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "tplancamento/editar",
      titulo: "Tipos de Lançamento",
      modo: "editar",
    },
  },
  {
    path: "/tplanc/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "tplancamento/deletar",
      titulo: "Tipos de Lançamento",
      modo: "deletar",
    },
  },
  {
    path: "/tplanc/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "tplancamento/editar",
      titulo: "Tipos de Lançamento",
      modo: "visualizar",
    },
  },

  {
    path: "/icnt",
    component: load("MctDataViewer"),
    props: {
      source: "icnt/lista",
      titulo: "Indice de Valores do M² da Construção",
    },
  },
  {
    path: "/icnt/novo",
    component: load("MctFormViewer"),
    props: {
      source: "icnt/novo",
      titulo: "Indice de Valores do M² da Construção",
      modo: "novo",
    },
  },
  {
    path: "/icnt/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "icnt/editar",
      titulo: "Indice de Valores do M² da Construção",
      modo: "editar",
    },
  },
  {
    path: "/icnt/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "icnt/deletar",
      titulo: "Indice de Valores do M² da Construção",
      modo: "deletar",
    },
  },
  {
    path: "/icnt/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "icnt/editar",
      titulo: "Indice de Valores do M² da Construção",
      modo: "visualizar",
    },
  },

  {
    path: "/iptuparcelamento",
    component: load("MctDataViewer"),
    props: {
      source: "iptuparcelamento/lista",
      titulo: "Parâmetros para Parcelameto do IPTU",
    },
  },
  {
    path: "/iptuparcelamento/novo",
    component: loadC("modulos/sigmun/IPTU/IptuParcelamento"),
    props: {
      source: "iptuparcelamento/novo",
      titulo: "Parâmetros para Parcelameto do IPTU",
      modo: "novo",
    },
  },
  {
    path: "/iptuparcelamento/:codigo/editar",
    component: loadC("modulos/sigmun/IPTU/IptuParcelamento"),
    props: {
      source: "iptuparcelamento/editar",
      titulo: "Parâmetros para Parcelameto do IPTU",
      modo: "editar",
    },
  },
  {
    path: "/iptuparcelamento/:codigo/deletar",
    component: loadC("modulos/sigmun/IPTU/IptuParcelamento"),
    props: {
      source: "iptuparcelamento/deletar",
      titulo: "Parâmetros para Parcelameto do IPTU",
      modo: "deletar",
    },
  },
  {
    path: "/iptuparcelamento/:codigo/visualizar",
    component: loadC("modulos/sigmun/IPTU/IptuParcelamento"),
    props: {
      source: "iptuparcelamento/editar",
      titulo: "Parâmetros para Parcelameto do IPTU",
      modo: "visualizar",
    },
  },

  // I.S.S.Q.N.
  {
    path: "/issqn",
    component: load("MctDataViewer"),
    props: { source: "issqnpercentual/lista", titulo: "Percentual de ISSQN" },
  },
  {
    path: "/issqn/novo",
    component: loadC("modulos/sigmun/ISSQN/GerarPercentualPorServico"),
    props: {
      source: "issqnpercentual/novo",
      titulo: "Percentual de ISSQN",
      modo: "novo",
    },
  },
  {
    path: "/issqn/:codigo/editar",
    component: loadC("modulos/sigmun/ISSQN/GerarPercentualPorServico"),
    props: {
      source: "issqnpercentual/editar",
      titulo: "Percentual de ISSQN",
      modo: "editar",
    },
  },
  {
    path: "/issqn/:codigo/deletar",
    component: loadC("modulos/sigmun/ISSQN/GerarPercentualPorServico"),
    props: {
      source: "issqnpercentual/deletar",
      titulo: "Percentual de ISSQN",
      modo: "deletar",
    },
  },
  {
    path: "/issqn/:codigo/visualizar",
    component: loadC("modulos/sigmun/ISSQN/GerarPercentualPorServico"),
    props: {
      source: "issqnpercentual/editar",
      titulo: "Percentual de ISSQN",
      modo: "visualizar",
    },
  },

  {
    path: "/issqnautonomo",
    component: load("MctDataViewer"),
    props: { source: "issqnaut/lista", titulo: "Valor ISSQN para Autônomos" },
  },
  {
    path: "/issqnautonomo/novo",
    component: load("MctFormViewer"),
    props: {
      source: "issqnaut/novo",
      titulo: "Valor ISSQN para Autônomos",
      modo: "novo",
    },
  },
  {
    path: "/issqnautonomo/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnaut/editar",
      titulo: "Valor ISSQN para Autônomos",
      modo: "editar",
    },
  },
  {
    path: "/issqnautonomo/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnaut/deletar",
      titulo: "Valor ISSQN para Autônomos",
      modo: "deletar",
    },
  },
  {
    path: "/issqnautonomo/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnaut/editar",
      titulo: "Valor ISSQN para Autônomos",
      modo: "visualizar",
    },
  },

  {
    path: "/issqnfatestimativa",
    component: load("MctDataViewer"),
    props: {
      source: "issqnfatestimativa/lista",
      titulo: "Lançamento de Faturamento - Estimativa",
    },
  },
  {
    path: "/issqnfatestimativa/novo",
    component: loadC("modulos/sigmun/ISSQN/LancamentoEstimativa"),
    props: {
      source: "issqnfatestimativa/novo",
      titulo: "Lançamento de Faturamento - Estimativa",
      modo: "novo",
    },
  },
  {
    path: "/issqnfatestimativa/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnfatestimativa/editar",
      titulo: "Lançamento de Faturamento - Estimativa",
      modo: "editar",
    },
  },
  {
    path: "/issqnfatestimativa/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnfatestimativa/deletar",
      titulo: "Lançamento de Faturamento - Estimativa",
      modo: "deletar",
    },
  },
  {
    path: "/issqnfatestimativa/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnfatestimativa/editar",
      titulo: "Lançamento de Faturamento - Estimativa",
      modo: "visualizar",
    },
  },

  {
    path: "/issqnfatnf",
    component: load("MctDataViewer"),
    props: {
      source: "issqnfatnf/lista",
      titulo: "Lançamento de Faturamento NF",
    },
  },
  {
    path: "/issqnfatnf/novo",
    component: loadC("modulos/sigmun/ISSQN/LancamentoNF"),
    props: {
      source: "issqnfatnf/novo",
      titulo: "Lançamento de Faturamento NF",
      modo: "novo",
    },
  },
  {
    path: "/issqnfatnf/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnfatnf/editar",
      titulo: "Lançamento de Faturamento NF",
      modo: "editar",
    },
  },
  {
    path: "/issqnfatnf/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnfatnf/deletar",
      titulo: "Lançamento de Faturamento NF",
      modo: "deletar",
    },
  },
  {
    path: "/issqnfatnf/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "issqnfatnf/editar",
      titulo: "Lançamento de Faturamento NF",
      modo: "visualizar",
    },
  },

  // issautonomo
  {
    path: "/issautonomo",
    component: loadC("modulos/sigmun/ISSQN/IssAnualAutonomo"),
    props: { titulo: "I.S.S. Anual Autônomo", modo: "novo" },
  },

  // nota fiscal
  {
    path: "/notasfiscais",
    component: load("MctDataViewer"),
    props: {
      source: "notasfiscais/lista",
      titulo: "Notas Fiscais",
      modo: "visualizar",
    },
  },
  {
    path: "/notasfiscais/novo",
    component: loadC("modulos/sigmun/notaFiscal/EmitirNotaFiscal"),
    props: {
      source: "notasfiscais/novo",
      titulo: "Notas Fiscais",
      modo: "novo",
    },
  },

  // taxas diversas
  {
    path: "/taxasdiversas",
    component: load("MctDataViewer"),
    props: { source: "taxasdiversas/lista", titulo: "Taxas Diversas" },
  },
  {
    path: "/taxasdiversas/novo",
    component: loadC("modulos/sigmun/taxasDiversas/TaxasDiversas"),
    props: {
      source: "taxasdiversas/novo",
      titulo: "Taxas Diversas",
      modo: "novo",
    },
  },
  {
    path: "/taxasdiversas/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "taxasdiversas/editar",
      titulo: "Taxas Diversas",
      modo: "editar",
    },
  },
  {
    path: "/taxasdiversas/:codigo/deletar",
    component: load("MctFormViewer"),
    props: {
      source: "taxasdiversas/deletar",
      titulo: "Taxas Diversas",
      modo: "deletar",
    },
  },
  {
    path: "/taxasdiversas/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "taxasdiversas/editar",
      titulo: "Taxas Diversas",
      modo: "visualizar",
    },
  },

  // receitas
  {
    path: "/certidaonegativa",
    component: load("MctDataViewer"),
    props: {
      source: "certidaonegativa/lista",
      titulo: "Certidão Negativa de Débitos",
    },
  },
  {
    path: "/certidaonegativa/novo",
    component: loadC("modulos/sigmun/receitas/CertidaoNegativa"),
    props: {
      source: "certidaonegativa/novo",
      titulo: "Certidão Negativa de Débitos",
      modo: "novo",
    },
  },
  {
    path: "/certidaonegativa/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "certidaonegativa/editar",
      titulo: "Certidão Negativa de Débitos",
      modo: "editar",
    },
  },
  {
    path: "/certidaonegativa/:codigo/deletar",
    component: loadC("modulos/sigmun/receitas/CertidaoNegativa"),
    props: {
      source: "certidaonegativa/deletar",
      titulo: "Certidão Negativa de Débitos",
      modo: "deletar",
    },
  },
  {
    path: "/certidaonegativa/:codigo/visualizar",
    component: loadC("modulos/sigmun/receitas/CertidaoNegativa"),
    props: {
      source: "certidaonegativa/editar",
      titulo: "Certidão Negativa de Débitos",
      modo: "visualizar",
    },
  },

  {
    path: "/consultadam",
    component: loadC("modulos/sigmun/receitas/ConsultaDam"),
    props: {
      source: "consultadam/novo",
      titulo: "Consulta Através da D.A.M.",
      modo: "novo",
    },
  },

  {
    path: "/extratodebito",
    component: loadC("modulos/sigmun/receitas/ExtratosDebitos"),
    props: {
      source: "extratodebito/visualizar",
      titulo: "Extrato de Débitos",
      modo: "novo",
    },
  },

  // relatórios
  {
    path: "/listagemprofissao",
    component: loadC("modulos/sigmun/relatorios/listagens/Profissao"),
    props: {
      source: "extratodebito/visualizar",
      titulo: "Relatório",
      modo: "novo",
    },
  },
  {
    path: "/relatoriodam",
    component: loadC(
      "modulos/sigmun/relatorios/documentosEmitidos/ArrecadacaoMunicipal"
    ),
    props: {
      source: "relatoriodam/visualizar",
      titulo: "Relatório",
      modo: "novo",
    },
  },
  {
    path: "/relatorionfprestacao",
    component: loadC(
      "modulos/sigmun/relatorios/documentosEmitidos/NFPrestacaoServico"
    ),
    props: {
      source: "relatorionfprestacao/visualizar",
      titulo: "Relatório",
      modo: "novo",
    },
  },
  {
    path: "/relatorionfemitidas",
    component: loadC("modulos/sigmun/relatorios/documentosEmitidos/NFEmitidas"),
    props: {
      source: "relatorionfemitidas/visualizar",
      titulo: "Relatório",
      modo: "novo",
    },
  },
  {
    path: "/relatoriocontas",
    component: loadC("modulos/sigmun/relatorios/listagens/Contas"),
    props: {
      source: "relatoriocontas/visualizar",
      titulo: "Relatório",
      modo: "novo",
    },
  },
  {
    path: "/relatoriorecebpend",
    component: loadC("modulos/sigmun/relatorios/receitas/RecebidosEPendentes"),
    props: {
      source: "relatoriorecebpend/visualizar",
      titulo: "RELATÓRIO RECEITAS - RECEBIDOS E PENDENTES",
      modo: "novo",
    },
  },
];
