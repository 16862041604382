import * as MutationTypes from './MutationTypes';

const state = {    
    showInfos: true,
};

const mutations = {       
    [MutationTypes.CONFIG_INFOS](state) {
        state.showInfos = !state.showInfos
    },    
};

const actions = {
    [MutationTypes.CONFIG_INFOS]({commit}) {
        commit(MutationTypes.CONFIG_INFOS);
    },
};

const getters = {
    showInfos: state => state.showInfos,
};

export default{
    namespaced: true,
    state, 
    actions, 
    mutations, 
    getters
}