function load (component) {
  return () => import(`../components/comum/${component}.vue`)
}

function loadC (component) {
  return () => import(`../components/${component}.vue`)
}
  
  export default [
    { path:'/orgao', name: 'Orgão', component: load('MctDataViewer'), props: {source:"orgao/lista",titulo:"Orgão"}},
    { path: '/orgao/novo', component: load('MctFormViewer'), props: {source:"orgao/novo",titulo:"Orgão",modo:'novo'} },
    { path: '/orgao/:org_codigo/:ug_codigo/:exe_codigo/editar', component: load('MctFormViewer'), props: {source:"orgao/editar",titulo:"Orgão",modo:'editar'} },
    { path: '/orgao/:org_codigo/:ug_codigo/:exe_codigo/deletar', component: load('MctFormViewer'), props: {source:"orgao/deletar",titulo:"Orgão",modo:'deletar'} },
    { path: '/orgao/:org_codigo/:ug_codigo/:exe_codigo/visualizar', component: load('MctFormViewer'), props: {source:"orgao/editar",titulo:"Orgão",modo:'visualizar'} },

    { path:'/uniorcamentarias', component: load('MctDataViewer'), props: {source:"uniorcamentarias/lista",titulo:"Unidade Orçamentária"}},
    { path: '/uniorcamentarias/novo', component: loadC('modulos/sigcont/cadastro/UniOrcamentarias'), props: {source:"uniorcamentarias/novo",titulo:"Unidade Orçamentária",modo:'novo'} },
    { path: '/uniorcamentarias/:uni_codigo/:org_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/cadastro/UniOrcamentarias'), props: {source:"uniorcamentarias/editar",titulo:"Unidade Orçamentária",modo:'editar'} },
    { path: '/uniorcamentarias/:uni_codigo/:org_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/cadastro/UniOrcamentarias'), props: {source:"uniorcamentarias/deletar",titulo:"Unidade Orçamentária",modo:'deletar'} },
    { path: '/uniorcamentarias/:uni_codigo/:org_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/cadastro/UniOrcamentarias'), props: {source:"uniorcamentarias/editar",titulo:"Unidade Orçamentária",modo:'visualizar'} },

    { path:'/funcao', component: load('MctDataViewer'), props: {source:"funcao/lista",titulo:"Funções"}},
    { path: '/funcao/novo', component: load('MctFormViewer'), props: {source:"funcao/novo",titulo:"Funções",modo:'novo'} },
    { path: '/funcao/:func_codigo/:ug_codigo/:exe_codigo/editar', component: load('MctFormViewer'), props: {source:"funcao/editar",titulo:"Funções",modo:'editar'} },
    { path: '/funcao/:func_codigo/:ug_codigo/:exe_codigo/deletar', component: load('MctFormViewer'), props: {source:"funcao/deletar",titulo:"Funções",modo:'deletar'} },
    { path: '/funcao/:func_codigo/:ug_codigo/:exe_codigo/visualizar', component: load('MctFormViewer'), props: {source:"funcao/editar",titulo:"Funções",modo:'visualizar'} },

    { path:'/subfuncao', component: load('MctDataViewer'), props: {source:"subfuncao/lista",titulo:"Sub-Funções"}},
    { path: '/subfuncao/novo', component: load('MctFormViewer'), props: {source:"subfuncao/novo",titulo:"Sub-Funções",modo:'novo'} },
    { path: '/subfuncao/:sfunc_codigo/:ug_codigo/:exe_codigo/editar', component: load('MctFormViewer'), props: {source:"subfuncao/editar",titulo:"Sub-Funções",modo:'editar'} },
    { path: '/subfuncao/:sfunc_codigo/:ug_codigo/:exe_codigo/deletar', component: load('MctFormViewer'), props: {source:"subfuncao/deletar",titulo:"Sub-Funções",modo:'deletar'} },
    { path: '/subfuncao/:sfunc_codigo/:ug_codigo/:exe_codigo/visualizar', component: load('MctFormViewer'), props: {source:"subfuncao/editar",titulo:"Sub-Funções",modo:'visualizar'} },

    { path:'/fonterecurso', component: load('MctDataViewer'), props: {source:"fonterecurso/lista",titulo:"Fonte de Recursos"}},
    { path: '/fonterecurso/novo', component: loadC('modulos/sigcont/cadastro/FonteRecurso'), props: {source:"fonterecurso/novo",titulo:"Fonte de Recursos",modo:'novo'} },
    { path: '/fonterecurso/:fon_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/cadastro/FonteRecurso'), props: {source:"fonterecurso/editar",titulo:"Fonte de Recursos",modo:'editar'} },
    { path: '/fonterecurso/:fon_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/cadastro/FonteRecurso'), props: {source:"fonterecurso/deletar",titulo:"Fonte de Recursos",modo:'deletar'} },
    { path: '/fonterecurso/:fon_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/cadastro/FonteRecurso'), props: {source:"fonterecurso/editar",titulo:"Fonte de Recursos",modo:'visualizar'} },

    { path:'/fundos', component: load('MctDataViewer'), props: {source:"fundos/lista",titulo:"Fundos"}},
    { path: '/fundos/novo', component: load('MctFormViewer'), props: {source:"fundos/novo",titulo:"Fundos",modo:'novo'} },
    { path: '/fundos/:fun_codigo/:ug_codigo/:exe_codigo/editar', component: load('MctFormViewer'), props: {source:"fundos/editar",titulo:"Fundos",modo:'editar'} },
    { path: '/fundos/:fun_codigo/:ug_codigo/:exe_codigo/deletar', component: load('MctFormViewer'), props: {source:"fundos/deletar",titulo:"Fundos",modo:'deletar'} },
    { path: '/fundos/:fun_codigo/:ug_codigo/:exe_codigo/visualizar', component: load('MctFormViewer'), props: {source:"fundos/editar",titulo:"Fundos",modo:'visualizar'} },
    
    { path:'/programas', component: load('MctDataViewer'), props: {source:"programas/lista",titulo:"Programas"}},
    { path: '/programas/novo', component: load('MctFormViewer'), props: {source:"programas/novo",titulo:"Programas",modo:'novo'} },
    { path: '/programas/:prg_codigo/:ug_codigo/:exe_codigo/editar', component: load('MctFormViewer'), props: {source:"programas/editar",titulo:"Programas",modo:'editar'} },
    { path: '/programas/:prg_codigo/:ug_codigo/:exe_codigo/deletar', component: load('MctFormViewer'), props: {source:"programas/deletar",titulo:"Programas",modo:'deletar'} },
    { path: '/programas/:prg_codigo/:ug_codigo/:exe_codigo/visualizar', component: load('MctFormViewer'), props: {source:"programas/editar",titulo:"Programas",modo:'visualizar'} },

    { path:'/projetos', component: load('MctDataViewer'), props: {source:"projetos/lista",titulo:"Projetos"}},
    { path: '/projetos/novo', component: load('MctFormViewer'), props: {source:"projetos/novo",titulo:"Projetos",modo:'novo'} },
    { path: '/projetos/:prj_codigo/:ug_codigo/:exe_codigo/editar', component: load('MctFormViewer'), props: {source:"projetos/editar",titulo:"Projetos",modo:'editar'} },
    { path: '/projetos/:prj_codigo/:ug_codigo/:exe_codigo/deletar', component: load('MctFormViewer'), props: {source:"projetos/deletar",titulo:"Projetos",modo:'deletar'} },
    { path: '/projetos/:prj_codigo/:ug_codigo/:exe_codigo/visualizar', component: load('MctFormViewer'), props: {source:"projetos/editar",titulo:"Projetos",modo:'visualizar'} },

    { path:'/produtoprojetos', component: load('MctDataViewer'), props: {source:"produtoprojetos/lista",titulo:"Produtos (Projeto)"}},
    { path: '/produtoprojetos/novo', component: load('MctFormViewer'), props: {source:"produtoprojetos/novo",titulo:"Produtos (Projeto)",modo:'novo'} },
    { path: '/produtoprojetos/:pprj_codigo/:ug_codigo/:exe_codigo/editar', component: load('MctFormViewer'), props: {source:"produtoprojetos/editar",titulo:"Produtos (Projeto)",modo:'editar'} },
    { path: '/produtoprojetos/:pprj_codigo/:ug_codigo/:exe_codigo/deletar', component: load('MctFormViewer'), props: {source:"produtoprojetos/deletar",titulo:"Produtos (Projeto)",modo:'deletar'} },
    { path: '/produtoprojetos/:pprj_codigo/:ug_codigo/:exe_codigo/visualizar', component: load('MctFormViewer'), props: {source:"produtoprojetos/editar",titulo:"Produtos (Projeto)",modo:'visualizar'} },

    { path:'/aplicreceita', component: load('MctDataViewer'), props: {source:"aplicreceita/lista",titulo:"Receitas (APLIC)"}},
    { path: '/aplicreceita/novo', component: loadC('modulos/sigcont/cadastro/Receita'), props: {source:"aplicreceita/novo",titulo:"Receitas (APLIC)",modo:'novo'} },
    { path: '/aplicreceita/:rec_codigo/:rec_ug/:rec_exercicio/editar', component: loadC('modulos/sigcont/cadastro/Receita'), props: {source:"aplicreceita/editar",titulo:"Receitas (APLIC)",modo:'editar'} },
    { path: '/aplicreceita/:rec_codigo/:rec_ug/:rec_exercicio/deletar', component: loadC('modulos/sigcont/cadastro/Receita'), props: {source:"aplicreceita/deletar",titulo:"Receitas (APLIC)",modo:'deletar'} },
    { path: '/aplicreceita/:rec_codigo/:rec_ug/:rec_exercicio/visualizar', component: loadC('modulos/sigcont/cadastro/Receita'), props: {source:"aplicreceita/editar",titulo:"Receitas (APLIC)",modo:'visualizar'} },

    { path:'/aplicdespesa', component: load('MctDataViewer'), props: {source:"aplicdespesa/lista",titulo:"Despesas (APLIC)"}},
    { path: '/aplicdespesa/novo', component: loadC('modulos/sigcont/cadastro/Despesa'), props: {source:"aplicdespesa/novo",titulo:"Despesas (APLIC)",modo:'novo'} },
    { path: '/aplicdespesa/:des_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/cadastro/Despesa'), props: {source:"aplicdespesa/editar",titulo:"Despesas (APLIC)",modo:'editar'} },
    { path: '/aplicdespesa/:des_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/cadastro/Despesa'), props: {source:"aplicdespesa/deletar",titulo:"Despesas (APLIC)",modo:'deletar'} },
    { path: '/aplicdespesa/:des_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/cadastro/Despesa'), props: {source:"aplicdespesa/editar",titulo:"Despesas (APLIC)",modo:'visualizar'} },

    { path:'/legislacao', component: load('MctDataViewer'), props: {source:"legislacao/lista",titulo:"Legislação"}},
    { path: '/legislacao/novo', component: loadC('modulos/sigcont/cadastro/Legislacao'), props: {source:"legislacao/novo",titulo:"Legislação",modo:'novo'} },
    { path: '/legislacao/:codigo/editar', component: loadC('modulos/sigcont/cadastro/Legislacao'), props: {source:"legislacao/editar",titulo:"Legislação",modo:'editar'} },
    { path: '/legislacao/:codigo/deletar', component: loadC('modulos/sigcont/cadastro/Legislacao'), props: {source:"legislacao/deletar",titulo:"Legislação",modo:'deletar'} },
    { path: '/legislacao/:codigo/visualizar', component: loadC('modulos/sigcont/cadastro/Legislacao'), props: {source:"legislacao/editar",titulo:"Legislação",modo:'visualizar'} },

    { path:'/responsavelug', component: load('MctDataViewer'), props: {source:"responsavelug/lista",titulo:"Responsável UG"}},
    { path: '/responsavelug/novo', component: loadC('modulos/sigcont/cadastro/ResponsavelUG'), props: {source:"responsavelug/novo",titulo:"Responsável UG",modo:'novo'} },
    { path: '/responsavelug/:rug_codigo/:rug_ug/:rug_exercicio/editar', component: loadC('modulos/sigcont/cadastro/ResponsavelUG'), props: {source:"responsavelug/editar",titulo:"Responsável UG",modo:'editar'} },
    { path: '/responsavelug/:rug_codigo/:rug_ug/:rug_exercicio/deletar', component: loadC('modulos/sigcont/cadastro/ResponsavelUG'), props: {source:"responsavelug/deletar",titulo:"Responsável UG",modo:'deletar'} },
    { path: '/responsavelug/:rug_codigo/:rug_ug/:rug_exercicio/visualizar', component: loadC('modulos/sigcont/cadastro/ResponsavelUG'), props: {source:"responsavelug/editar",titulo:"Responsável UG",modo:'visualizar'} },

    /* PPA */
    { path:'/ppadespesas', component: loadC('modulos/sigcont/ppa/PPADespesasLista'), props: {source:"ppadespesas/despesas",titulo:"Lanc. Despesa PPA"}},
    { path: '/ppadespesas/novo', component: loadC('modulos/sigcont/ppa/PPADespesas'), props: {source:"ppadespesas/novo",titulo:"Lanc. Despesa PPA",modo:'novo'} },
    { path: '/ppadespesas/:ppades_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/ppa/PPADespesas'), props: {source:"ppadespesas/editar",titulo:"Lanc. Despesa PPA",modo:'editar'} },
    { path: '/ppadespesas/:ppades_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/ppa/PPADespesas'), props: {source:"ppadespesas/deletar",titulo:"Lanc. Despesa PPA",modo:'deletar'} },
    { path: '/ppadespesas/:ppades_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/ppa/PPADespesas'), props: {source:"ppadespesas/editar",titulo:"Lanc. Despesa PPA",modo:'visualizar'} },

    { path:'/ppareceitas', component: loadC('modulos/sigcont/ppa/PPAReceitasLista'), props: {source:"ppareceitas/lista",titulo:"Lanc. Receita PPA"}},
    { path: '/ppareceitas/novo', component: loadC('modulos/sigcont/ppa/PPAReceitas'), props: {source:"ppareceitas/novo",titulo:"Lanc. Receita PPA",modo:'novo'} },
    { path: '/ppareceitas/:pparec_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/ppa/PPAReceitas'), props: {source:"ppareceitas/editar",titulo:"Lanc. Receita PPA",modo:'editar'} },
    { path: '/ppareceitas/:pparec_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/ppa/PPAReceitas'), props: {source:"ppareceitas/deletar",titulo:"Lanc. Receita PPA",modo:'deletar'} },
    { path: '/ppareceitas/:pparec_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/ppa/PPAReceitas'), props: {source:"ppareceitas/editar",titulo:"Lanc. Receita PPA",modo:'visualizar'} },
    
    /* LDO */
    { path:'/ldodespesas', component: loadC('modulos/sigcont/ldo/LDODespesasLista'), props: {source:"ldodespesas/despesas",titulo:"Lanc. Despesa LDO"}},
    { path: '/ldodespesas/novo', component: loadC('modulos/sigcont/ldo/LDODespesas'), props: {source:"ldodespesas/novo",titulo:"Lanc. Despesa LDO",modo:'novo'} },
    { path: '/ldodespesas/:ldodes_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/ldo/LDODespesas'), props: {source:"ldodespesas/editar",titulo:"Lanc. Despesa LDO",modo:'editar'} },
    { path: '/ldodespesas/:ldodes_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/ldo/LDODespesas'), props: {source:"ldodespesas/deletar",titulo:"Lanc. Despesa LDO",modo:'deletar'} },
    { path: '/ldodespesas/:ldodes_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/ldo/LDODespesas'), props: {source:"ldodespesas/editar",titulo:"Lanc. Despesa LDO",modo:'visualizar'} },

    { path:'/ldoreceitas', component: loadC('modulos/sigcont/ldo/LDOReceitasLista'), props: {source:"ldoreceitas/lista",titulo:"Lanc. Receita LDO"}},
    { path: '/ldoreceitas/novo', component: loadC('modulos/sigcont/ldo/LDOReceitas'), props: {source:"ldoreceitas/novo",titulo:"Lanc. Receita LDO",modo:'novo'} },
    { path: '/ldoreceitas/:ldorec_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/ldo/LDOReceitas'), props: {source:"ldoreceitas/editar",titulo:"Lanc. Receita LDO",modo:'editar'} },
    { path: '/ldoreceitas/:ldorec_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/ldo/LDOReceitas'), props: {source:"ldoreceitas/deletar",titulo:"Lanc. Receita LDO",modo:'deletar'} },
    { path: '/ldoreceitas/:ldorec_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/ldo/LDOReceitas'), props: {source:"ldoreceitas/editar",titulo:"Lanc. Receita LDO",modo:'visualizar'} },

    /* LOA */
    { path:'/loadespesas', component: loadC('modulos/sigcont/loa/LOADespesasLista'), props: {source:"loadespesas/despesas",titulo:"Lanc. Despesa LOA"}},
    { path: '/loadespesas/novo', component: loadC('modulos/sigcont/loa/LOADespesas'), props: {source:"loadespesas/novo",titulo:"Lanc. Despesa LOA",modo:'novo'} },
    { path: '/loadespesas/:loades_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/loa/LOADespesas'), props: {source:"loadespesas/editar",titulo:"Lanc. Despesa LOA",modo:'editar'} },
    { path: '/loadespesas/:loades_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/loa/LOADespesas'), props: {source:"loadespesas/deletar",titulo:"Lanc. Despesa LOA",modo:'deletar'} },
    { path: '/loadespesas/:loades_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/loa/LOADespesas'), props: {source:"loadespesas/editar",titulo:"Lanc. Despesa LOA",modo:'visualizar'} },

    { path:'/loareceitas', component: loadC('modulos/sigcont/loa/LOAReceitasLista'), props: {source:"loareceitas/lista",titulo:"Lanc. Receita LOA"}},
    { path: '/loareceitas/novo', component: loadC('modulos/sigcont/loa/LOAReceitas'), props: {source:"loareceitas/novo",titulo:"Lanc. Receita LOA",modo:'novo'} },
    { path: '/loareceitas/:loarec_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/loa/LOAReceitas'), props: {source:"loareceitas/editar",titulo:"Lanc. Receita LOA",modo:'editar'} },
    { path: '/loareceitas/:loarec_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/loa/LOAReceitas'), props: {source:"loareceitas/deletar",titulo:"Lanc. Receita LOA",modo:'deletar'} },
    { path: '/loareceitas/:loarec_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/loa/LOAReceitas'), props: {source:"loareceitas/editar",titulo:"Lanc. Receita LOA",modo:'visualizar'} },
    
    /* EXECUCAO ORCAMENTARIA */
    { path:'/execdespesas', component: loadC('modulos/sigcont/execucao/EXECDespesasLista'), props: {source:"execdespesas/despesas",titulo:"Lanc. Despesa Exec. Orç."}},
    { path: '/execdespesas/novo', component: loadC('modulos/sigcont/execucao/EXECDespesas'), props: {source:"execdespesas/novo",titulo:"Lanc. Despesa Exec. Orç.",modo:'novo'} },
    { path: '/execdespesas/:execdes_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/execucao/EXECDespesas'), props: {source:"execdespesas/editar",titulo:"Lanc. Despesa Exec. Orç.",modo:'editar'} },
    { path: '/execdespesas/:execdes_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/execucao/EXECDespesas'), props: {source:"execdespesas/deletar",titulo:"Lanc. Despesa Exec. Orç.",modo:'deletar'} },
    { path: '/execdespesas/:execdes_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/execucao/EXECDespesas'), props: {source:"execdespesas/editar",titulo:"Lanc. Despesa Exec. Orç.",modo:'visualizar'} },

    { path:'/execreceitas', component: loadC('modulos/sigcont/execucao/EXECReceitasLista'), props: {source:"execreceitas/lista",titulo:"Lanc. Receita Exec. Orç."}},
    { path: '/execreceitas/novo', component: loadC('modulos/sigcont/execucao/EXECReceitas'), props: {source:"execreceitas/novo",titulo:"Lanc. Receita Exec. Orç.",modo:'novo'} },
    { path: '/execreceitas/:execrec_codigo/:ug_codigo/:exe_codigo/editar', component: loadC('modulos/sigcont/execucao/EXECReceitas'), props: {source:"execreceitas/editar",titulo:"Lanc. Receita Exec. Orç.",modo:'editar'} },
    { path: '/execreceitas/:execrec_codigo/:ug_codigo/:exe_codigo/deletar', component: loadC('modulos/sigcont/execucao/EXECReceitas'), props: {source:"execreceitas/deletar",titulo:"Lanc. Receita Exec. Orç.",modo:'deletar'} },
    { path: '/execreceitas/:execrec_codigo/:ug_codigo/:exe_codigo/visualizar', component: loadC('modulos/sigcont/execucao/EXECReceitas'), props: {source:"execreceitas/editar",titulo:"Lanc. Receita Exec. Orç.",modo:'visualizar'} },

    { path:'/empenho', component: loadC('modulos/sigcont/execucao/EmpenhosLista'), props: {source:"empenho/lista",titulo:"Empenho"}},
    { path: '/empenho/novo', component: loadC('modulos/sigcont/execucao/Empenhos'), props: {source:"empenho/novo",titulo:"Empenho",modo:'novo'} },
    { path: '/empenho/:emp_codigo/:emp_ug/:emp_exercicio/editar', component: loadC('modulos/sigcont/execucao/Empenhos'), props: {source:"empenho/editar",titulo:"Empenho",modo:'editar'} },
    { path: '/empenho/:emp_codigo/:emp_ug/:emp_exercicio/deletar', component: loadC('modulos/sigcont/execucao/Empenhos'), props: {source:"empenho/deletar",titulo:"Empenho",modo:'deletar'} },
    { path: '/empenho/:emp_codigo/:emp_ug/:emp_exercicio/visualizar', component: loadC('modulos/sigcont/execucao/Empenhos'), props: {source:"empenho/editar",titulo:"Empenho",modo:'visualizar'} },

    { path:'/liquidacoes', component: loadC('modulos/sigcont/execucao/LiquidacoesLista'), props: {source:"liquidacoes/lista",titulo:"Liquidações"}},
    { path: '/liquidacoes/novo', component: loadC('modulos/sigcont/execucao/Liquidacoes'), props: {source:"liquidacoes/novo",titulo:"Liquidações",modo:'novo'} },
    { path: '/liquidacoes/:liq_codigo/:liq_ug/:liq_exercicio/editar', component: loadC('modulos/sigcont/execucao/Liquidacoes'), props: {source:"liquidacoes/editar",titulo:"Liquidações",modo:'editar'} },
    { path: '/liquidacoes/:liq_codigo/:liq_ug/:liq_exercicio/deletar', component: loadC('modulos/sigcont/execucao/Liquidacoes'), props: {source:"liquidacoes/deletar",titulo:"Liquidações",modo:'deletar'} },
    { path: '/liquidacoes/:liq_codigo/:liq_ug/:liq_exercicio/visualizar', component: loadC('modulos/sigcont/execucao/Liquidacoes'), props: {source:"liquidacoes/editar",titulo:"Liquidações",modo:'visualizar'} },

    { path:'/pagamentos', component: loadC('modulos/sigcont/execucao/PagamentosLista'), props: {source:"pagamentos/lista",titulo:"Pagamentos"}},
    { path: '/pagamentos/novo', component: loadC('modulos/sigcont/execucao/Pagamentos'), props: {source:"pagamentos/novo",titulo:"Pagamentos",modo:'novo'} },
    { path: '/pagamentos/:pag_codigo/:pag_ug/:pag_exercicio/editar', component: loadC('modulos/sigcont/execucao/Pagamentos'), props: {source:"pagamentos/editar",titulo:"Pagamentos",modo:'editar'} },
    { path: '/pagamentos/:pag_codigo/:pag_ug/:pag_exercicio/deletar', component: loadC('modulos/sigcont/execucao/Pagamentos'), props: {source:"pagamentos/deletar",titulo:"Pagamentos",modo:'deletar'} },
    { path: '/pagamentos/:pag_codigo/:pag_ug/:pag_exercicio/visualizar', component: loadC('modulos/sigcont/execucao/Pagamentos'), props: {source:"pagamentos/editar",titulo:"Pagamentos",modo:'visualizar'} },



    { path:'/caixa', component: load('MctDataViewer'), props: {source:"caixa/lista",titulo:"Caixa"}},
    { path: '/caixa/novo', component: loadC('modulos/sigcont/execucao/Caixa'), props: {source:"caixa/novo",titulo:"Caixa",modo:'novo'} },
    { path: '/caixa/:caix_codigo/:caix_ug/:caix_exercicio/editar', component: loadC('modulos/sigcont/execucao/Caixa'), props: {source:"caixa/editar",titulo:"Caixa",modo:'editar'} },
    { path: '/caixa/:caix_codigo/:caix_ug/:caix_exercicio/deletar', component: loadC('modulos/sigcont/execucao/Caixa'), props: {source:"caixa/deletar",titulo:"Caixa",modo:'deletar'} },
    { path: '/caixa/:caix_codigo/:caix_ug/:caix_exercicio/visualizar', component: loadC('modulos/sigcont/execucao/Caixa'), props: {source:"caixa/editar",titulo:"Caixa",modo:'visualizar'} },

    { path:'/contabancaria', component: load('MctDataViewer'), props: {source:"contabancaria/lista",titulo:"Contas Bancárias"}},
    { path: '/contabancaria/novo', component: loadC('modulos/sigcont/execucao/ContaBancaria'), props: {source:"contabancaria/novo",titulo:"Contas Bancárias",modo:'novo'} },
    { path: '/contabancaria/:cbnc_codigo/:cbnc_ug/:cbnc_exercicio/editar', component: loadC('modulos/sigcont/execucao/ContaBancaria'), props: {source:"contabancaria/editar",titulo:"Contas Bancárias",modo:'editar'} },
    { path: '/contabancaria/:cbnc_codigo/:cbnc_ug/:cbnc_exercicio/deletar', component: loadC('modulos/sigcont/execucao/ContaBancaria'), props: {source:"contabancaria/deletar",titulo:"Contas Bancárias",modo:'deletar'} },
    { path: '/contabancaria/:cbnc_codigo/:cbnc_ug/:cbnc_exercicio/visualizar', component: loadC('modulos/sigcont/execucao/ContaBancaria'), props: {source:"contabancaria/editar",titulo:"Contas Bancárias",modo:'visualizar'} },

    { path:'/contaextra', component: load('MctDataViewer'), props: {source:"contaextra/lista",titulo:"Conta Extra"}},
    { path: '/contaextra/novo', component: loadC('modulos/sigcont/execucao/ContaExtra'), props: {source:"contaextra/novo",titulo:"Conta Extra",modo:'novo'} },
    { path: '/contaextra/:cextra_codigo/:cextra_ug/:cextra_exercicio/editar', component: loadC('modulos/sigcont/execucao/ContaExtra'), props: {source:"contaextra/editar",titulo:"Conta Extra",modo:'editar'} },
    { path: '/contaextra/:cextra_codigo/:cextra_ug/:cextra_exercicio/deletar', component: loadC('modulos/sigcont/execucao/ContaExtra'), props: {source:"contaextra/deletar",titulo:"Conta Extra",modo:'deletar'} },
    { path: '/contaextra/:cextra_codigo/:cextra_ug/:cextra_exercicio/visualizar', component: loadC('modulos/sigcont/execucao/ContaExtra'), props: {source:"contaextra/editar",titulo:"Conta Extra",modo:'visualizar'} },

    { path:'/arrecreceitas', component: loadC('modulos/sigcont/execucao/ArrecReceitasLista'), props: {source:"arrecreceitas/lista",titulo:"Lanc. Receita Orçamentária"}},
    { path: '/arrecreceitas/novo', component: loadC('modulos/sigcont/execucao/ArrecReceitas'), props: {source:"arrecreceitas/novo",titulo:"Lanc. Receita Orçamentária",modo:'novo'} },
    { path: '/arrecreceitas/:arrec_codigo/:arrec_ug/:arrec_exercicio/:arrec_data/editar', component: loadC('modulos/sigcont/execucao/ArrecReceitas'), props: {source:"arrecreceitas/editar",titulo:"Lanc. Receita Orçamentária",modo:'editar'} },
    { path: '/arrecreceitas/:arrec_codigo/:arrec_ug/:arrec_exercicio/:arrec_data/deletar', component: loadC('modulos/sigcont/execucao/ArrecReceitas'), props: {source:"arrecreceitas/deletar",titulo:"Lanc. Receita Orçamentária",modo:'deletar'} },
    { path: '/arrecreceitas/:arrec_codigo/:arrec_ug/:arrec_exercicio/:arrec_data/visualizar', component: loadC('modulos/sigcont/execucao/ArrecReceitas'), props: {source:"arrecreceitas/editar",titulo:"Lanc. Receita Orçamentária",modo:'visualizar'} },

    { path:'/interferencialanc', component: loadC('modulos/sigcont/execucao/InterferenciaLancLista'), props: {source:"interferencialanc/lista",titulo:"Lanc. de Interferencias Financeiras"}},
    { path: '/interferencialanc/novo', component: loadC('modulos/sigcont/execucao/InterferenciaLanc'), props: {source:"interferencialanc/novo",titulo:"Lanc. de Interferencias Financeiras",modo:'novo'} },
    { path: '/interferencialanc/:inlanc_codigo/:inlanc_ug/:inlanc_exercicio/:inlanc_data/editar', component: loadC('modulos/sigcont/execucao/InterferenciaLanc'), props: {source:"interferencialanc/editar",titulo:"Lanc. de Interferencias Financeiras",modo:'editar'} },
    { path: '/interferencialanc/:inlanc_codigo/:inlanc_ug/:inlanc_exercicio/:inlanc_data/deletar', component: loadC('modulos/sigcont/execucao/InterferenciaLanc'), props: {source:"interferencialanc/deletar",titulo:"Lanc. de Interferencias Financeiras",modo:'deletar'} },
    { path: '/interferencialanc/:inlanc_codigo/:inlanc_ug/:inlanc_exercicio/:inlanc_data/visualizar', component: loadC('modulos/sigcont/execucao/InterferenciaLanc'), props: {source:"interferencialanc/editar",titulo:"Lanc. de Interferencias Financeiras",modo:'visualizar'} },

    { path:'/contaextralanc', component: loadC('modulos/sigcont/execucao/ContaExtraLancLista'), props: {source:"contaextralanc/lista",titulo:"Lanc. Extra-Orçamentário"}},
    { path: '/contaextralanc/novo', component: loadC('modulos/sigcont/execucao/ContaExtraLanc'), props: {source:"contaextralanc/novo",titulo:"Lanc. Extra-Orçamentário",modo:'novo'} },
    { path: '/contaextralanc/:celanc_codigo/:celanc_ug/:celanc_exercicio/:celanc_data/editar', component: loadC('modulos/sigcont/execucao/ContaExtraLanc'), props: {source:"contaextralanc/editar",titulo:"Lanc. Extra-Orçamentário",modo:'editar'} },
    { path: '/contaextralanc/:celanc_codigo/:celanc_ug/:celanc_exercicio/:celanc_data/deletar', component: loadC('modulos/sigcont/execucao/ContaExtraLanc'), props: {source:"contaextralanc/deletar",titulo:"Lanc. Extra-Orçamentário",modo:'deletar'} },
    { path: '/contaextralanc/:celanc_codigo/:celanc_ug/:celanc_exercicio/:celanc_data/visualizar', component: loadC('modulos/sigcont/execucao/ContaExtraLanc'), props: {source:"contaextralanc/editar",titulo:"Lanc. Extra-Orçamentário",modo:'visualizar'} },

    { path:'/transferencias', component: loadC('modulos/sigcont/execucao/TransferenciasLista'), props: {source:"transferencias/lista",titulo:"Transf. Bancárias/Caixa/Extra-Orçamentárias "}},
    { path: '/transferencias/novo', component: loadC('modulos/sigcont/execucao/Transferencias'), props: {source:"transferencias/novo",titulo:"Transf. Bancárias/Caixa/Extra-Orçamentárias ",modo:'novo'} },
    { path: '/transferencias/:trans_codigo/:trans_ug/:trans_exercicio/:trans_data/editar', component: loadC('modulos/sigcont/execucao/Transferencias'), props: {source:"transferencias/editar",titulo:"Transf. Bancárias/Caixa/Extra-Orçamentárias ",modo:'editar'} },
    { path: '/transferencias/:trans_codigo/:trans_ug/:trans_exercicio/:trans_data/deletar', component: loadC('modulos/sigcont/execucao/Transferencias'), props: {source:"transferencias/deletar",titulo:"Transf. Bancárias/Caixa/Extra-Orçamentárias ",modo:'deletar'} },
    { path: '/transferencias/:trans_codigo/:trans_ug/:trans_exercicio/:trans_data/visualizar', component: loadC('modulos/sigcont/execucao/Transferencias'), props: {source:"transferencias/editar",titulo:"Transf. Bancárias/Caixa/Extra-Orçamentárias ",modo:'visualizar'} },

    { path:'/decretos', component: loadC('modulos/sigcont/execucao/DecretosLista'), props: {source:"decretos/lista",titulo:"Decretos"}},
    { path: '/decretos/novo', component: loadC('modulos/sigcont/execucao/Decretos'), props: {source:"decretos/novo",titulo:"Decretos",modo:'novo'} },
    { path: '/decretos/:decr_numero/:decr_ug/:decr_ano/editar', component: loadC('modulos/sigcont/execucao/Decretos'), props: {source:"decretos/editar",titulo:"Decretos",modo:'editar'} },
    { path: '/decretos/:decr_numero/:decr_ug/:decr_ano/deletar', component: loadC('modulos/sigcont/execucao/Decretos'), props: {source:"decretos/deletar",titulo:"Decretos",modo:'deletar'} },
    { path: '/decretos/:decr_numero/:decr_ug/:decr_ano/visualizar', component: loadC('modulos/sigcont/execucao/Decretos'), props: {source:"decretos/editar",titulo:"Decretos",modo:'visualizar'} },

    { path:'/prestacaocontas', component: loadC('modulos/sigcont/execucao/PrestacaoContasLista'), props: {source:"prestacaocontas/lista",titulo:"Prestação de Contas"}},
    { path: '/prestacaocontas/novo', component: loadC('modulos/sigcont/execucao/PrestacaoContas'), props: {source:"prestacaocontas/novo",titulo:"Prestação de Contas",modo:'novo'} },
    { path: '/prestacaocontas/:pcnt_emp_codigo/:pcnt_emp_ug/:pcnt_emp_exercicio/editar', component: loadC('modulos/sigcont/execucao/PrestacaoContas'), props: {source:"prestacaocontas/editar",titulo:"Prestação de Contas",modo:'editar'} },
    { path: '/prestacaocontas/:pcnt_emp_codigo/:pcnt_emp_ug/:pcnt_emp_exercicio/deletar', component: loadC('modulos/sigcont/execucao/PrestacaoContas'), props: {source:"prestacaocontas/deletar",titulo:"Prestação de Contas",modo:'deletar'} },
    { path: '/prestacaocontas/:pcnt_emp_codigo/:pcnt_emp_ug/:pcnt_emp_exercicio/visualizar', component: loadC('modulos/sigcont/execucao/PrestacaoContas'), props: {source:"prestacaocontas/editar",titulo:"Prestação de Contas",modo:'visualizar'} },
    
    /**INICIALIZACOES */
    { path:'/inicializacao/ppaldoloa', component: loadC('modulos/sigcont/inicializacao/Inicializacao')},
    { path:'/inicializacao/financeira', component: loadC('modulos/sigcont/inicializacao/InicializacaoFinan')},

]