<template>
  <component :is="layout">
    <router-view/>
  </component>  
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'app',
  data () {
    return {
      //layout: 'div',
    }
  },
  mounted () {
    if (!this.infoLogado.usu_codigo){
      this.$router.push({path: '/logar'})  
    }
  },
  updated () {
    if (!this.infoLogado.usu_codigo){        
      this.$router.push({path: '/logar'})  
    }
  },
  computed: {
    ...mapState('Login',['infoLogado', 'layout']),
  },
}
</script>

<style lang="scss">
  @import './assets/scss/style';
</style>
