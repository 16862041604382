function load (component) {
    return () => import(`../components/comum/${component}.vue`)
  }
  

  export default [
   
    { path:'/apliccargofuncaopessoal', component: load('MctDataViewer'), props: {source:"apliccargofuncaopessoal/lista",titulo:"Aplic Cargo Função Pessoal"}},
    { path: '/apliccargofuncaopessoal/novo', component: load('MctFormViewer'), props: {source:"apliccargofuncaopessoal/novo",titulo:"Aplic Cargo Função Pessoal",modo:'novo'} },
    { path: '/apliccargofuncaopessoal/:codigo/editar', component: load('MctFormViewer'), props: {source:"apliccargofuncaopessoal/editar",titulo:"Aplic Cargo Função Pessoal",modo:'editar'} },
    { path: '/apliccargofuncaopessoal/:codigo/deletar', component: load('MctFormViewer'), props: {source:"apliccargofuncaopessoal/deletar",titulo:"Aplic Cargo Função Pessoal",modo:'deletar'} },
    { path: '/apliccargofuncaopessoal/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"apliccargofuncaopessoal/editar",titulo:"Aplic Cargo Função Pessoal",modo:'visualizar'} },

    { path:'/apliccargoformaocupacao', component: load('MctDataViewer'), props: {source:"apliccargoformaocupacao/lista",titulo:"Aplic Cargo Forma Ocupação"}},
    { path: '/apliccargoformaocupacao/novo', component: load('MctFormViewer'), props: {source:"apliccargoformaocupacao/novo",titulo:"Aplic Cargo Forma Ocupação",modo:'novo'} },
    { path: '/apliccargoformaocupacao/:codigo/editar', component: load('MctFormViewer'), props: {source:"apliccargoformaocupacao/editar",titulo:"Aplic Cargo Forma Ocupação",modo:'editar'} },
    { path: '/apliccargoformaocupacao/:codigo/deletar', component: load('MctFormViewer'), props: {source:"apliccargoformaocupacao/deletar",titulo:"Aplic Cargo Forma Ocupação",modo:'deletar'} },
    { path: '/apliccargoformaocupacao/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"apliccargoformaocupacao/editar",titulo:"Aplic Cargo Forma Ocupação",modo:'visualizar'} },

    { path:'/apliccargoescolaridade', component: load('MctDataViewer'), props: {source:"apliccargoescolaridade/lista",titulo:"Aplic Cargo Forma Escolaridade"}},
    { path: '/apliccargoescolaridade/novo', component: load('MctFormViewer'), props: {source:"apliccargoescolaridade/novo",titulo:"Aplic Cargo Forma Escolaridade",modo:'novo'} },
    { path: '/apliccargoescolaridade/:codigo/editar', component: load('MctFormViewer'), props: {source:"apliccargoescolaridade/editar",titulo:"Aplic Cargo Forma Escolaridade",modo:'editar'} },
    { path: '/apliccargoescolaridade/:codigo/deletar', component: load('MctFormViewer'), props: {source:"apliccargoescolaridade/deletar",titulo:"Aplic Cargo Forma Escolaridade",modo:'deletar'} },
    { path: '/apliccargoescolaridade/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"apliccargoescolaridade/editar",titulo:"Aplic Cargo Forma Escolaridade",modo:'visualizar'} },

    { path:'/apliccargonatureza', component: load('MctDataViewer'), props: {source:"apliccargonatureza/lista",titulo:"Aplic Cargo Natureza"}},
    { path: '/apliccargonatureza/novo', component: load('MctFormViewer'), props: {source:"apliccargonatureza/novo",titulo:"Aplic Cargo Natureza",modo:'novo'} },
    { path: '/apliccargonatureza/:codigo/editar', component: load('MctFormViewer'), props: {source:"apliccargonatureza/editar",titulo:"Aplic Cargo Natureza",modo:'editar'} },
    { path: '/apliccargonatureza/:codigo/deletar', component: load('MctFormViewer'), props: {source:"apliccargonatureza/deletar",titulo:"Aplic Cargo Natureza",modo:'deletar'} },
    { path: '/apliccargonatureza/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"apliccargonatureza/editar",titulo:"Aplic Cargo Natureza",modo:'visualizar'} },

    { path:'/aplicregimejuridicocargo', component: load('MctDataViewer'), props: {source:"aplicregimejuridicocargo/lista",titulo:"Aplic Regime Jurídico"}},
    { path: '/aplicregimejuridicocargo/novo', component: load('MctFormViewer'), props: {source:"aplicregimejuridicocargo/novo",titulo:"Aplic Regime Jurídico",modo:'novo'} },
    { path: '/aplicregimejuridicocargo/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplicregimejuridicocargo/editar",titulo:"Aplic Regime Jurídico",modo:'editar'} },
    { path: '/aplicregimejuridicocargo/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplicregimejuridicocargo/deletar",titulo:"Aplic Regime Jurídico",modo:'deletar'} },
    { path: '/aplicregimejuridicocargo/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplicregimejuridicocargo/editar",titulo:"Aplic Regime Jurídico",modo:'visualizar'} },

    { path:'/aplictpdocatop', component: load('MctDataViewer'), props: {source:"aplictpdocatop/lista",titulo:"Aplic Tipo Documento"}},
    { path: '/aplictpdocatop/novo', component: load('MctFormViewer'), props: {source:"aplictpdocatop/novo",titulo:"Aplic Tipo Documento",modo:'novo'} },
    { path: '/aplictpdocatop/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplictpdocatop/editar",titulo:"Aplic Tipo Documento",modo:'editar'} },
    { path: '/aplictpdocatop/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplictpdocatop/deletar",titulo:"Aplic Tipo Documento",modo:'deletar'} },
    { path: '/aplictpdocatop/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplictpdocatop/editar",titulo:"Aplic Tipo Documento",modo:'visualizar'} },

    { path:'/aplicnaturezadiaria', component: load('MctDataViewer'), props: {source:"aplicnaturezadiaria/lista",titulo:"Aplic Natureza Diária"}},
    { path: '/aplicnaturezadiaria/novo', component: load('MctFormViewer'), props: {source:"aplicnaturezadiaria/novo",titulo:"Aplic Natureza Diária",modo:'novo'} },
    { path: '/aplicnaturezadiaria/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplicnaturezadiaria/editar",titulo:"Aplic Natureza Diária",modo:'editar'} },
    { path: '/aplicnaturezadiaria/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplicnaturezadiaria/deletar",titulo:"Aplic Natureza Diária",modo:'deletar'} },
    { path: '/aplicnaturezadiaria/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplicnaturezadiaria/editar",titulo:"Aplic Natureza Diária",modo:'visualizar'} },

    { path:'/aplictipodescbenefgratif', component: load('MctDataViewer'), props: {source:"aplictipodescbenefgratif/lista",titulo:"Aplic Tipo Desconto Beneficio/Gratificação"}},
    { path: '/aplictipodescbenefgratif/novo', component: load('MctFormViewer'), props: {source:"aplictipodescbenefgratif/novo",titulo:"Aplic Tipo Desconto Beneficio/Gratificação",modo:'novo'} },
    { path: '/aplictipodescbenefgratif/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplictipodescbenefgratif/editar",titulo:"Aplic Tipo Desconto Beneficio/Gratificação",modo:'editar'} },
    { path: '/aplictipodescbenefgratif/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplictipodescbenefgratif/deletar",titulo:"Aplic Tipo Desconto Beneficio/Gratificação",modo:'deletar'} },
    { path: '/aplictipodescbenefgratif/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplictipodescbenefgratif/editar",titulo:"Aplic Tipo Desconto Beneficio/Gratificação",modo:'visualizar'} },

    { path:'/aplictiporesponsavel', component: load('MctDataViewer'), props: {source:"aplictiporesponsavel/lista",titulo:"Aplic Tipo Responsável"}},
    { path: '/aplictiporesponsavel/novo', component: load('MctFormViewer'), props: {source:"aplictiporesponsavel/novo",titulo:"Aplic Tipo Responsável",modo:'novo'} },
    { path: '/aplictiporesponsavel/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplictiporesponsavel/editar",titulo:"Aplic Tipo Responsável",modo:'editar'} },
    { path: '/aplictiporesponsavel/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplictiporesponsavel/deletar",titulo:"Aplic Tipo Responsável",modo:'deletar'} },
    { path: '/aplictiporesponsavel/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplictiporesponsavel/editar",titulo:"Aplic Tipo Responsável",modo:'visualizar'} },

    { path:'/aplicelementodespesa', component: load('MctDataViewer'), props: {source:"aplicelementodespesa/lista",titulo:"Aplic Elemento da Despesa"}},
    { path: '/aplicelementodespesa/novo', component: load('MctFormViewer'), props: {source:"aplicelementodespesa/novo",titulo:"Aplic Elemento da Despesa",modo:'novo'} },
    { path: '/aplicelementodespesa/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplicelementodespesa/editar",titulo:"Aplic Elemento da Despesa",modo:'editar'} },
    { path: '/aplicelementodespesa/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplicelementodespesa/deletar",titulo:"Aplic Elemento da Despesa",modo:'deletar'} },
    { path: '/aplicelementodespesa/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplicelementodespesa/editar",titulo:"Aplic Elemento da Despesa",modo:'visualizar'} },

    { path:'/aplicsubelementodespesa', component: load('MctDataViewer'), props: {source:"aplicsubelementodespesa/lista",titulo:"Aplic Sub-Elemento da Despesa"}},
    { path: '/aplicsubelementodespesa/novo', component: load('MctFormViewer'), props: {source:"aplicsubelementodespesa/novo",titulo:"Aplic Sub-Elemento da Despesa",modo:'novo'} },
    { path: '/aplicsubelementodespesa/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplicsubelementodespesa/editar",titulo:"Aplic Sub-Elemento da Despesa",modo:'editar'} },
    { path: '/aplicsubelementodespesa/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplicsubelementodespesa/deletar",titulo:"Aplic Sub-Elemento da Despesa",modo:'deletar'} },
    { path: '/aplicsubelementodespesa/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplicsubelementodespesa/editar",titulo:"Aplic Sub-Elemento da Despesa",modo:'visualizar'} },

    { path:'/aplicdesdobramentodespesa', component: load('MctDataViewer'), props: {source:"aplicdesdobramentodespesa/lista",titulo:"Aplic Desdobramento da Despesa"}},
    { path: '/aplicdesdobramentodespesa/novo', component: load('MctFormViewer'), props: {source:"aplicdesdobramentodespesa/novo",titulo:"Aplic Desdobramento da Despesa",modo:'novo'} },
    { path: '/aplicdesdobramentodespesa/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplicdesdobramentodespesa/editar",titulo:"Aplic Desdobramento da Despesa",modo:'editar'} },
    { path: '/aplicdesdobramentodespesa/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplicdesdobramentodespesa/deletar",titulo:"Aplic Desdobramento da Despesa",modo:'deletar'} },
    { path: '/aplicdesdobramentodespesa/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplicdesdobramentodespesa/editar",titulo:"Aplic Desdobramento da Despesa",modo:'visualizar'} },

    { path:'/aplicitensmaterialservico', component: load('MctDataViewer'), props: {source:"aplicitensmaterialservico/lista",titulo:"Aplic Itens Material/Serviço"}},
    { path: '/aplicitensmaterialservico/novo', component: load('MctFormViewer'), props: {source:"aplicitensmaterialservico/novo",titulo:"Aplic Itens Material/Serviço",modo:'novo'} },
    { path: '/aplicitensmaterialservico/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplicitensmaterialservico/editar",titulo:"Aplic Itens Material/Serviço",modo:'editar'} },
    { path: '/aplicitensmaterialservico/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplicitensmaterialservico/deletar",titulo:"Aplic Itens Material/Serviço",modo:'deletar'} },
    { path: '/aplicitensmaterialservico/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplicitensmaterialservico/editar",titulo:"Aplic Itens Material/Serviço",modo:'visualizar'} },

    { path:'/aplicunidadefornecimentoitens', component: load('MctDataViewer'), props: {source:"aplicunidadefornecimentoitens/lista",titulo:"Aplic Unidade de Fornecimento Itens"}},
    { path: '/aplicunidadefornecimentoitens/novo', component: load('MctFormViewer'), props: {source:"aplicunidadefornecimentoitens/novo",titulo:"Aplic Unidade de Fornecimento Itens",modo:'novo'} },
    { path: '/aplicunidadefornecimentoitens/:codigo/editar', component: load('MctFormViewer'), props: {source:"aplicunidadefornecimentoitens/editar",titulo:"Aplic Unidade de Fornecimento Itens",modo:'editar'} },
    { path: '/aplicunidadefornecimentoitens/:codigo/deletar', component: load('MctFormViewer'), props: {source:"aplicunidadefornecimentoitens/deletar",titulo:"Aplic Unidade de Fornecimento Itens",modo:'deletar'} },
    { path: '/aplicunidadefornecimentoitens/:codigo/visualizar', component: load('MctFormViewer'), props: {source:"aplicunidadefornecimentoitens/editar",titulo:"Aplic Unidade de Fornecimento Itens",modo:'visualizar'} },
    

];