import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
//import { store } from "./store";

Vue.use(VueRouter);

const router = new VueRouter({
  /*mode: 'history',*/
  mode: "abstract",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
