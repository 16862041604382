function load(component) {
  return () => import(`../components/comum/${component}.vue`);
}

function loadC(component) {
  return () => import(`../components/${component}.vue`);
}

export default [
  //PROFISSÕES
  {
    path: "/sigleg/profissao",
    name: "Profissões/Cargos",
    component: load("MctDataViewer"),
    props: { source: "profissaocargos/lista", titulo: "Profissões/Cargos" },
  },
  {
    path: "/sigleg/profissao/novo",
    component: load("MctFormViewer"),
    props: {
      source: "profissaocargos/novo",
      titulo: "Profissões/Cargos",
      modo: "novo",
    },
  },
  {
    path: "/sigleg/profissao/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "profissaocargos/editar",
      titulo: "Profissões/Cargos",
      modo: "editar",
    },
  },
  {
    path: "/sigleg/profissao/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "profissaocargos/editar",
      titulo: "PROFISSÕES",
      modo: "visualizar",
    },
  },

  {
    path: "/sigleg/secretarias",
    name: "Profissões/Cargos",
    component: load("MctDataViewer"),
    props: { source: "secretarias/lista", titulo: "Secretarias" },
  },
  {
    path: "/sigleg/secretarias/novo",
    component: load("MctFormViewer"),
    props: { source: "secretarias/novo", titulo: "Secretarias", modo: "novo" },
  },
  {
    path: "/sigleg/secretarias/:codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "secretarias/editar",
      titulo: "Secretarias",
      modo: "editar",
    },
  },
  {
    path: "/sigleg/secretarias/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "secretarias/editar",
      titulo: "Secretarias",
      modo: "visualizar",
    },
  },

  {
    path: "/sigleg/estantes",
    name: "Estantes",
    component: load("MctDataViewer"),
    props: { source: "estantes/lista", titulo: "Estantes" },
  },
  {
    path: "/sigleg/estantes/novo",
    component: load("MctFormViewer"),
    props: { source: "estantes/novo", titulo: "Estantes", modo: "novo" },
  },
  {
    path: "/sigleg/estantes/:codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "estantes/editar", titulo: "Estantes", modo: "editar" },
  },
  {
    path: "/sigleg/estantes/:codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "estantes/editar",
      titulo: "Estantes",
      modo: "visualizar",
    },
  },

  {
    path: "/sigleg/departamentos",
    name: "Estantes",
    component: load("MctDataViewer"),
    props: { source: "departamentos/lista", titulo: "Departamentos" },
  },
  {
    path: "/sigleg/departamentos/novo",
    component: load("MctFormViewer"),
    props: {
      source: "departamentos/novo",
      titulo: "Departamentos",
      modo: "novo",
    },
  },
  {
    path: "/sigleg/departamentos/:dep_codigo/:sec_codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "departamentos/editar",
      titulo: "Departamentos",
      modo: "editar",
    },
  },
  {
    path: "/sigleg/departamentos/:dep_codigo/:sec_codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "departamentos/editar",
      titulo: "Departamentos",
      modo: "visualizar",
    },
  },

  {
    path: "/sigleg/setores",
    name: "Estantes",
    component: load("MctDataViewer"),
    props: { source: "setores/lista", titulo: "Setores" },
  },
  {
    path: "/sigleg/setores/novo",
    component: load("MctFormViewer"),
    props: { source: "setores/novo", titulo: "Setores", modo: "novo" },
  },
  {
    path: "/sigleg/setores/:set_codigo/:dep_codigo/editar",
    component: load("MctFormViewer"),
    props: { source: "setores/editar", titulo: "Setores", modo: "editar" },
  },
  {
    path: "/sigleg/setores/:set_codigo/:dep_codigo/visualizar",
    component: load("MctFormViewer"),
    props: { source: "setores/editar", titulo: "Setores", modo: "visualizar" },
  },

  {
    path: "/sigleg/prateleiras",
    name: "Estantes",
    component: load("MctDataViewer"),
    props: { source: "prateleiras/lista", titulo: "Prateleirias" },
  },
  {
    path: "/sigleg/prateleiras/novo",
    component: load("MctFormViewer"),
    props: { source: "prateleiras/novo", titulo: "Prateleirias", modo: "novo" },
  },
  {
    path: "/sigleg/prateleiras/:pra_codigo/:est_codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "prateleiras/editar",
      titulo: "Prateleirias",
      modo: "editar",
    },
  },
  {
    path: "/sigleg/prateleiras/:pra_codigo/:est_codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "prateleiras/editar",
      titulo: "Prateleirias",
      modo: "visualizar",
    },
  },

  {
    path: "/sigleg/contas_bancarias",
    name: "Contas Bancárias",
    component: load("MctDataViewer"),
    props: { source: "contas_bancarias/lista", titulo: "Contas Bancárias" },
  },
  {
    path: "/sigleg/contas_bancarias/novo",
    component: load("MctFormViewer"),
    props: {
      source: "contas_bancarias/novo",
      titulo: "Contas Bancárias",
      modo: "novo",
    },
  },
  {
    path: "/sigleg/contas_bancarias/:con_codigo/:bnc_codigo/editar",
    component: load("MctFormViewer"),
    props: {
      source: "contas_bancarias/editar",
      titulo: "Contas Bancárias",
      modo: "editar",
    },
  },
  {
    path: "/sigleg/contas_bancarias/:con_codigo/:bnc_codigo/visualizar",
    component: load("MctFormViewer"),
    props: {
      source: "contas_bancarias/editar",
      titulo: "Contas Bancárias",
      modo: "visualizar",
    },
  },
];
