import Comum from './comum'
import Aplic from './aplic'
import Logradouro from './logradouro'
import SigCont from './sigcont'
import SigRH from './sigrh'
import SigCad from './sigcad'
import SigMun from './sigmun'
import SigEs from './siges'
import SigLeg from './sigleg'

const routes = [].concat(Comum, Aplic, Logradouro, SigCont, SigRH, SigCad, SigMun, SigEs, SigLeg);
  
export default routes